import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <nav className='mt-4 text-right'>
            <ul className="inline-flex -space-x-px text-sm">
                {pageNumbers.map((number, index) => (
                    <li key={number}>
                        <button
                            onClick={() => paginate(number)}
                            className={`flex items-center justify-center px-4 h-10 ms-0 leading-tight text-lime-600  border border-gray-300 ${index === 0 ? 'rounded-s-lg' : ''} ${pageNumbers.length === index + 1 ? 'rounded-e-lg' : ''} hover:bg-lime-600 hover:text-white ${number === currentPage ? 'bg-lime-600 text-white' : 'bg-white text-lime-600'} border border-solid hover:bg-gray-100 hover:text-lime-600`}
                            // className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 border-solid hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Pagination;
