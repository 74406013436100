import React, { useState } from "react";
import axios from 'axios';
import { Link, useNavigate  } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {API_URL} from "../../../constants";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export default function SignUp() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    dateOfBirth:'',
    phone1: '',
    phone2: '',
    company: ''
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear errors for a particular field when user starts typing
    if (errors[name]) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: null
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = {};
    // Validate required fields
    if (!formData.name.trim()) {
      newErrors.name = 'Username is required';
    }else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain alphabets and spaces";
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    }
    if (!formData.dateOfBirth.trim()) {
      newErrors.dateOfBirth = 'Date of Birth is required';
    }
    if (!formData.phone1.trim()) {
      newErrors.phone1 = 'Phone is required';
    }else if (!/^\d{10}$/.test(formData.phone1)) {
      newErrors.phone1 = "Phone number must be 10 digits";
    }
    if (!formData.company.trim()) {
      newErrors.company = 'Company name is required';
    }
    if (!formData.password.trim()) {
      newErrors.password = 'Password is required';
    }else if (formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long"
    }
    // Set errors state or submit form if no errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        const { name, email, dateOfBirth, phone1, phone2, company, password } = formData;  // Destructure to get only name and password
        const payload = { name, email, dateOfBirth, phone1, phone2, company, password };
        const response = await axios.post(`${API_URL}/api/customerUser/selfRegister`, payload);
        localStorage.setItem('token', response.data.token);
        toast.success('Registered Successfully!');
        navigate('/auth/login');
      } catch (err) {
        toast.error('Error occurred')
      }
    }
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div className="container mx-auto flex set-h-minus-nav flex-1 flex-col justify-center px-6 py-12 lg:px-8 animate-fadeIn">
        <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white shadow-lg px-12 py-8 rounded-md">
          <h2 className="mb-5 text-xl font-bold leading-9 tracking-tight text-gray-900">
            Create an account
          </h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <input
                    id="name"
                    name="name"
                    type="text"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    placeholder="Enter name"
                    className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    style={{border: errors.username ? '1px solid red' : ''}}
                />
                {errors.name && <p style={{color: 'red'}}>{errors.name}</p>}
              </div>
            </div>
            <div>
              <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    placeholder="Enter email address"
                    className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    style={{border: errors.email ? '1px solid red' : ''}}
                />
                {errors.email && <p style={{color: 'red'}}>{errors.email}</p>}
              </div>
            </div>
            <div>
              <label
                  htmlFor="dateOfBirth"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Date of Birth
              </label>
              <div className="mt-2">
                <input
                    id="dateOfBirth"
                    name="dateOfBirth"
                    type="date"
                    autoComplete="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    required
                    placeholder="Enter date of  birth"
                    className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    style={{border: errors.phone1 ? '1px solid red' : ''}}
                />
                {errors.dateOfBirth && <p style={{color: 'red'}}>{errors.dateOfBirth}</p>}
              </div>
            </div>
            <div>
              <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Phone 1
              </label>
              <div className="mt-2">
                <input
                    id="phone"
                    name="phone1"
                    type="text"
                    autoComplete="phone1"
                    value={formData.phone1}
                    onChange={handleChange}
                    required
                    placeholder="Enter phone number"
                    className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    style={{border: errors.phone1 ? '1px solid red' : ''}}
                />
                {errors.phone1 && <p style={{color: 'red'}}>{errors.phone1}</p>}
              </div>
            </div>
            <div>
              <label
                  htmlFor="phone2"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Phone 2
              </label>
              <div className="mt-2">
                <input
                    id="phone2"
                    name="phone2"
                    type="text"
                    autoComplete="phone2"
                    value={formData.phone2}
                    onChange={handleChange}
                    placeholder="Enter phone number"
                    className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                />
              </div>
            </div>
            <div>
              <label
                  htmlFor="company"
                  className="block text-sm font-medium leading-6 text-gray-900">
                Company
              </label>
              <div className="mt-2">
                <input
                    id="company"
                    name="company"
                    type="text"
                    autoComplete="company"
                    value={formData.company}
                    onChange={handleChange}
                    required
                    placeholder="Enter company name"
                    className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    style={{border: errors.company ? '1px solid red' : ''}}
                />
                {errors.company && <p style={{color: 'red'}}>{errors.company}</p>}
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
              </div>
              <div className="relative mt-2">
                <input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                    placeholder="Enter password"
                    className="block w-full rounded-lg border-0 pr-10 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    style={{border: errors.password ? '1px solid red' : ''}}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {showPassword ? <FaEyeSlash className='text-gray-400'/> : <FaEye className='text-gray-400'/>}
                </span>
                {errors.password && <p style={{color: 'red'}}>{errors.password}</p>}
              </div>
            </div>

            <div className="pt-4">
              <button
                  type="submit"
                  className="flex w-full justify-center rounded-lg bg-6 p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">
                Create account
              </button>
            </div>
          </form>

          <p className="mt-5 text-center text-sm text-gray-900 flex justify-between items-center gap-2">
            <span>Already have an account?</span>
            <Link
                to="/auth/login"
                className="font-semibold leading-6 text-lime-600 hover:text-lime-500">
              Sign In
            </Link>
          </p>
          <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover/>
        </div>
      </div>
    </>
  );
}
