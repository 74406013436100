// src/components/MenuCategoryList.js

import React, {useEffect, useState} from 'react';
import MenuCategoryService from './MenuCategoryService';
import {useNavigate} from 'react-router-dom';
import {Table} from "flowbite-react";
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";

const token = localStorage.getItem("token");

const MenuCategoryList = () => {
    const [menuCategories, setMenuCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        MenuCategoryService.getAll()
            .then(response => {
                setMenuCategories(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [token]);

    const handleEdit = (id) => {
        navigate(`/admin/menuCategory/edit/${id}`);
    };

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this menu category?");
        if (confirmed) {
            MenuCategoryService.delete(id)
                .then(response => {
                    setMenuCategories(menuCategories.filter(menuCategory => menuCategory.id !== id));
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleCreate = (id) => {
        navigate(`/admin/menuCategory/add`);
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons mb-2">
                <button onClick={() => navigate('/admin')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
                <button onClick={handleCreate}
                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Create New Group
                </button>
            </div>
            <div className="overflow-x-auto">
                <Table>
                    <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Description</Table.HeadCell>
                        <Table.HeadCell>Display Order</Table.HeadCell>
                        <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {menuCategories.map(menuCategory => (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={menuCategory.id}>
                                <Table.Cell
                                    className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{menuCategory.name}</Table.Cell>
                                <Table.Cell>{menuCategory.description}</Table.Cell>
                                <Table.Cell>{menuCategory.displayOrder}</Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => handleEdit(menuCategory.id)}
                                            className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Edit
                                    </button>
                                    <button onClick={() => handleDelete(menuCategory.id)}
                                            className="bg-red-700 rounded-lg px-4 py-2 text-white text-sm hover:bg-red-600 font-semibold transition-all focus:ring-2 focus:ring-red-600">Delete
                                    </button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
};

export default MenuCategoryList;
