import React, {useState, useEffect} from 'react';
import CustomerUserService from '../../../services/CustomerUserService';
import {useParams, useNavigate} from 'react-router-dom';
import UserGroupService from "./UserGroupService";
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";

const token = localStorage.getItem("token");

const AddUserForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [customerUsers, setCustomerUsers] = useState([]);
    const [addedUsers, setAddedUsers] = useState([]);
    const [addedUser, setAddedUser] = useState({
        userId: 0
    });

    useEffect(() => {
        UserGroupService.listUsers(Number(id))
            .then(response => {
                setAddedUsers(response.data.users);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Failed to load group.";
                toast.error(errorMessage)
                console.log(e);
            });

        CustomerUserService.getAll()
            .then(response => {
                setCustomerUsers(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Failed to load group.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [id, token]);

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setAddedUser({...addedUser, userId: selectedValue});
    };


    const handleRemove = (userId) => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            UserGroupService.removeUsers(Number(id), {userId: userId})
                .then(response => {
                    toast.success("User removed successfully!");
                    UserGroupService.listUsers(Number(id))
                        .then(response => {
                            setAddedUsers(response.data.users);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Failed to load group.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Failed to load group.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            UserGroupService.addUsers(Number(id), addedUser)
                .then(response => {
                    toast.success("User added successfully!");
                    UserGroupService.listUsers(Number(id))
                        .then(response => {
                            setAddedUsers(response.data.users);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Failed to load group.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Failed to load group.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const availableUsers = customerUsers.filter(
        customerUser => !addedUsers.some(addedUser => addedUser.id === customerUser.id)
    );

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons mb-2">
                <button onClick={() => navigate('/admin/userGroup')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-200 rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-xl">
                <div className='bg-white shadow-lg px-12 py-12 rounded-md'>
                    <h2 className="text-2xl font-semibold mb-6">Manage Users</h2>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                        <label className="block text-md font-semibold text-gray-700 mb-4">Existing Users:</label>
                            <ul className="list-disc list-inside mb-4">
                                {addedUsers.map(user => (
                                    <li key={user.id} className="flex justify-between items-center mt-2">
                                        {user.firstName} {user.lastName} ({user.email})
                                        <button
                                            type="button"
                                            onClick={() => handleRemove(user.id)}
                                            className="text-red-600 ml-2 p-0"
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <br />
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Select User:</label>
                            <select
                                name="userGroupIds"
                                value={addedUser.userId}
                                onChange={handleChange}
                                className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            >
                                <option>Select User</option>
                                {availableUsers.map(user => (
                                    <option key={user.id} value={user.id}>
                                        {user.firstName} {user.lastName} ({user.email})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='text-right'>
                            <button type="submit" 
                                className="rounded-lg p-2 px-6 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddUserForm;
