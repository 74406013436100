import React from 'react'
import Header from '../../components/Header/Header'

export default function Home() {
  return (
    <div className='home-page'>
        <Header/>
        {/* <Menu category={category} setCategory={setCategory}/>
        <FoodDisplay category={category}/> */}
    </div>
  )
}
