"use client";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Table } from "flowbite-react";
import { API_URL } from "../../../constants";
import {FaArrowLeft} from "react-icons/fa6";

export default function ReportPerDate() {
    const location = useLocation();
    const token = localStorage.getItem("token");
    const [myOrders, setMyOrders] = useState([]); // Default to an empty array
    const navigate = useNavigate();

    // Extract the deliveryDate query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const deliveryDate = queryParams.get("deliveryDate");
    const id = queryParams.get("id")

    useEffect(() => {
        fetchOrdersByDate();
    }, [deliveryDate, token]);

    const fetchOrdersByDate = async () => {
        try {
            const response = await axios.post(
                `${API_URL}/api/customerOrderCart/listByDate/?deliveryDate=${deliveryDate}`,
                { id: id },
                {
                    headers: {
                        "X-Auth-Token": token,
                    },
                }
            );
            setMyOrders(response.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        }
    };

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const categorizeOrders = (orders) => {
        if (!Array.isArray(orders)) {
            console.error("Expected orders to be an array, but got:", orders);
            return {};
        }

        return orders.reduce((acc, order) => { // Renamed 'orders' to 'order' for clarity
            const slotName = order.slot ? order.slot.name : "UNKNOWN";

            if (!acc[slotName]) {
                acc[slotName] = [];
            }

            // Handle the nested 'orders' array within each order object
            order.orders.forEach((item) => {
                acc[slotName].push({
                    ...item,
                    status: order.status,
                });
            });

            return acc;
        }, {});
    };


    const ordersBySlot = categorizeOrders(myOrders.orders);
    console.log('orders', myOrders);
    console.log('categorized', ordersBySlot);


    const renderTableRows = (ordersBySlot) => (
        Object.keys(ordersBySlot).map((slotName) => (
            <React.Fragment key={slotName}>
                <Table.Row className="bg-gray-50">
                    <Table.Cell colSpan={7} className="text-gray-950 py-3 text-sm uppercase font-extrabold">
                        {slotName}
                    </Table.Cell>
                </Table.Row>
                {ordersBySlot[slotName].map((item) => (
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={item.itemId}>
                        <Table.Cell className="w-1/6">{item.item}</Table.Cell>
                        <Table.Cell className="w-1/6">{item.quantity}</Table.Cell>
                        <Table.Cell className="w-1/6">Rs. {parseInt(item.price)}</Table.Cell>
                        <Table.Cell className="w-1/6">Rs. {parseInt(item.price) * item.quantity}</Table.Cell>
                        <Table.Cell className="w-1/6">
                        <span
                            className={`${
                                item.status.name === 'TIME_EXCEEDED'
                                    ? 'bg-red-100 text-red-800'
                                    : 'bg-blue-100 text-blue-800'
                            } text-xs font-semibold px-2 py-0.5 capitalize rounded whitespace-nowrap truncate`}
                        >
                            {item.status.name}
                        </span>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </React.Fragment>
        ))
    );

    return (
        <div className="max-w-screen-xl px-4 py-8 mx-auto">
            <h1 className="font-bold text-2xl text-center mt-5 mb-10">Orders for {formatDate(deliveryDate)}</h1>
            <div className="nav-buttons mb-4 flex space-x-4">
                <button onClick={() => navigate(`/reportPerUser/${id}`)}
                        className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-200  rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
            </div>
            <Table className="w-full">
                <Table.Head>
                    <Table.HeadCell className="w-1/6 bg-zinc-100">Item</Table.HeadCell>
                    <Table.HeadCell className="w-1/6 bg-zinc-100">Quantity</Table.HeadCell>
                    <Table.HeadCell className="w-1/6 bg-zinc-100">Rate</Table.HeadCell>
                    <Table.HeadCell className="w-1/6 bg-zinc-100">Total</Table.HeadCell>
                    <Table.HeadCell className="w-1/6 bg-zinc-100">Status</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">{renderTableRows(ordersBySlot)}</Table.Body>
            </Table>
        </div>
    );
}
