import axios from 'axios';
import { API_URL } from '../../../constants'

const MENU_CATEGORY_API_URL = `${API_URL}/api/menuCategory`;

class MenuCategoryService {

    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }

    getAll() {
        return axios.get(MENU_CATEGORY_API_URL, { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${MENU_CATEGORY_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${MENU_CATEGORY_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${MENU_CATEGORY_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${MENU_CATEGORY_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }
}

export default new MenuCategoryService();
