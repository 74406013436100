import React, { useEffect, useState, useCallback } from "react";
import {Table, Label, Button} from "flowbite-react";
import axios from "axios";
import { API_URL } from "../../constants";
import DeliveryBagService from "../../services/DeliveryBagService";
import KitchenService from "../Admin/Kitchen/KitchenService";
import { toast } from 'react-toastify';
import Spinner from "../../components/Spinner/Spinner";
import jsPDF from "jspdf";
import {FaFilePdf} from "react-icons/fa";

const KitchenCsr = () => {
    const token = localStorage.getItem("token");
    const [orderedItemsByEmployee, setOrderedItemsByEmployee] = useState([]);
    const [deliveryBags, setDeliveryBags] = useState([]);
    const [userGroups, setUserGroups] = useState([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState({
        id: 0,
    });
    const [selectedSlot, setSelectedSlot] = useState({
        slot: "",
    });
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);

    const combineOrdersByUser = (data) => {
        // Function to split employee ID into prefix and numeric part
        const splitEmployeeId = (id) => {
            const match = id.match(/(\D+)(\d+)/);
            if (match) {
                return [match[1], parseInt(match[2], 10)];
            }
            return [id, 0]; // If no numeric part, return 0
        };

        // Custom comparator function
        const compareEmployeeIds = (a, b) => {
            const [prefixA, numA] = splitEmployeeId(a.user.employeeId);
            const [prefixB, numB] = splitEmployeeId(b.user.employeeId);

            if (prefixA === prefixB) {
                return numA - numB;
            }
            return prefixA.localeCompare(prefixB);
        };

        // Sort the data by user employee ID
        const sortedData = data.sort(compareEmployeeIds);

        // Sort orders within each user by item name
        sortedData.forEach(user => {
            user.orders.sort((a, b) => a.item.localeCompare(b.item));
        });

        return processOrders(sortedData);
    };

    const processOrders = (data) => {
        return data.flatMap(cart =>
            cart.orders.map(o => ({
                cartId: cart.id,
                customerNote: cart.customerNote,
                deliveryBag: cart.deliveryBag,
                deliveryStatusId: cart.deliveryStatusId,
                expectedDeliveryTime: cart.expectedDeliveryTime,
                slot: cart.slot.name,
                status: cart.status.name,
                total: cart.total,
                totalWithoutDiscount: cart.totalWithoutDiscount,
                userId: cart.user.id,
                userEmail: cart.user.email,
                userEmployeeId: cart.user.employeeId,
                userGroupId: cart.userGroup.id,
                userGroupName: cart.userGroup.name,
                itemId: o.id,
                itemName: o.item,
                itemPrice: o.price,
                itemQuantity: o.quantity,
                itemTotalPrice: o.totalPrice,
                itemCustomerNote: o.customerNote,
                itemUserId: o.customerUser.id,
                itemUserEmail: o.customerUser.email,
                itemUserEmployeeId: o.customerUser.employeeId,
                itemUserGroupId: o.userGroup.id,
                itemUserGroupName: o.userGroup.name
            }))
        );
    };

    const fetchData = useCallback(async (data) => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${API_URL}/api/customerOrderCart/orderListByCart`, data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": `${token}`,
                    },
                }
            );
            const combinedData = combineOrdersByUser(response.data);
            setOrderedItemsByEmployee(combinedData);
        } catch (error) {
            toast.error('Error fetching data');
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [token]);

    const search = (data) => {
        fetchData(data);
        generateDeliveryBags(data);
    };

    const generateDeliveryBags = async (data) => {
        setLoading(true);
        DeliveryBagService.generateDeliveryBags(data)
            .then((response) => {
                setDeliveryBags(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Error fetching delivery bags");
                console.error("Error fetching delivery bags:", error);
            });
    };

    useEffect(() => {
        KitchenService.listMyGroups()
            .then((response) => {
                setUserGroups(response.data);
                if (response.data.length > 0) {
                    setSelectedUserGroup(response.data[0]);
                }
                setSelectedSlot('LUNCH');
                let data = { userGroupId: response.data[0].id, slot: "LUNCH" };
                generateDeliveryBags(data);
                fetchData(data);
            })
            .catch((error) => {
                toast.error("Error fetching my groups");
                console.error("Error fetching my groups:", error);
            });
    }, [fetchData]);

    const handleGroupDropdownChange = async (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setSelectedUserGroup({ id: selectedValue });
    };

    const handleSlotDropdownChange = async (e) => {
        const selectedValue = e.target.value;
        setSelectedSlot({ slot: selectedValue });
    };

    const addToBag = async (bagId, cartIds) => {
        const payload = {
            ids: cartIds,
            bagId: bagId,
        };
        try {
            await axios.put(
                `${API_URL}/api/customerOrderCart/addToBag`,
                payload,
                {
                    headers: {
                        "X-Auth-Token": `${token}`,
                    },
                }
            );
        } catch (error) {
            toast.error("Error adding to delivery bag");
            console.error("Error adding to delivery bag:", error);
        }
    }

    const handleBagDropdownChange = (e, id) => {
        const selectedValue = parseInt(e.target.value, 10);
        addToBag(selectedValue, [id]).then(() => {
            setOrderedItemsByEmployee((prevItems) =>
                prevItems.map((employee) => {
                    if (employee.cartId === id) {
                        return { ...employee, deliveryStatusId: selectedValue };
                    }
                    return employee;
                })
            );
        })
    };

    const addCartsInBag = async (e) => {
        const bagId = parseInt(e.target.value, 10);
        const ids = filteredOrderedItemsByEmployee?.map(item => item.cartId);
        addToBag(bagId, ids).then(() => {
            setOrderedItemsByEmployee((prevItems) =>
                prevItems.map((employee) => {
                    return { ...employee, deliveryStatusId: bagId };
                })
            );
        })
    }

    // Filter ordered items based on search term
    const filteredOrderedItemsByEmployee = orderedItemsByEmployee.filter(cart =>
        cart.itemName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cart.userEmployeeId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        cart.itemUserEmail.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const printPackagingInfo = () => {
        const pdf = new jsPDF('p', 'mm', 'a4');
        const margin = 10;
        const pageHeight = pdf.internal.pageSize.height;
        const startY = margin;
        let currentY = startY;

        const table = document.getElementById('packagingTable');
        const rows = table.getElementsByTagName('tbody')[0].getElementsByTagName('tr');

        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].getElementsByTagName('td');
            const employeeId = cells[0].innerText;
            const group = cells[1].innerText;
            const item = cells[2].innerText;
            const quantity = parseInt(cells[3].innerText);

            for (let j = 0; j < quantity; j++) {
                if (currentY + 30 > pageHeight - margin) {
                    pdf.addPage();
                    currentY = startY;
                }
                pdf.setFontSize(12);

                pdf.setFont("helvetica", "bold");
                pdf.text(item, margin, currentY);
                currentY += 5;

                pdf.setFont("helvetica", "normal");
                pdf.text(`${employeeId}\n${group}\nsomabhutu.com`, margin, currentY);

                currentY += 25;
            }
        }
        pdf.save('packaging_info.pdf');
    };

    return (
        <>
            <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
                <div className="flex justify-between">
                    <div className="flex mb-10 gap-2.5">
                        <div>
                            <label
                                htmlFor="select-group"
                                className="block text-sm font-medium leading-6 text-gray-900">
                                Select Group
                            </label>
                            <div className="mt-2">
                                <select
                                    id="select-group"
                                    value={selectedUserGroup.id}
                                    onChange={handleGroupDropdownChange}
                                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                    {userGroups.map((userGroup) => (
                                        <option key={userGroup.id} value={userGroup.id}>
                                            {userGroup.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor="select-slot"
                                className="block text-sm font-medium leading-6 text-gray-900">
                                Select Slot
                            </label>
                            <div className="mt-2">
                                <select
                                    id="select-slot"
                                    value={selectedSlot.slot}
                                    onChange={handleSlotDropdownChange}
                                    className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                    <option value="LUNCH">LUNCH</option>
                                    <option value="BREAKFAST">BREAKFAST</option>
                                    <option value="SNACKS">SNACKS</option>
                                    <option value="DINNER">DINNER</option>
                                </select>
                            </div>
                        </div>
                        <button
                            onClick={() => search({userGroupId: selectedUserGroup.id, slot: selectedSlot.slot})}
                            className="mt-8 bg-lime-600 rounded-lg px-6 py-2.5 font-semibold text-white text-sm hover:bg-lime-500 focus:ring-2 focus:ring-lime-500 transition-all">
                            Search
                        </button>
                        <Button
                            onClick={printPackagingInfo}
                            className="bg-blue-600 hover:bg-blue-700 transition-all flex items-center gap-2 relative group"
                        >
                            <FaFilePdf className="text-white" />
                            <span className="hidden group-hover:inline text-white">Print PDF</span>
                        </Button>
                    </div>
                    <div>
                        <label htmlFor="search-item" className="block text-sm font-medium leading-6 text-gray-900">
                            Search Item
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                placeholder="Search by item name, employee ID, or email"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="block min-w-80 w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
                {loading ? <Spinner /> :
                    <>
                        <div className="overflow-x-auto px-0.5" >
                            <div className="mb-10">
                                <Table className="drop-shadow-none" id="packagingTable">
                                    <Table.Head>
                                        <Table.HeadCell>EmployeeId</Table.HeadCell>
                                        <Table.HeadCell>Group</Table.HeadCell>
                                        <Table.HeadCell>Item</Table.HeadCell>
                                        <Table.HeadCell>Quantity</Table.HeadCell>
                                        <Table.HeadCell>Bag</Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {filteredOrderedItemsByEmployee.map((cart) => (
                                            <Table.Row
                                                key={cart.itemId}
                                                className="bg-white">
                                                <Table.Cell>{cart.userEmployeeId ? cart.userEmployeeId : cart.itemUserEmail}</Table.Cell>
                                                <Table.Cell>
                                                    {cart.itemUserGroupName ? cart.itemUserGroupName : 'N/A'}
                                                </Table.Cell>
                                                <Table.Cell>{cart.itemName}</Table.Cell>
                                                <Table.Cell>{cart.itemQuantity}</Table.Cell>
                                                <Table.Cell>
                                                    <select
                                                        name="bagId"
                                                        value={cart.deliveryStatusId}
                                                        onChange={(e) => handleBagDropdownChange(e, cart.cartId)}
                                                        className="rounded-lg border-0 px-2 py-2.5 text-gray-900 shadow-sm ring-1 ring-inse ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6">
                                                        <option value={0}>Select Bag</option>
                                                        {deliveryBags.map((bag) => (
                                                            <option key={bag.id} value={bag.id}>
                                                                {bag.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                        <div className="text-right mt-6 flex justify-end items-center gap-3">
                            <Label value="Add carts into delivery bag"/>
                            <select
                                name="bagId"
                                onChange={(e) => addCartsInBag(e)}
                                className="rounded-lg border-0 px-2 py-2.5 text-gray-900 shadow-sm ring-1 ring-inse ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6">
                                <option value={0}>Select Bag</option>
                                {deliveryBags.map((bag) => (
                                    <option key={bag.id} value={bag.id}>
                                        {bag.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default KitchenCsr;