import axios from 'axios';
import { API_URL } from '../constants';

const INVENTORY_API_URL = `${API_URL}/api/inventory`;

const token = localStorage.getItem('token'); // Adjust this to how you store/retrieve your token
console.log(token);
const headers = {
    'Content-Type': 'application/json',
    'X-Auth-Token': `${token}` // Set your authorization token here
};

class InventoryService {
    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }
    getAll() {
        return axios.get(INVENTORY_API_URL, { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${INVENTORY_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${INVENTORY_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${INVENTORY_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${INVENTORY_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }

    listForKitchen(date, data) {
        const encodedDate = encodeURIComponent(date);
        return axios.post(`${INVENTORY_API_URL}/listForKitchen?date=${date}`, data, { headers: this.getHeaders() });
    }

    listForCustomer(data) {
        return axios.post(`${INVENTORY_API_URL}/list`, data,{ headers: this.getHeaders() });
    }

    copyToNextDay(data) {
        return axios.post(`${INVENTORY_API_URL}/copyToNextDay`, data,{ headers: this.getHeaders() });
    }
}

export default new InventoryService();
