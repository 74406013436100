import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import UserGroupService from "../../Admin/UserGroup/UserGroupService";
import {toast} from "react-toastify";
import {FaArrowLeft} from "react-icons/fa6";

const OrganizationAddUserForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [addedUser, setAddedUser] = useState({
        name: '',
        email: '',
        password: '',
        phone1: '',
        phone2: '',
        employeeId: '',
        company: '',
        dateOfBirth: ''
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const {name, value} = e.target;
        setAddedUser(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear errors for a particular field when user starts typing
        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: null
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};
        // Validate required fields
        if (!addedUser.name.trim()) {
            newErrors.name = 'Username is required';
        }else if (!/^[A-Za-z\s]+$/.test(addedUser.name)) {
            newErrors.name = "Name can only contain alphabets and spaces";
        }
        if (!addedUser.phone1.trim()) {
            newErrors.phone1 = 'Phone is required';
        }else if (!/^\d{10}$/.test(addedUser.phone1)) {
            newErrors.phone1 = "Phone number must be 10 digits";
        }
        if (!addedUser.dateOfBirth.trim()) {
            newErrors.dateOfBirth = 'Date of Birth is required';
        }
        // Set errors state or submit form if no errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        }else {
            if (id) {
                UserGroupService.addNewUser(Number(id), addedUser)
                    .then(response => {
                        UserGroupService.listUsers(Number(id))
                            .then(response => {
                                toast.success('Added new user');
                                navigate(`/settings/users/${id}`);
                            })
                            .catch(e => {
                                const errorMessage = e.response?.data?.message || "Error occurred.";
                                toast.error(errorMessage)
                            });
                    })
                    .catch(e => {
                        const errorMessage = e.response?.data?.message || "Error occurred.";
                        toast.error(errorMessage)
                    });
            }
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <button onClick={() => navigate(`/settings/users/${id}`)}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                <FaArrowLeft/>
                <span>Back</span>
            </button>
            <div className="mt-10 mb-10 sm:mx-auto sm:w-full sm:max-w-md bg-white shadow-lg px-12 py-8 rounded-md">
                <h2 className="text-2xl font-semibold mb-6">Add user</h2>
                <form onSubmit={handleSubmit} className="space-y-5">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                            Name <span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2">
                            <input
                                id="name"
                                name="name"
                                type="text"
                                value={addedUser.name}
                                onChange={handleChange}
                                required
                                placeholder="Enter name"
                                className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                style={{border: errors.username ? '1px solid red' : ''}}
                            />
                            {errors.name && <p style={{color: 'red'}}>{errors.name}</p>}
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-900">
                            Email address <span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                value={addedUser.email}
                                onChange={handleChange}
                                required
                                placeholder="Enter email address"
                                className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                style={{border: errors.email ? '1px solid red' : ''}}
                            />
                            {errors.email && <p style={{color: 'red'}}>{errors.email}</p>}
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-900">
                            Phone 1 <span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2">
                            <input
                                id="phone"
                                name="phone1"
                                type="text"
                                autoComplete="phone1"
                                value={addedUser.phone1}
                                onChange={handleChange}
                                required
                                placeholder="Enter phone number"
                                className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                style={{border: errors.phone1 ? '1px solid red' : ''}}
                            />
                            {errors.phone1 && <p style={{color: 'red'}}>{errors.phone1}</p>}
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="employeeId"
                            className="block text-sm font-medium text-gray-900">
                            Employee ID
                        </label>
                        <div className="mt-2">
                            <input
                                id="employeeId"
                                name="employeeId"
                                type="text"
                                autoComplete="employeeId"
                                value={addedUser.employeeId}
                                onChange={handleChange}
                                placeholder="Enter employee id"
                                className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                style={{border: errors.employeeId ? '1px solid red' : ''}}
                            />
                            {errors.employeeId && <p style={{color: 'red'}}>{errors.employeeId}</p>}
                        </div>
                    </div>
                    <div>
                        <label
                            htmlFor="dateOfBirth"
                            className="block text-sm font-medium text-gray-900">
                            Date of Birth <span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2">
                            <input
                                id="dateOfBirth"
                                name="dateOfBirth"
                                type="date"
                                autoComplete="dateOfBirth"
                                value={addedUser.dateOfBirth}
                                onChange={handleChange}
                                required
                                placeholder="Enter date of birth"
                                className="block w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                                style={{border: errors.dateOfBirth ? '1px solid red' : ''}}
                            />
                            {errors.dateOfBirth && <p style={{color: 'red'}}>{errors.dateOfBirth}</p>}
                        </div>
                    </div>

                    <div className="pt-4">
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-lg bg-6 p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">
                            Add User
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OrganizationAddUserForm;
