import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { NavComponent } from "./components/Nav/Nav";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from "./pages/Home/Home";
import { FooterComponent } from "./components/Footer/Footer";
import Login from "./pages/Auth/Login/Login";
import SignUp from "./pages/Auth/SignUp/SignUp";
import Order from "./pages/Organization/Order/Order";
import OrderStatus from "./pages/Organization/OrderStatus/OrderStatus";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ChangePassword from "./pages/Auth/ChangePassword/ChangePassword";
import OrderHistory from "./pages/OrderHistory/OrderHistory";
import OrderHistoryIndividual from "./pages/OrderHistoryIndividual/OrderHistoryIndividual";
import KitchenOrderLists from "./pages/Kitchen/KitchenOrderLists";
import KitchenCsr from "./pages/Kitchen/KitchenCsr";
import { UserProvider } from "./context/UserContext";
import UserGroupList from "./pages/Admin/UserGroup/UserGroupList";
import UserGroupForm from "./pages/Admin/UserGroup/UserGroupForm";
import KitchenList from "./pages/Admin/Kitchen/KitchenList";
import KitchenForm from "./pages/Admin/Kitchen/KitchenForm";
import AssignContractedGroupForm from "./pages/Admin/Kitchen/AssignContractedGroupForm";
import MenuCategoryList from "./pages/Admin/MenuCategory/MenuCategoryList";
import MenuCategoryForm from "./pages/Admin/MenuCategory/MenuCategoryForm";
import MenuItemList from "./pages/Admin/MenuItem/MenuItemList";
import MenuItemForm from "./pages/Admin/MenuItem/MenuItemForm";
import AssignMenuCategoryForm from "./pages/Admin/Kitchen/AssignMenuCategoryForm";
import AssignMenuItemForm from "./pages/Admin/Kitchen/AssignMenuItemForm";
import AdminIndex from "./pages/Admin";
import InventoryList from "./pages/Kitchen/Inventory/InventoryList";
import InventoryForm from "./pages/Kitchen/Inventory/InventoryForm";
import Calendar from "./pages/Organization/Order/CalendarOrder";
import AddUserForm from "./pages/Admin/UserGroup/AddUserForm";
import DeliveryBagList from "./pages/Admin/DeliveryBag/DeliveryBagList";
import DeliveryBagForm from "./pages/Admin/DeliveryBag/DeliveryBagForm";
import AssignDeliveryBagForm from "./pages/Admin/Kitchen/AssignDeliveryBagForm";
import DeliveringBagList from "./pages/Kitchen/Delivery/DeliveringBagList";
import AddMenuForm from "./pages/Admin/UserGroup/AddMenuForm";
import ReportPerGroup from "./pages/Organization/Admin/ReportPerGroup";
import ReportPerUser from "./pages/Organization/Admin/ReportPerUser";
import AddOwnerForm from "./pages/Admin/UserGroup/AddOwnerForm";
import OrganizationUserGroupList from "./pages/Organization/Admin/OrganizationUserGroupList";
import OrganizationEditUserGroupForm from "./pages/Organization/Admin/OrganizationEditUserGroupForm";
import OrganizationUserListForm from "./pages/Organization/Admin/OrganizationUserListForm";
import OrganizationAddChildUserGroupForm from "./pages/Organization/Admin/OrganizationAddChildUserGroupForm";
import OrganizationAddUserByEmailForm from "./pages/Organization/Admin/OrganizationAddUserByEmailForm";
import OrganizationAddUserForm from "./pages/Organization/Admin/OrganizationAddUserForm";
import ProfileSetting from "./pages/Auth/ProfileSetting/ProfileSetting";
import OrganizationEditUserForm from "./pages/Organization/Admin/OrganizationEditUserForm";
import AssignDependentMenusForm from "./pages/Admin/MenuItem/AssignDependentMenusForm";
import KitchenReportPerGroup from "./pages/Kitchen/Reporting/KitchenReportPerGroup";
import KitchenReportPerUser from "./pages/Kitchen/Reporting/KitchenReportPerUser";
import OrderByDate from "./pages/Organization/OrderStatus/OrderByDate";
import ReportPerDate from "./pages/Organization/Admin/ReportPerDate";
import AdjustOrder from "./pages/Organization/Admin/AdjustOrder";
import TimeExceededOrders from "./pages/Kitchen/TimeExceededOrders";

export default function App() {
  return (
    <>
      {/* {showLogin?<LoginPopup setShowLogin={setShowLogin}/>:<></>} */}

      <div className="app flex flex-col justify-between min-h-screen">

        <UserProvider>
          <ToastContainer hideProgressBar={true}/>
          <NavComponent />
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route
                path="/placeOrder"
                element={
                  // <PrivateRoute>
                    <Order />
                  // </PrivateRoute>
                }
              />
              <Route
                path="/orderCalendar"
                element={
                  <PrivateRoute>
                    <Calendar />
                  </PrivateRoute>
                }
              />
              <Route
                path="/orderStatus"
                element={
                  <PrivateRoute>
                    <OrderStatus />
                  </PrivateRoute>
                }></Route>
                <Route
                    path="/orderByDate"
                    element={
                        <PrivateRoute>
                            <OrderByDate />
                        </PrivateRoute>
                    }></Route>
              <Route
                path="/reportPerGroup"
                element={
                  <PrivateRoute>
                    <ReportPerGroup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/reportPerUser/:id"
                element={
                  <PrivateRoute>
                    <ReportPerUser />
                  </PrivateRoute>
                }
              />
                <Route
                    path="/adjustOrder"
                    element={
                        <PrivateRoute>
                            <AdjustOrder />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/reportPerDate"
                    element={
                        <PrivateRoute>
                            <ReportPerDate />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute>
                            <OrganizationUserGroupList />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings/addGroup/:id"
                    element={
                        <PrivateRoute>
                            <OrganizationAddChildUserGroupForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings/edit/:id"
                    element={
                        <PrivateRoute>
                            <OrganizationEditUserGroupForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings/users/:id"
                    element={
                        <PrivateRoute>
                            <OrganizationUserListForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings/addUser/:id"
                    element={
                        <PrivateRoute>
                            <OrganizationAddUserForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings/editUser/:id"
                    element={
                        <PrivateRoute>
                            <OrganizationEditUserForm />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings/addUserByEmail/:id"
                    element={
                        <PrivateRoute>
                            <OrganizationAddUserByEmailForm />
                        </PrivateRoute>
                    }
                />
              <Route
                path="/auth/login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }></Route>
              <Route
                path="/auth/signUp"
                element={
                  <PublicRoute>
                    <SignUp />
                  </PublicRoute>
                }></Route>
            <Route
                path="/auth/profileSetting"
                element={
                    <PrivateRoute>
                        <ProfileSetting />
                    </PrivateRoute>
                }></Route>
              <Route
                path="/auth/changePassword"
                element={
                  <PrivateRoute>
                    <ChangePassword />
                  </PrivateRoute>
                }
              />
              <Route path="/orderHistory" element={<OrderHistory />} />
              <Route
                path="/orderHistoryIndividual"
                element={<OrderHistoryIndividual />}
              />
              <Route
                path="/kitchen/kitchenOrderLists"
                element={
                  <PrivateRoute>
                    <KitchenOrderLists />
                  </PrivateRoute>
                }
              />
                <Route
                    path="/kitchen/timeExceededOrders"
                    element={
                        <PrivateRoute>
                            <TimeExceededOrders />
                        </PrivateRoute>
                    }
                />
              <Route
                path="/kitchen/kitchenCsr"
                element={
                  <PrivateRoute>
                    <KitchenCsr />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kitchen/delivery"
                element={
                  <PrivateRoute>
                    <DeliveringBagList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kitchen/inventory"
                element={
                  <PrivateRoute>
                    <InventoryList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kitchen/inventory/add"
                element={
                  <PrivateRoute>
                    <InventoryForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kitchen/inventory/edit/:id"
                element={
                  <PrivateRoute>
                    <InventoryForm />
                  </PrivateRoute>
                }
              />
            <Route
                path="/kitchen/reporting"
                element={
                    <PrivateRoute>
                        <KitchenReportPerGroup />
                    </PrivateRoute>
                }
            />
            <Route
                path="/kitchen/reportPerUser/:id"
                element={
                    <PrivateRoute>
                        <KitchenReportPerUser />
                    </PrivateRoute>
                }
            />
            <Route
                path="/admin"
                element={
                  <PrivateRoute>
                    <AdminIndex />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/userGroup"
                element={
                  <PrivateRoute>
                    <UserGroupList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/userGroup/add"
                element={
                  <PrivateRoute>
                    <UserGroupForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/userGroup/edit/:id"
                element={
                  <PrivateRoute>
                    <UserGroupForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/userGroup/addUser/:id"
                element={
                  <PrivateRoute>
                    <AddUserForm />
                  </PrivateRoute>
                }
              />

            <Route
                path="/admin/userGroup/addOwner/:id"
                element={
                    <PrivateRoute>
                        <AddOwnerForm />
                    </PrivateRoute>
                }
            />

              <Route
                path="/admin/userGroup/addMenu/:id"
                element={
                  <PrivateRoute>
                    <AddMenuForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/kitchen"
                element={
                  <PrivateRoute>
                    <KitchenList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/kitchen/add"
                element={
                  <PrivateRoute>
                    <KitchenForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/kitchen/edit/:id"
                element={
                  <PrivateRoute>
                    <KitchenForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/kitchen/assignContractedGroup/:id"
                element={
                  <PrivateRoute>
                    <AssignContractedGroupForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/kitchen/assignDeliveryBag/:id"
                element={
                  <PrivateRoute>
                    <AssignDeliveryBagForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/deliveryBag"
                element={
                  <PrivateRoute>
                    <DeliveryBagList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/deliveryBag/add"
                element={
                  <PrivateRoute>
                    <DeliveryBagForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/deliveryBag/edit/:id"
                element={
                  <PrivateRoute>
                    <DeliveryBagForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/menuCategory"
                element={
                  <PrivateRoute>
                    <MenuCategoryList />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/menuCategory/add"
                element={
                  <PrivateRoute>
                    <MenuCategoryForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/menuCategory/edit/:id"
                element={
                  <PrivateRoute>
                    <MenuCategoryForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/kitchen/assignMenuCategory/:id"
                element={
                  <PrivateRoute>
                    <AssignMenuCategoryForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/menuItem"
                element={
                  <PrivateRoute>
                    <MenuItemList />
                  </PrivateRoute>
                }
              />
                <Route
                    path="/admin/menuItem/assignDependencies/:id"
                    element={
                        <PrivateRoute>
                            <AssignDependentMenusForm />
                        </PrivateRoute>
                    }
                />
              <Route
                path="/admin/menuItem/add"
                element={
                  <PrivateRoute>
                    <MenuItemForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/menuItem/edit/:id"
                element={
                  <PrivateRoute>
                    <MenuItemForm />
                  </PrivateRoute>
                }
              />

              <Route
                path="/admin/kitchen/assignMenuItem/:id"
                element={
                  <PrivateRoute>
                    <AssignMenuItemForm />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
          <FooterComponent />
          {/* </ToastContainer> */}
        </UserProvider>

      </div>
    </>
  );
}
