// src/services/MenuItemService.js

import axios from 'axios';
import { API_URL } from '../../../constants';

const MENU_ITEM_API_URL = `${API_URL}/api/menuItem`;

class MenuItemService {

    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }

    getAll() {
        return axios.get(MENU_ITEM_API_URL, { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${MENU_ITEM_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${MENU_ITEM_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${MENU_ITEM_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${MENU_ITEM_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }

    listDependentMenus(id) {
        return axios.get(`${MENU_ITEM_API_URL}/listDependentMenus/${id}`, { headers: this.getHeaders() });
    }

    assignDependentMenus(id, data) {
        return axios.put(`${MENU_ITEM_API_URL}/assignDependentMenus/${id}`, data, { headers: this.getHeaders() });
    }

    removeDependentMenus(id, data) {
        return axios.put(`${MENU_ITEM_API_URL}/removeDependentMenus/${id}`, data, { headers: this.getHeaders() });
    }
}

export default new MenuItemService();
