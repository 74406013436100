import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';
import { API_URL } from '../../../constants';
import { toast } from 'react-toastify';
import PasswordInput from '../../../components/Inputs/PasswordInput';
import Spinner from '../../../components/Spinner/Spinner';

export default function Login() {
  const [username, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useUser();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${API_URL}/auth/login`, { username, password });
      localStorage.setItem('token', response.data.access_token);
      fetchUserDetails(response.data.access_token);
    } catch (err) {
      // toast.error("Invalid email or password");
      setError('Invalid email or password');
      setLoading(false);
    }
  };
  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.get(`${API_URL}/api/customerUser/getLoggedUser`, {
        headers: {
          'X-Auth-Token': `${token}`
        }
      });
      setUser(response.data);
      response.data.isKitchen ? navigate('/kitchen/kitchenOrderLists') : navigate('/placeOrder?slot=lunch');
    } catch (error) {
      localStorage.removeItem('token');
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please log in again.");
          navigate('/auth/login');
        } else if (!navigator.onLine) {
          toast.error("No internet connection. Please check your network settings.");
        } else {
          navigate('/error');
        }
    }
  }
    return (
      <>
      {loading ? <Spinner /> :
        <div className="container mx-auto flex set-h-minus-nav flex-1 flex-col justify-center px-6 py-12 lg:px-8 animate-fadeIn">
          <div className="sm:mx-auto sm:w-full sm:max-w-md bg-white shadow-lg px-12 py-8 rounded-md">
            <h2 className="mb-5 text-xl font-bold leading-9 tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={username}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter email address"
                    className="block w-full rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    Password
                  </label>
                  <div className="text-sm">
                    <span className="font-semibold text-lime-600 hover:text-lime-500 cursor-pointer">
                      Forgot password?
                    </span>
                  </div>
                </div>
                <PasswordInput
                  id="password"
                  name="password"
                  password={password}
                  placeholder='Enter Password'
                  setPassword={setPassword}
                />
                {error && <p className='error-text'>{error}</p>}
              </div>

              <div className="pt-4">
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-lg bg-lime-600 p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 transition-all"
                >
                  Sign in
                </button>
              </div>
            </form>

            <p className="mt-5 text-center text-sm text-gray-900 flex justify-between items-center gap-2">
                <span>Don't have an account?</span>
                <Link to='/auth/signUp' className="font-semibold leading-6 text-lime-600 hover:text-lime-500 transition-all">
                    Sign Up
                </Link>
            </p>
          </div>
        </div>
      }
      </>
    )
  }
