import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Table, Button } from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../../constants";
import { useParams } from "react-router-dom";
import CustomerUserService from "../../../services/CustomerUserService";
import { FaArrowLeft } from "react-icons/fa6";
import { format, subDays } from "date-fns";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {FaFilePdf} from "react-icons/fa";

export default function ReportPerUser() {
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialFromDate = queryParams.get("fromDate") ? new Date(queryParams.get("fromDate")) : subDays(new Date(), 30);
  const initialToDate = queryParams.get("toDate") ? new Date(queryParams.get("toDate")) : new Date();
  const status = queryParams.get("status") ? queryParams.get("status") : 'DELIVERED' ;
  const [data, setData] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);
  const [totals, setTotals] = useState({
    total: 0,
    subsidy: 0,
    personalLiability: 0,
  });
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    CustomerUserService.get(id).then((response) => {
      setCurrentUser(response.data);
    }).catch((e) => {
      console.log(e);
    });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [id, fromDate, toDate, token]);

  const fetchData = async () => {
    try {
      const formattedFromDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
      const formattedToDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
      const response = await axios.post(
          `${API_URL}/api/customerOrderCart/listByUser/${id}`,
          { fromDate: formattedFromDate, toDate: formattedToDate, status: status },
          {
            headers: {
              "X-Auth-Token": `${token}`,
            },
          }
      );
      const sortedData = response.data.list.sort((a, b) => new Date(a.date) - new Date(b.date));
      setData(sortedData);
      calculateTotals(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateTotals = (list) => {
    const totalAmounts = list.reduce((acc, item) => acc + item.total, 0);
    const totalSubsidy = list.reduce((acc, item) => acc + item.subsidy, 0);
    const totalLiability = list.reduce(
        (acc, item) => acc + (item.personalLiability || 0),
        0
    );

    setTotals({
      total: totalAmounts,
      subsidy: totalSubsidy,
      personalLiability: totalLiability,
    });
  };

  const goToOrderByDate = (deliveryDate) => {
    const formattedDeliveryDate = deliveryDate ? format(deliveryDate, "yyyy-MM-dd") : null;
    console.log("Navigating with deliveryDate:", deliveryDate, "Formatted:", formattedDeliveryDate);
    navigate(`/reportPerDate/?deliveryDate=${formattedDeliveryDate}&id=${id}`);
  };

  const printTableAsPDF = () => {
    const input = document.getElementById("tableToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("report-per-user.pdf");
    });
  };

  return (
      <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
        <div className="nav-buttons mb-4 flex space-x-4">
          <button onClick={() => navigate('/reportPerGroup')}
                  className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-200  rounded-lg">
            <FaArrowLeft />
            <span>Back</span>
          </button>

          <div className="flex space-x-4">
            <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                dateFormat="MMMM d, yyyy"
                placeholderText="Select From Date"
                className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
            />

            <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                dateFormat="MMMM d, yyyy"
                placeholderText="Select To Date"
                className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
            />

            <Button onClick={fetchData} className="bg-lime-600 hover:bg-lime-700 transition-all">
              Search
            </Button>
          </div>
          <Button
              onClick={printTableAsPDF}
              className="bg-blue-600 hover:bg-blue-700 transition-all flex items-center gap-2 relative group"
          >
            <FaFilePdf className="text-white" />
            <span className="hidden group-hover:inline text-white">Print PDF</span>
          </Button>
        </div>

        <div className="overflow-x-auto p-0.5" id="tableToPrint">
          <h2>{currentUser.name}</h2>
          <Table className="drop-shadow-none">
            <Table.Head>
              <Table.HeadCell>Date</Table.HeadCell>
              <Table.HeadCell>Group name</Table.HeadCell>
              <Table.HeadCell>Subsidy</Table.HeadCell>
              <Table.HeadCell>Personal Liability</Table.HeadCell>
              <Table.HeadCell>Total</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {data.map((item, index) => (
                  <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={index}>
                    <Table.Cell>
                      <span
                          className="text-lime-600 cursor-pointer hover:text-lime-700 hover:underline transition-all"
                          onClick={() => {
                            goToOrderByDate(item.date);
                          }}
                      >
                      {formatDate(item.date)}
                      </span>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {item.userGroup.name}
                    </Table.Cell>
                    <Table.Cell>{item.subsidy}</Table.Cell>
                    <Table.Cell>{item.personalLiability || "-"}</Table.Cell>
                    <Table.Cell>{item.total}</Table.Cell>
                  </Table.Row>
              ))}
            </Table.Body>
            <Table.Body className="divide-y">
              <Table.Row className="bg-gray-50 text-gray-700 font-semibold">
                <Table.Cell>Total</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>{totals.subsidy}</Table.Cell>
                <Table.Cell>{totals.personalLiability}</Table.Cell>
                <Table.Cell>{totals.total}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
  );
}