import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {Table, Button, Modal} from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../../constants";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { FaTrashAlt} from 'react-icons/fa';
import {HiOutlineExclamationCircle} from "react-icons/hi";

export default function AdjustOrder() {
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [totals, setTotals] = useState({
        total: 0,
        subsidy: 0,
        personalLiability: 0,
    });

    const [toDate, setToDate] = useState(new Date());
    const [selectedSlot, setSelectedSlot] = useState("LUNCH");
    const token = localStorage.getItem("token")
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [newSubsidy, setNewSubsidy] = useState("");
    const [inventoryItems, setInventoryItems] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedItemPrice, setSelectedItemPrice] = useState('');

    const [selectedSlotForOrder, setSelectedSlotForOrder] = useState('');

    const [selectedEmployee, setSelectedEmployee] = useState('');

    const [isAdjustSubsidyModalOpen, setIsAdjustSubsidyModalOpen] = useState(false);
    const [modalSubsidyContent, setModalSubsidyContent] = useState(null);
    const [modalSubsidy, setModalSubsidy] = useState(null);


    const handleEmployeeChange = (event) => {
        const id = event.target.value;
        setSelectedEmployee(id);

    };

    useEffect(() => {
        if (selectedEmployee) {
            console.log('selected slot updated:', selectedEmployee);
        }
    }, [selectedEmployee]);



        const fetchData = async () => {
            try {
                const formattedToDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
                const response = await axios.post(
                    `${API_URL}/api/customerOrderCart/listByGroup`,
                    {fromDate: formattedToDate, toDate: formattedToDate, slot: selectedSlot},
                    {
                        headers: {
                            "X-Auth-Token": `${token}`,
                        },
                    }
                );

                const sortedData = response.data.list.sort((a, b) =>
                    a.user.name.localeCompare(b.user.name)
                );

                setData(sortedData);
                calculateTotals(sortedData);
            } catch (error) {
                const errorMessage = error.response?.data?.message || "Error fetching data.";
                toast.error(errorMessage);
                console.error("Error fetching data:", error);
            }
        };

        const calculateTotals = (list) => {
            const totalAmounts = list.reduce((acc, item) => acc + item.total, 0);
            const totalSubsidy = list.reduce((acc, item) => acc + item.subsidy, 0);
            const totalLiability = list.reduce(
                (acc, item) => acc + (item.personalLiability || 0),
                0
            );

            setTotals({
                total: totalAmounts,
                subsidy: totalSubsidy,
                personalLiability: totalLiability,
            });
        };

        const openModal = (item) => {
            setModalContent({item});
            setModalIsOpen(true);
        };

    const openSubsidyModal = (item) => {
        setModalSubsidyContent({item});
        setIsAdjustSubsidyModalOpen(true);
        setModalSubsidy(item.total)
    };

        console.log('new subsidy', newSubsidy);

        const deleteOrder = async () => {
            try {
                const response = await axios.delete(
                    `${API_URL}/api/customerOrderCart/deleteOrderByAdmin/${modalContent.item.cartId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-Auth-Token": `${token}`,
                        },
                    }
                );
                if (response.status === 200) {
                    toast.success('Order item deleted successfully');
                    fetchData(); // Re-fetch orders to update the list
                }
            } catch (error) {
                const errorMessage = error.response?.data || "Failed to delete order.";
                if (errorMessage.includes("Deletion not allowed")) {
                    toast.error("Deletion not allowed, order status must be NEW or TIME_EXCEEDED");
                } else {
                    toast.error(errorMessage);
                }
                console.error('Error deleting order:', error);
            } finally {
                setModalIsOpen(false);
            }
        };

        const adjustSubsidy = async (id) => {
            try {
                const subsidy = modalSubsidy;
                await axios.post(
                    `${API_URL}/api/customerOrderCart/adjustCartSubsidy/${modalSubsidyContent.item.cartId}`,
                    {subsidy: subsidy},
                    {
                        headers: {
                            "X-Auth-Token": `${token}`,
                        },
                    }
                );
                toast.success('Subsidy adjusted successfully');
                fetchData(); // Re-fetch orders to update the list
            } catch (error) {
                console.error("Error adjusting subsidy:", error);
            }
            finally {
                setIsAdjustSubsidyModalOpen(false);
            }
        };
        const fetchEmployee = async () => {
            try {
                const response = await axios.get(
                    `${API_URL}/api/customerUser/listUsers`,
                    {
                        headers: {
                            "X-Auth-Token": `${token}`,
                        },
                    }
                );

                if (response.data && response.data.usersList) {
                    const sortedUsers = response.data.usersList.sort((a, b) =>
                        a.firstName.localeCompare(b.firstName)
                    );
                    setUsers(sortedUsers);
                    console.log("Users set:", sortedUsers);
                } else {
                    console.warn("usersList is undefined:", response.data);
                    setUsers([]);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || "Error fetching data.";
                toast.error(errorMessage);
                console.error("Error fetching data:", error);
            }
        };

        const fetchInventoryItems = async () => {
            try {
                const response = await axios.post(
                    `${API_URL}/api/inventory/list`,
                    {slot: selectedSlotForOrder},
                    {
                        headers: {
                            "X-Auth-Token": `${token}`,
                        },
                    }
                );

                if (response.data && Array.isArray(response.data)) {
                    setInventoryItems(response.data);
                    console.log("Inventory items fetched:", response.data);
                } else {
                    console.warn("Unexpected response structure:", response.data);
                    setInventoryItems([]);
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || "Error fetching inventory data.";
                toast.error(errorMessage);
                console.error("Error fetching inventory data:", error);
            }
        };

        const handleItemChange = (event) => {
            const selectedId = event.target.value;
            setSelectedItemId(selectedId);

            const item = inventoryItems.find(item => item.item.id.toString() === selectedId);
            console.log('inventory', inventoryItems)
            console.log('selectedItem', item)
            setSelectedItem(item)
            if (item) {
                setSelectedItemPrice(item.item.price);
            } else {
                setSelectedItemPrice('');
            }
        };

        const [showForm, setShowForm] = useState(false);

        const handleButtonClick = async () => {
            await fetchEmployee();
            setShowForm(!showForm);
        };

    const handleSlotChange = async (event) => {
        const value = event.target.value;
        setSelectedSlotForOrder(value);
        console.log('selected slot', selectedSlotForOrder)

    };

    useEffect( () => {
        if (selectedSlotForOrder) {
            console.log('selected slot updated:', selectedSlotForOrder);
            fetchInventoryItems();
        }
    }, [selectedSlotForOrder]);

    const [quantity, setQuantity] = useState('');

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };

    useEffect(() => {
            if (showForm) {
                fetchEmployee();
            }
        }, [showForm]);

    const handlePlaceOrder = async () => {
        const orders = [{
            date: new Date().toISOString(),
            id: selectedItem.id,
            item: selectedItem.item,
            quantity: Number(quantity)
        }];

        const payload = { orders, slot: selectedSlotForOrder ? selectedSlotForOrder.toUpperCase() : "LUNCH", userId: Number(selectedEmployee) };
        console.log('payload', payload)

        try {
            await axios.post(`${API_URL}/api/customerOrder/placeOrder`, payload, {
                headers: {
                    'X-Auth-Token': `${token}`
                }
            });
            toast.success("Order placed successfully.");
            setShowForm(false);
            await fetchData()
            navigate('/adjustOrder');
        } catch (error) {
            const errorMessage = error.response?.data || "Failed to place order.";
            toast.error(errorMessage);
            console.log('Err', error);
        }
    };


        return (
            <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
                <div className="flex space-x-4 mb-4">
                    <DatePicker
                        selected={toDate}
                        onChange={(date) => setToDate(date)}
                        dateFormat="MMMM d, yyyy"
                        placeholderText="Select To Date"
                        className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                    />

                    <select
                        value={selectedSlot}
                        onChange={(e) => setSelectedSlot(e.target.value)}
                        className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                        placeholder="Select Slot"
                    >
                        <option value="LUNCH">LUNCH</option>
                        <option value="BREAKFAST">BREAKFAST</option>
                        <option value="SNACKS">SNACKS</option>
                        <option value="DINNER">DINNER</option>
                    </select>

                    <Button onClick={fetchData} className="bg-lime-600 hover:bg-lime-700 transition-all">
                        Search
                    </Button>
                </div>

                <div className="overflow-x-auto p-0.5" id="tableToPrint">
                    <Table className="drop-shadow-none">
                        <Table.Head>
                            <Table.HeadCell>Employee</Table.HeadCell>
                            <Table.HeadCell>Group name</Table.HeadCell>
                            <Table.HeadCell>Subsidy</Table.HeadCell>
                            <Table.HeadCell>Personal Liability</Table.HeadCell>
                            <Table.HeadCell>Total</Table.HeadCell>
                            <Table.HeadCell>Actions</Table.HeadCell>

                        </Table.Head>
                        <Table.Body className="divide-y">
                            {data.map((item, index) => (
                                <Table.Row
                                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                    key={index}
                                >
                                    <Table.Cell>
                  <span
                      className="whitespace-nowrap font-medium text-gray-900 dark:text-white"                  >
                    {item.user.name}
                  </span>
                                    </Table.Cell>
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {item.userGroup.name}
                                    </Table.Cell>

                                    <Table.Cell>{item.subsidy}</Table.Cell>
                                    <Table.Cell>{item.personalLiability || "-"}</Table.Cell>
                                    <Table.Cell>{item.total}</Table.Cell>
                                    <Table.Cell className="w-1/6 flex items-center space-x-2">
                                    <span>
                                      <FaTrashAlt
                                          className="text-md text-gray-500 hover:text-lime-600 cursor-pointer transition-colors"
                                          onClick={() => openModal(item)}
                                      />
                                    </span>
                                    {/*    <span>*/}
                                    {/*  <input*/}
                                    {/*      type="number"*/}
                                    {/*      size="5"*/}
                                    {/*      value={newSubsidy}*/}
                                    {/*      onChange={(e) => setNewSubsidy(e.target.value)}*/}
                                    {/*      className="border p-1 w-16"*/}
                                    {/*  />*/}
                                    {/*</span>*/}
                                        <button
                                            className="bg-lime-600 text-white px-3 py-1 rounded hover:bg-lime-700 transition-colors"
                                            // onClick={() => adjustSubsidy(item.cartId)}
                                            onClick={() => openSubsidyModal(item)}
                                            >
                                            Adjust Subsidy
                                        </button>
                                    </Table.Cell>

                                </Table.Row>
                            ))}
                        </Table.Body>
                        <Table.Body>
                            <Table.Row className="bg-gray-100 font-semibold border-0 rounded-none">
                                <Table.Cell>Total</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell>{totals.subsidy}</Table.Cell>
                                <Table.Cell>{totals.personalLiability}</Table.Cell>
                                <Table.Cell>{totals.total}</Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
                <Button
                    onClick={handleButtonClick}
                    className="bg-lime-600 hover:bg-lime-700 transition-all">
                    Add
                </Button>
                {showForm && (
                    <div className="mt-4 flex flex-wrap gap-4">
                        <div className="flex-1">
                            <label htmlFor="employeeName" className="block text-sm font-medium">
                                EMPLOYEE
                            </label>
                            <select
                                id="employeeName"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                                value={selectedEmployee}
                                onChange={handleEmployeeChange}
                            >
                                <option value="">Select Employee</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>
                                        {user.firstName} {user.lastName}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex-1">
                            <label htmlFor="slot" className="block text-sm font-medium">
                                SLOT
                            </label>
                            <select
                                id="slot"
                                value={selectedSlotForOrder}
                                onChange={handleSlotChange}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                            >
                                <option value="">Select Slot</option>
                                <option value="LUNCH">LUNCH</option>
                                <option value="SNACKS">SNACKS</option>
                                <option value="DINNER">DINNER</option>
                            </select>
                        </div>

                        <div className="flex-1">
                            <label htmlFor="menuItems" className="block text-sm font-medium">
                                MENU ITEM
                            </label>
                            <div className="flex items-center space-x-4 mt-1">
                                <select
                                    value={selectedItemId}
                                    onChange={handleItemChange}
                                    className="block w-full border border-gray-300 rounded-md shadow-sm"
                                >
                                    <option value="">Select an item</option>
                                    {inventoryItems.map((item) => (
                                        <option key={item.item.id} value={item.item.id}>
                                            {item.item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="flex-1">
                            <label htmlFor="quantity" className="block text-sm font-medium">
                                QUANTITY
                            </label>
                            <input
                                id="quantity"
                                type="number"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                                placeholder="Enter quantity"
                                value={quantity}
                                onChange={handleQuantityChange}
                            />
                        </div>
                        <div className="flex-1">
                            <label htmlFor="price" className="block text-sm font-medium">
                                PRICE
                            </label>
                            <textarea
                                id="price"
                                rows="1"
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                                value={selectedItemPrice || ''}
                                readOnly
                            />
                        </div>

                        <div className='mt-6'>
                            <button
                                onClick={handlePlaceOrder}
                                    className='bg-lime-600 rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'>
                                Place Order
                            </button>
                        </div>
                    </div>
                )}
                <Modal show={modalIsOpen} size="lg" onClose={() => setModalIsOpen(false)} popup>
                    <Modal.Header/>
                    <Modal.Body>
                        <div className="text-center">
                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-600"/>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to delete this?
                            </h3>
                            <div className="flex justify-center gap-4">
                                <Button color="failure" onClick={deleteOrder}>
                                    {"Yes, I'm sure"}
                                </Button>
                                <button
                                    className="px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100 rounded-lg"
                                    onClick={() => setModalIsOpen(false)}>
                                    No, cancel
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={isAdjustSubsidyModalOpen} size="lg" onClose={() => setIsAdjustSubsidyModalOpen(false)} popup>
                    <Modal.Header/>
                    <Modal.Body>
                        <div className="text-center">
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Adjust Subsidy Amount
                            </h3>
                                <span>
                              <input
                                  type="number"
                                  size="5"
                                  value={modalSubsidy}
                                  // onChange={(e) => setNewSubsidy(e.target.value)}
                                  onChange={(e) => setModalSubsidy(e.target.value)}
                                  className="border p-2 w-16 mb-4 text-center rounded-lg"
                              />
                            </span>
                            <div className="flex justify-center gap-4">
                                <Button className="bg-lime-600 hover:bg-lime-700" onClick={adjustSubsidy}>
                                    { "Ok, save" }
                                </Button>
                                <button
                                    className="px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100 rounded-lg"
                                    onClick={() => setIsAdjustSubsidyModalOpen(false)}>
                                    No, cancel
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
