import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import MenuItemService from "./MenuItemService";

const token = localStorage.getItem("token");

const AssignDependentMenusForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [menus, setMenus] = useState([]);
    const [currentMenu, setCurrentMenu] = useState([]);
    const [assignedMenus, setAssignedMenus] = useState([]);
    const [addedMenu, setAddedMenu] = useState({
        menuItemIds: []
    });

    const fetchData = () => {
        MenuItemService.listDependentMenus(Number(id))
            .then(response => {
                setAssignedMenus(response.data);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }

    useEffect(() => {
        fetchData()
        MenuItemService.getAll()
            .then(response => {
                setMenus(response.data.list);
                setCurrentMenu(response.data.list.find(menu => menu.id === Number(id)))
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [token, id]);

    const handleChange = (e) => {
        const options = e.target.options;
        const selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        setAddedMenu({...addedMenu, menuItemIds: selectedValues});
    };

    const handleRemove = (menuItemId) => {
        const confirmed = window.confirm("Are you sure you want to delete this ?");
        if (confirmed) {
            MenuItemService.removeDependentMenus(Number(id), {menuItemIds: [menuItemId]})
                .then(response => {
                    fetchData();
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            MenuItemService.assignDependentMenus(Number(id), addedMenu)
                .then(response => {
                    fetchData();
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/menuItem')
                }} className="nav-button">❮ Back
                </button>
            </div>
            <h2 className="text-2xl font-semibold mb-6">Dependent Items:: <b>{currentMenu?.name}</b> </h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Assigned Dependent Menus:</label>
                    <ul className="list-disc list-inside mb-4">
                        {assignedMenus.map(menu => (
                            <li key={menu.id} className="flex justify-between items-center">
                                {menu.name}
                                <button
                                    type="button"
                                    onClick={() => handleRemove(menu.id)}
                                    className="text-red-600 ml-2"
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Select Dependent Menus:</label>
                    <select
                        multiple
                        name="itemId"
                        value={addedMenu.itemId}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        {menus.map(menu => (
                            <option key={menu.id} value={menu.id}>
                                {menu.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default AssignDependentMenusForm;
