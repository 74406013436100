import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../constants';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner/Spinner';
import PasswordInput from '../../../components/Inputs/PasswordInput';

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(
      oldPassword.trim() !== '' &&
      newPassword.trim() !== '' &&
      confirmPassword.trim() !== ''
    );
  }, [oldPassword, newPassword, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error('New password and confirmation do not match', {
        position: "bottom-center"
      });
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${API_URL}/api/customerUser/changePassword`,
        { oldPassword, newPassword, confirmPassword },
        {
          headers: {
            'X-Auth-Token': `${token}`
          }
        }
      );
      toast.success('Password changed successfully');
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      toast.error('Failed to change password');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="container mx-auto flex set-h-minus-nav flex-1 flex-col justify-center px-6 py-12 lg:px-8 animate-fadeIn">
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white shadow-lg px-12 py-8 rounded-md">
          {loading ? <Spinner /> :
            <div>
              <h2 className="mb-5 text-xl font-bold leading-9 tracking-tight text-gray-900">
                Change Password
              </h2>
              <form className="space-y-5" onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                    Old Password <span className="text-red-600">*</span>
                  </label>
                  <PasswordInput
                    id="oldPassword"
                    name="oldPassword"
                    password={oldPassword}
                    placeholder='Enter old password'
                    setPassword={setOldPassword}
                  />
                  {/* <div className="mt-2">
                    <input
                      id="oldPassword"
                      name="oldPassword"
                      type="password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                      required
                      placeholder="Enter old password"
                      className="mt-1 block w-full rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    />
                  </div> */}
                </div>

                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-900">
                    New Password <span className="text-red-600">*</span>
                  </label>
                  <PasswordInput
                    id="newPassword"
                    name="newPassword"
                    password={newPassword}
                    placeholder='Enter new password'
                    setPassword={setNewPassword}
                  />
                  {/* <div className="mt-2">
                    <input
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                      placeholder="Enter new password"
                      className="mt-1 block w-full rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    />
                  </div> */}
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-900">
                    Confirm Password <span className="text-red-600">*</span>
                  </label>
                  <PasswordInput
                    id="confirmPassword"
                    name="confirmPassword"
                    password={confirmPassword}
                    placeholder='Enter confirm password'
                    setPassword={setConfirmPassword}
                  />
                  {/* <div className="mt-2">
                    <input
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      placeholder="Enter confirm password"
                      className="block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    />
                  </div> */}
                </div>
                <div className="pt-4">
                  {/* <button
                    type="submit"
                    disabled={!isFormValid}
                    className="flex w-full justify-center rounded-lg bg-lime-600 p-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600"
                  >
                    Change Password
                  </button> */}
                  <button
                  type="submit"
                  disabled={!isFormValid}
                  className={`flex w-full justify-center rounded-lg p-2 text-sm font-semibold leading-6 text-white shadow-sm ${
                    isFormValid ? 'bg-lime-600 hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600' : 'bg-lime-600 opacity-60 cursor-not-allowed transition-all'
                  }`}
                >
                  Change Password
                </button>
                </div>
              </form>
            </div>
          }
        </div>
      </div>
    </>
  )
}
