import axios from 'axios';
import { API_URL } from '../constants';

const DELIVERY_BAG_API_URL = `${API_URL}/api/deliveryBag`;

const token = localStorage.getItem('token');
const headers = {
    'Content-Type': 'application/json',
    'X-Auth-Token': token
};

class DeliveryBagService {

    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }
    getAll() {
        return axios.get(DELIVERY_BAG_API_URL, { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${DELIVERY_BAG_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${DELIVERY_BAG_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${DELIVERY_BAG_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${DELIVERY_BAG_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }

    generateDeliveryBags(data) {
        return axios.post(`${DELIVERY_BAG_API_URL}/generateDeliveryBags`, data, { headers: this.getHeaders() });
    }

    markAsDelivered(id) {
        return axios.put(`${DELIVERY_BAG_API_URL}/markAsDelivered/${id}`, null, { headers: this.getHeaders() });
    }

    markAsDelivering(id) {
        return axios.put(`${DELIVERY_BAG_API_URL}/markAsDelivering/${id}`, null, { headers: this.getHeaders() });
    }

    kitchenCurrentDeliveryBags(data) {
        return axios.post(`${DELIVERY_BAG_API_URL}/kitchenCurrentDeliveryBags`,data, { headers: this.getHeaders() });
    }
}

export default new DeliveryBagService();