import React, { useEffect, useState } from 'react';
import InventoryService from '../../../services/InventoryService';
import { useNavigate, useLocation } from 'react-router-dom';
import { Table, Modal, Button } from 'flowbite-react';
import Pagination from '../../../components/Pagination/Pagination';
import DateUtils from "../../../utils/DateUtils";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner/Spinner";
import KitchenService from "../../Admin/Kitchen/KitchenService";
// import Select from 'react-select';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaTrashCan, FaPenToSquare } from "react-icons/fa6";

const token = localStorage.getItem('token');

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const InventoryList = () => {
    const query = useQuery();
    const date = query.get('date');
    const [currentDate, setCurrentDate] = useState(date ? new Date(date) : new Date());
    const [inventories, setInventories] = useState([]);
    const [myMenuItems, setMyMenuItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [newInventory, setNewInventory] = useState({
        item: {},
        date: DateUtils.formatDate(currentDate),
        quantity: 0
    });
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    useEffect(() => {
        fetchInventories(currentDate);
        fetchMenuItems();
    }, [currentDate, token]);

    const handleEdit = (id) => {
        navigate(`/kitchen/inventory/edit/${id}`);
    };

    const openModal = (params) => {
        setModalContent(params);
        setModalIsOpen(true);
        setNewInventory({
            ...newInventory,
            item: newMenuItems[0] // Set the first item as default
        });
    }

    const handleDelete = () => {
        if (modalContent) {
            InventoryService.delete(modalContent)
                .then(() => {
                    toast.success("Inventory deleted successfully!");
                    setInventories(inventories.filter(inventory => inventory.id !== modalContent));
                    setModalIsOpen(false);
                })
                .catch(e => {
                    const errorMessage = e.response?.data || "Failed to list inventory.";
                    toast.error(errorMessage);
                    console.log(e);
                }
                );
        }
    };

    const handleCreate = (e) => {
        e.preventDefault();
        setLoading(true);
        console.log(currentDate, '::::::');
        InventoryService.create({
            ...newInventory,
            date:DateUtils.formatDate(currentDate),
            itemId: newInventory.item.value
        })
            .then(response => {
                toast.success("Inventory added successfully!");
                fetchInventories(currentDate);
                fetchMenuItems();
                setNewInventory({
                    item: {},
                    date: DateUtils.formatDate(currentDate),
                    quantity: 0
                });
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Failed to add inventory.";
                toast.error(errorMessage);
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
                setModalIsOpen(false)
            });
    };

    const fetchInventories = (date) => {
        setLoading(true);
        InventoryService.listForKitchen(DateUtils.formatDate(date))
            .then(response => {
                setInventories(response.data.sort((a, b) => a.item.name.localeCompare(b.item.name)));
                setLoading(false);
            })
            .catch(e => {
                setLoading(false);
                const errorMessage = e.response?.data?.message || "Failed to fetch inventory.";
                toast.error(errorMessage);
                console.log(e);
            });
    };

    const fetchMenuItems = () => {
        setLoading(true);
        KitchenService.listMyMenuItems().then(response => {
            setMyMenuItems(response.data);
            setLoading(false);
        }).catch(error => {
            const errorMessage = error.response?.data?.message || "Failed to fetch inventory list";
            toast.error(errorMessage);
            setLoading(false);
        });
    };

    const handlePreviousDay = () => {
        const previousDate = new Date(currentDate);
        previousDate.setDate(currentDate.getDate() - 1);
        setCurrentDate(previousDate);
    };

    const handleNextDay = () => {
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);
        setCurrentDate(nextDate);
    };

    const copyToTomorrow = () => {
        const nextDate = new Date(currentDate);
        nextDate.setDate(currentDate.getDate() + 1);
        InventoryService.copyToNextDay({date: DateUtils.formatDate(currentDate)}).then((resp) => {
            toast.success(`Inventory added for ${DateUtils.formatDate(nextDate)} successfully!`);
        })
    }

    // const handleInputChange = (selectedOption) => {
    //     setNewInventory({
    //         ...newInventory,
    //         item: selectedOption
    //     });
    // };

    const handleInputChange = (event) => {
        const { value } = event.target;
        const selectedItem = newMenuItems.find(item => item.value === parseInt(value));
        setNewInventory({
            ...newInventory,
            item: selectedItem
        });
    };

    const handleFormInputChange = (e) => {
        const { name, value } = e.target;
        setNewInventory({
            ...newInventory,
            [name]: value
        });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter inventories based on the search term
    const filteredInventories = inventories.filter(inventory =>
        inventory.item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredInventories.slice(indexOfFirstItem, indexOfLastItem);

    const newMenuItems = myMenuItems.filter(
        menuItem => !inventories.some(inventory => inventory.item.id === menuItem.id)
    ).map(menuItem => ({
        value: menuItem.id,
        label: menuItem.name
    }));

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
            <div className="calendar-header mb-6">
                <div className="nav-buttons items-center">
                    <button onClick={handlePreviousDay}
                            className="bg-gray-100 font-semibold rounded-full w-10 h-10 flex justify-center text-gray-900 text-sm hover:bg-lime-600 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">❮
                    </button>
                    <h2 className="current-date">{DateUtils.formatDate(currentDate)}</h2>
                    <button onClick={handleNextDay}
                            className="bg-gray-100 font-semibold rounded-full w-10 h-10 flex justify-center text-gray-900 text-sm hover:bg-lime-600 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">❯
                    </button>
                    <button
                        onClick={ copyToTomorrow }
                        className="bg-lime-600 rounded-lg font-semibold w-50 h-10 flex justify-center text-white text-sm hover:bg-lime-500 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">
                        Copy for next day
                    </button>
                </div>
                <div className="flex space-x-2">
                    <div>
                        <label htmlFor="search-item" className="block text-sm font-medium leading-6 text-gray-900">
                            Search Item
                        </label>
                        <div className="mt-2">
                            <input
                                type="text"
                                placeholder="Search by name"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="block md:min-w-80 rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Spinner /> :
                <div className="overflow-x-auto">
                    <Table className='drop-shadow-none border-collapse'>
                        <Table.Head>
                            <Table.HeadCell>Menu Item</Table.HeadCell>
                            <Table.HeadCell>Date</Table.HeadCell>
                            <Table.HeadCell>Quantity</Table.HeadCell>
                            <Table.HeadCell>Actions</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {currentItems && currentItems.length > 0 ? currentItems.map(inventory => (
                                <Table.Row className="bg-white" key={inventory.id}>
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {inventory.item.name}
                                    </Table.Cell>
                                    <Table.Cell>{DateUtils.formatDate(new Date(inventory.date))}</Table.Cell>
                                    <Table.Cell>{inventory.quantity}</Table.Cell>
                                    <Table.Cell>
                                        <div className="flex flex-wrap items-center gap-3">
                                            <FaPenToSquare
                                                onClick={() => handleEdit(inventory.id)}
                                                className="text-base cursor-pointer text-gray-700 hover:text-lime-600 transition-colors"
                                            />
                                            <FaTrashCan className='text-md text-gray-800 hover:text-lime-700 cursor-pointer transition-colors'
                                                onClick={() => openModal(inventory.id)}
                                            />
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            )) : <Table.Row>
                                <Table.Cell colSpan={5}>
                                    <h1 className="flex justify-center mt-8 mb-8 font-bold text-xl text-gray-900">No Items found</h1>
                                </Table.Cell>
                            </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </div>
            }


            <div className='flex justify-between items-center gap-3 flex-wrap'>
                <button onClick={() => openModal('inventory')}
                    className='mt-4 bg-lime-600 rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'>Add Inventory</button>
                <Pagination
                    itemsPerPage={itemsPerPage}
                    totalItems={filteredInventories.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />
            </div>

            {modalContent && modalContent === 'inventory' ?
                <Modal show={modalIsOpen} size="3xl" onClose={() => setModalIsOpen(false)} popup>
                    <Modal.Header className='px-8 py-6 border'>
                    <div className="text-xl font-semibold">Add Inventory</div>
                    </Modal.Header>
                    <Modal.Body className='px-8 py-6'>
                    <form onSubmit={handleCreate} className="space-y-6">
                    <div>
                        <div className='flex-1'>
                            <label className="block text-sm font-medium text-gray-900">Menu Item:</label>
                            <select onChange={handleInputChange} required
                                    className="mt-1 block w-full rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all">
                                { newMenuItems.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                            {/* <Select
                                 name="itemId"
                                 value={newInventory.item}
                                 onChange={handleInputChange}
                                 options={newMenuItems}
                                 styles={customStyles}
                             /> */}
                        </div>
                        <div className='flex-1 mt-4'>
                            <label className="block text-sm font-medium text-gray-700">Quantity</label>
                            <input
                                type="number"
                                name="quantity"
                                onWheel={(e) => e.target.blur()}
                                value={newInventory.quantity}
                                onChange={handleFormInputChange}
                                className="mt-1 block w-full rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                required
                            />
                        </div>
                        <div className='text-right flex-1 mt-4'>
                            <button type="submit" className="rounded-lg px-6 p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">Add Inventory</button>
                        </div>
                    </div>
                </form>
                    </Modal.Body>
                </Modal> :
                <Modal show={modalIsOpen} size="lg" onClose={() => setModalIsOpen(false)} popup>
                    <Modal.Header />
                    <Modal.Body>
                        <div className="text-center">
                            <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-600" />
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                Are you sure you want to delete this inventory item?
                            </h3>
                            <div className="flex justify-center gap-4">
                                <Button color="failure" onClick={() => handleDelete()}>
                                    {"Yes, I'm sure"}
                                </Button>
                                <button className='px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg'
                                    onClick={() => setModalIsOpen(false)}>
                                    No, cancel
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }

        </div>
    );
};

export default InventoryList;