import React, {useState, useEffect} from 'react';
import MenuCategoryService from './MenuCategoryService';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";

const MenuCategoryForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [menuCategory, setMenuCategory] = useState({
        name: '',
        description: '',
        parentId: '',
        userGroupId: '',
        displayOrder: '',
        deletedDate: '',
        dateCreated: '',
        lastUpdated: '',
    });

    useEffect(() => {
        if (id) {
            MenuCategoryService.get(Number(id))
                .then(response => {
                    setMenuCategory(response.data);
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    }, [id]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setMenuCategory({...menuCategory, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            MenuCategoryService.update(Number(id), menuCategory)
                .then(response => {
                    navigate('/admin/menuCategory');
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        } else {
            MenuCategoryService.create(menuCategory)
                .then(response => {
                    navigate('/admin/menuCategory');
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/menuCategory')
                }} className="nav-button">❮ Back
                </button>
            </div>
            <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit Menu Category' : 'Add Menu Category'}</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={menuCategory.name}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Description:</label>
                    <input
                        type="text"
                        name="description"
                        value={menuCategory.description}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Parent Category:</label>
                    <input
                        type="text"
                        name="parentId"
                        value={menuCategory.parentId}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">User Group ID:</label>
                    <input
                        type="text"
                        name="userGroupId"
                        value={menuCategory.userGroupId}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Display Order:</label>
                    <input
                        type="number"
                        name="displayOrder"
                        onWheel={(e) => e.target.blur()}
                        value={menuCategory.displayOrder}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default MenuCategoryForm;