// src/components/UserGroupList.js

import React, {useEffect, useState} from 'react';
import UserGroupService from '../../Admin/UserGroup/UserGroupService';
import {useNavigate} from 'react-router-dom';
import {Table} from "flowbite-react";
import Spinner from '../../../components/Spinner/Spinner';
import {toast} from 'react-toastify';

const OrganizationUserGroupList = () => {
    const [userGroups, setUserGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        UserGroupService.listChildren()
            .then(response => {
                setUserGroups(response.data);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleEdit = (id) => {
        navigate(`/settings/edit/${id}`);
    };

    const handleUserManagement = (id) => {
        navigate(`/settings/users/${id}`);
    };

    const handleMenuManagement = (id) => {
        navigate(`/admin/userGroup/addMenu/${id}`);
    };

    const handleOwnerManagement = (id) => {
        navigate(`/admin/userGroup/addOwner/${id}`);
    }

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this user group?");
        if (confirmed) {
            UserGroupService.delete(id)
                .then(response => {
                    setUserGroups(userGroups.filter(userGroup => userGroup.id !== id));
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                });
        }
    };

    const handleCreate = (id) => {
        navigate(`/settings/addGroup/${id}`);
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            {loading ? <Spinner/> :
                <div className="overflow-x-auto p-0.5">
                    <Table className='drop-shadow-none'>
                        <Table.Head>
                            <Table.HeadCell>I.D</Table.HeadCell>
                            <Table.HeadCell>Name</Table.HeadCell>
                            <Table.HeadCell>Subsidy</Table.HeadCell>
                            <Table.HeadCell>Actions</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {userGroups.map(userGroup => (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800"
                                           key={userGroup.id}>
                                    <Table.Cell>{userGroup.id}</Table.Cell>
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {userGroup.name}
                                        {userGroup.parent && ` (Parent ID: ${userGroup.parent.id})`}
                                    </Table.Cell>
                                    <Table.Cell>{userGroup.subsidy}</Table.Cell>
                                    <Table.Cell>
                                        <button onClick={() => handleEdit(userGroup.id)}
                                                className="mr-2 px-4 py-1.5 font-semibold bg-lime-600 text-white rounded hover:bg-lime-500 transition-colors">Edit
                                        </button>

                                        <button onClick={() => handleUserManagement(userGroup.id)}
                                                className="mr-2 px-4 py-1.5 font-semibold bg-lime-600 text-white rounded hover:bg-lime-500 transition-colors">Users
                                        </button>
                                        {userGroup.parent && userGroup.parent.id ? (
                                            ''
                                        ) : (
                                            <button type="button" onClick={() => handleCreate(userGroup.id)}
                                                    className="mr-2 px-4 py-1.5 font-semibold bg-lime-600 text-white rounded hover:bg-lime-500 transition-colors">Create
                                                child
                                            </button>
                                        )}

                                        <button onClick={() => handleDelete(userGroup.id)}
                                                className="mr-2 px-4 py-1.5 font-semibold bg-red-700 text-white rounded hover:bg-red-600 transition-colors">Delete
                                        </button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            }
        </div>
    );
};

export default OrganizationUserGroupList;
