// src/components/KitchenForm.js

import React, {useState, useEffect} from 'react';
import KitchenService from './KitchenService';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";

const KitchenForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [kitchen, setKitchen] = useState({
        name: '',
        address: '',
        phone: '',
        email: '',
        billingAddress: '',
        contactPersonId: '',
        presentation: '',
        score: '',
        servingCount: '',
        deletedDate: '',
        dateCreated: '',
        lastUpdated: '',
    });

    useEffect(() => {
        if (id) {
            KitchenService.get(Number(id))
                .then(response => {
                    setKitchen(response.data);
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    }, [id]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setKitchen({...kitchen, [name]: value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            KitchenService.update(Number(id), kitchen)
                .then(response => {
                    navigate('/admin/kitchen');
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        } else {
            KitchenService.create(kitchen)
                .then(response => {
                    navigate('/admin/kitchen');
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };


    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/kitchen')
                }} className="nav-button">❮ Back
                </button>
            </div>
            <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit Kitchen' : 'Add Kitchen'}</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={kitchen.name}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Phone:</label>
                    <input
                        type="text"
                        name="phone"
                        value={kitchen.phone}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={kitchen.email}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Contact Person ID:</label>
                    <input
                        type="text"
                        name="contactPersonId"
                        value={kitchen.contactPersonId}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Presentation:</label>
                    <input
                        type="text"
                        name="presentation"
                        value={kitchen.presentation}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Score:</label>
                    <input
                        type="number"
                        name="score"
                        onWheel={(e) => e.target.blur()}
                        value={kitchen.score}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Serving Count:</label>
                    <input
                        type="number"
                        name="servingCount"
                        onWheel={(e) => e.target.blur()}
                        value={kitchen.servingCount}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default KitchenForm;