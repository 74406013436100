// src/services/KitchenService.js

import axios from 'axios';
import { API_URL } from '../../../constants';

const KITCHEN_API_URL = `${API_URL}/api/kitchen`;

class KitchenService {

    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }

    getAll() {
        return axios.get(KITCHEN_API_URL, { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${KITCHEN_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${KITCHEN_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${KITCHEN_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${KITCHEN_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }

    assignGroups(id, data) {
        return axios.post(`${KITCHEN_API_URL}/assignGroups/${id}`, data, { headers: this.getHeaders() });
    }

    listContractedGroups(id) {
        return axios.get(`${KITCHEN_API_URL}/listContractedGroups/${id}`, { headers: this.getHeaders() });
    }

    listMyGroups() {
        return axios.get(`${KITCHEN_API_URL}/listMyGroups`, { headers: this.getHeaders() });
    }

    removeGroups(id, data) {
        return axios.post(`${KITCHEN_API_URL}/removeGroups/${id}`, data, { headers: this.getHeaders() });
    }

    assignMenuCategories(id, data) {
        return axios.post(`${KITCHEN_API_URL}/assignMenuCategories/${id}`, data, { headers: this.getHeaders() });
    }

    removeMenuCategories(id, data) {
        return axios.post(`${KITCHEN_API_URL}/removeMenuCategories/${id}`, data, { headers: this.getHeaders() });
    }

    listMenuCategories(id) {
        return axios.get(`${KITCHEN_API_URL}/listMenuCategories/${id}`, { headers: this.getHeaders() });
    }

    assignMenuItems(id, data) {
        return axios.post(`${KITCHEN_API_URL}/assignMenuItems/${id}`, data, { headers: this.getHeaders() });
    }

    removeMenuItems(id, data) {
        return axios.post(`${KITCHEN_API_URL}/removeMenuItems/${id}`, data, { headers: this.getHeaders() });
    }

    listMenuItems(id) {
        return axios.get(`${KITCHEN_API_URL}/listMenuItems/${id}`, { headers: this.getHeaders() });
    }


    listMyMenuItems() {
        return axios.get(`${KITCHEN_API_URL}/listMyMenuItems`, { headers: this.getHeaders() });
    }


    addDeliveryBag(id, data) {
        return axios.post(`${KITCHEN_API_URL}/addDeliveryBag/${id}`, data, { headers: this.getHeaders() });
    }

    removeDeliveryBag(id, data) {
        return axios.post(`${KITCHEN_API_URL}/removeDeliveryBag/${id}`, data, { headers: this.getHeaders() });
    }

    listDeliveryBags(id) {
        return axios.get(`${KITCHEN_API_URL}/listDeliveryBags/${id}`, { headers: this.getHeaders() });
    }


}

export default new KitchenService();
