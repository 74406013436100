import React, {useState, useEffect} from 'react';
import KitchenService from './KitchenService';
import UserGroupService from '../UserGroup/UserGroupService';
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";

const token = localStorage.getItem("token");

const AssignContractedGroupForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [userGroups, setUserGroups] = useState([]);
    const [contractedGroups, setContractedGroups] = useState([]);
    const [contractedGroup, setContractedGroup] = useState({
        userGroupIds: []
    });

    useEffect(() => {
        KitchenService.listContractedGroups(Number(id))
            .then(response => {
                setContractedGroups(response.data);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });

        UserGroupService.getAll()
            .then(response => {
                setUserGroups(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [token]);

    const handleChange = (e) => {
        const options = e.target.options;
        const selectedValues = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        setContractedGroup({...contractedGroup, userGroupIds: selectedValues});
    };


    const handleRemove = (groupId) => {
        const confirmed = window.confirm("Are you sure you want to delete this kitchen?");
        if (confirmed) {
            KitchenService.removeGroups(Number(id), {userGroupIds: [groupId]})
                .then(response => {
                    KitchenService.listContractedGroups(Number(id))
                        .then(response => {
                            setContractedGroups(response.data);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Error occurred.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            KitchenService.assignGroups(Number(id), contractedGroup)
                .then(response => {
                    KitchenService.listContractedGroups(Number(id))
                        .then(response => {
                            setContractedGroups(response.data);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Error occurred.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const availableGroups = userGroups.filter(
        userGroup => !contractedGroups.some(contractedGroup => contractedGroup.id === userGroup.id)
    );

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/kitchen')
                }} className="nav-button">❮ Back
                </button>
            </div>
            <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit Kitchen' : 'Add Kitchen'}</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Selected User Groups:</label>
                    <ul className="list-disc list-inside mb-4">
                        {contractedGroups.map(userGroup => (
                            <li key={userGroup.id} className="flex justify-between items-center">
                                {userGroup.name}
                                <button
                                    type="button"
                                    onClick={() => handleRemove(userGroup.id)}
                                    className="text-red-600 ml-2"
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Select User Groups:</label>
                    <select
                        name="userGroupIds"
                        multiple
                        value={contractedGroup.userGroupIds}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        {availableGroups.map(userGroup => (
                            <option key={userGroup.id} value={userGroup.id}>
                                {userGroup.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default AssignContractedGroupForm;
