// src/components/MenuItemForm.js

import React, {useState, useEffect} from 'react';
import MenuItemService from './MenuItemService';
import {useParams, useNavigate} from 'react-router-dom';
import MenuCategoryService from "../MenuCategory/MenuCategoryService";
import {toast} from "react-toastify";

const MenuItemForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [menuItem, setMenuItem] = useState({
        name: '',
        description: '',
        price: '',
        parentMenuItem: '',
        userGroupId: '',
        displayOrder: '',
        occasional: false,
        lunch: false,
        dinner: false,
        breakfast: false,
        snack: false,
        timeless: false,
        mainCourse: false,
        selectedCategory: '',
        deletedDate: '',
        dateCreated: '',
        lastUpdated: '',
    });

    const [menuCategories, setMenuCategories] = useState([]);

    useEffect(() => {
        if (id) {
            MenuItemService.get(Number(id))
                .then(response => {
                    setMenuItem(response.data);
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }

        MenuCategoryService.getAll()
            .then(response => {
                setMenuCategories(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [id]);

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setMenuItem({...menuItem, [name]: type === 'checkbox' ? checked : value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            MenuItemService.update(Number(id), menuItem)
                .then(response => {
                    navigate('/admin/menuItem');
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        } else {
            MenuItemService.create(menuItem)
                .then(response => {
                    navigate('/admin/menuItem');
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/menuItem')
                }} className="nav-button">❮ Back
                </button>
            </div>
            <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit Menu Item' : 'Add Menu Item'}</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={menuItem.name}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Description:</label>
                    <input
                        type="text"
                        name="description"
                        value={menuItem.description}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Price:</label>
                    <input
                        type="number"
                        name="price"
                        onWheel={(e) => e.target.blur()}
                        value={menuItem.price}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">User Group ID:</label>
                    <input
                        type="text"
                        name="userGroupId"
                        value={menuItem.userGroupId}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Display Order:</label>
                    <input
                        type="number"
                        name="displayOrder"
                        onWheel={(e) => e.target.blur()}
                        value={menuItem.displayOrder}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Occasional:</label>
                    <input
                        type="checkbox"
                        name="occasional"
                        checked={menuItem.occasional}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Lunch:</label>
                    <input
                        type="checkbox"
                        name="lunch"
                        checked={menuItem.lunch}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Dinner:</label>
                    <input
                        type="checkbox"
                        name="dinner"
                        checked={menuItem.dinner}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Breakfast:</label>
                    <input
                        type="checkbox"
                        name="breakfast"
                        checked={menuItem.breakfast}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Snack:</label>
                    <input
                        type="checkbox"
                        name="snack"
                        checked={menuItem.snack}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Timeless:</label>
                    <input
                        type="checkbox"
                        name="timeless"
                        checked={menuItem.timeless}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Main Course:</label>
                    <input
                        type="checkbox"
                        name="mainCourse"
                        checked={menuItem.mainCourse}
                        onChange={handleChange}
                        className="mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Selected Category:</label>
                    <select
                        name="selectedCategory"
                        value={menuItem.selectedCategory.id}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        <option value="">Select a category</option>
                        {menuCategories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default MenuItemForm;