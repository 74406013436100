// src/components/InventoryForm.js

import React, { useState, useEffect } from 'react';
import InventoryService from '../../../services/InventoryService';
import { useParams, useNavigate } from 'react-router-dom';
import DateUtils from "../../../utils/DateUtils";
import { toast } from 'react-toastify';

const InventoryForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [inventory, setInventory] = useState({
        quantity: 0,
    });

    useEffect(() => {
        if (id) {
            InventoryService.get(Number(id))
                .then(response => {
                    setInventory({
                        ...response.data,
                        item: response.data.item
                    });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Failed to load inventory.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInventory({ ...inventory, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            InventoryService.update(Number(id), {quantity:inventory.quantity})
                .then((resp) => {
                    console.log(resp.data.date, "date")
                    const isoDateString = resp.data.date; // Example: '2024-06-24T18:15:00Z'
                    const dateObj = new Date(isoDateString);
                    navigate('/kitchen/inventory?date='+DateUtils.formatDate(dateObj));
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Failed to update inventory.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        } else {
            InventoryService.create(inventory)
                .then((data) => {
                    navigate('/kitchen/inventory?date='+data.date);
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Failed to create inventory.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm bg-white shadow-lg px-12 py-8 rounded-md">
                <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit Inventory' : 'Add Inventory'}</h2>
                <form onSubmit={handleSubmit} className="space-y-5">
                    <label className='font-semibold text-gray-900'>{inventory.item?inventory.item.name: 'N/A'}</label>
                    <div>
                        <label className="block text-sm font-medium text-gray-900">
                            Quantity <span className="text-red-600">*</span>
                        </label>
                        <div className="mt-2">
                            <input
                                type="number"
                                name="quantity"
                                onWheel={(e) => e.target.blur()}
                                value={inventory.quantity}
                                onChange={handleChange}
                                required
                                className="block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                            />
                        </div>
                    </div>
                    <div className="pt-4">
                        <button type="submit"
                            className="flex w-full justify-center rounded-lg bg-6 p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500">Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InventoryForm;