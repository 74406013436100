// App.js
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "flowbite-react";
import { Datepicker } from "flowbite-react";

import "./OrderHistory.css";

export default function OrderHistory() {
  const [startDate, setStartDate] = useState(null);
  const [employeeName, setEmployeeName] = useState("");

  const data = [
    {
      date: "Jan, 2024",
      name: "Ram Shrestha",
      total: 7000,
      subsidy: 5000,
      personalLiability: 2000,
    },
    {
      date: "Jan, 2024",
      name: "Hari Bhandari",
      total: 6000,
      subsidy: 5000,
      personalLiability: 1000,
    },
    {
      date: "Jan, 2024",
      name: "Rita Neupane",
      total: 5000,
      subsidy: 4000,
      personalLiability: 1000,
    },
    {
      date: "Jan, 2024",
      name: "Sita Shakya",
      total: 8000,
      subsidy: 3000,
      personalLiability: 5000,
    },
  ];

  const total = data.reduce(
    (acc, item) => ({
      total: acc.total + item.total,
      subsidy: acc.subsidy + item.subsidy,
      personalLiability: acc.personalLiability + item.personalLiability,
    }),
    { total: 0, subsidy: 0, personalLiability: 0 }
  );

  return (
    <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
      <div className="filter-container">
        <Datepicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText="Select Month"
          showMonthYearPicker
        />
        <input type="text" value={employeeName} 
            className="rounded-lg border-0 p-2.5 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
          onChange={(e) => setEmployeeName(e.target.value)} placeholder="Employee Name" />
        <button className="bg-lime-600 rounded-lg px-6 py-2.5 text-white text-sm hover:bg-lime-500 focus:ring-2 focus:ring-lime-500">Search</button>
      </div>
      <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>Date</Table.HeadCell>
          <Table.HeadCell>Employee Name</Table.HeadCell>
          <Table.HeadCell>Total</Table.HeadCell>
          <Table.HeadCell>Subsidy</Table.HeadCell>
          <Table.HeadCell>Personal Liability</Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {data.map((item, index) => (
            <Table.Row
              className="bg-white dark:border-gray-700 dark:bg-gray-800"
              key={index}>
              <Table.Cell>{item.date}</Table.Cell>
              <Table.Cell>{item.name}</Table.Cell>
              <Table.Cell>{item.total}</Table.Cell>
              <Table.Cell>{item.subsidy}</Table.Cell>
              <Table.Cell>{item.personalLiability}</Table.Cell>
            </Table.Row>
          ))}
          <Table.Row
            className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell colSpan="2">Total</Table.Cell>
            <Table.Cell>{total.total}</Table.Cell>
            <Table.Cell>{total.subsidy}</Table.Cell>
            <Table.Cell>{total.personalLiability}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      </div>
    </div>
  );
}
