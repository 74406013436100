"use client";

import React, { useState, useEffect } from 'react';
import {Navbar, Avatar, Dropdown} from "flowbite-react";
import {useLocation} from "react-router-dom";
import {Link, useNavigate} from "react-router-dom";
import {useUser} from "../../context/UserContext";
import axios from 'axios';
import {API_URL} from "../../constants";
import './Nav.css';

export function NavComponent({setShowLogin}) {

    const navigate = useNavigate();
    const location = useLocation();
    const {user, setUser, fetchUserDetails} = useUser();
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    const isLoginRoute =
        location.pathname === "/auth/login" || location.pathname === "/auth/signUp";

    const handleLogout = () => {
        localStorage.removeItem("token");
        setUser(null);
        navigate("/auth/login");
    };

    const userGroupSettings = () => {
        navigate("/settings");
    }

    const changePassword = () => {
        navigate("/auth/changePassword");
    }

    const switchUsergroup = async (id) => {
        const payload = {
            groupId: id
        }
        const headers = {
            'X-Auth-Token': localStorage.getItem("token")
        };
        try {
            const response = await axios.post(`${API_URL}/api/customerUser/switchSelectedGroup`, payload, {headers});
            if (response.data) {
                await fetchUserDetails();
            }
        } catch (err) {
            console.error('Error:', err);
        }
    }
    const login = (
        <Link
            to="/auth/login"
            className="bg-lime-600 login-btn text-white px-4 py-2 text-xs font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 hover:bg-lime-500 rounded-3xl transition-all">
            Login
        </Link>
    );

    const selectedUsergroup = (
        <div className="flex flex-col">
            <span>{user?.selectedUserGroup.displayName}</span>
            <small className="text-xs font-light text-right">by <span className="font-semibold"><span
                className="text-lime-600">Cloud</span> Canteen</span></small>
        </div>
    )

    const profileSettingHandler = () => {
        navigate('/auth/profileSetting')
    }

    return (
        <>
            <Navbar className={`bg-white mx-auto w-full cc-navbar py-5 bg-transparent z-50 transition-all duration-150 ${isSticky ? 'fixed bg-white z-50 shadow-md py-4' : '' }`}>
                <Link to="/">
                    <span className="self-center whitespace-nowrap text-xl font-semibold">
                      <span>{user ? selectedUsergroup : <span className='text-2xl cloud-canteen-logo'><span className="text-lime-600">Cloud</span> Canteen</span>}</span>
                    </span>
                </Link>
                <div className="flex items-center gap-2 md:order-2">
                    {localStorage.getItem("token") ? (
                        <Dropdown className="p-0 text-gray-200"
                                  label={<Avatar img="./../circle-user-regular.svg" size={"xs"} rounded/>}
                                  arrowIcon={false}
                                  inline>
                            <Dropdown.Header>
                                <span
                                    className="block text-sm font-semibold"
                                >
                                  {user?.firstName + " " + user?.lastName}
                                </span>
                            </Dropdown.Header>
                            <Dropdown.Item
                                className="py-0 px-1 group focus:bg-transparent hover:bg-transparent"
                                onClick={() => profileSettingHandler()}>
                                <span
                                    className="inline-block text-left py-1.5 px-3 w-full text-sm cursor-pointer hover:bg-transparent group-hover:bg-lime-100 group-hover:text-lime-700 rounded-lg transition-all"
                                >
                                  Account settings
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            {user?.groups.map((group, index) => {
                                return (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => switchUsergroup(group.id)}
                                        className={`py-0 px-1 group focus:bg-transparent hover:bg-transparent mt-1 mb-1`}
                                    >
                                        <span
                                            className={`inline-block text-left py-1.5 px-3 w-full text-sm cursor-pointer group-hover:bg-lime-100 group-hover:text-lime-700 rounded-lg transition-all ${
                                                user.selectedUserGroup.id === group.id ? 'bg-lime-100 text-lime-700' : ''
                                            }`}>
                                          {group.displayName}
                                        </span>
                                    </Dropdown.Item>
                                );
                            })}
                            <Dropdown.Divider/>
                            {user?.id === user?.selectedUserGroup?.groupOwner?.id ? (<><Navbar.Collapse>
                                <Dropdown.Item className="py-0 px-1 group focus:bg-transparent hover:bg-transparent"
                                               onClick={() => userGroupSettings()}>
                                    <span
                                        className="inline-block text-left py-1.5 px-3 w-full text-sm cursor-pointer hover:bg-transparent group-hover:bg-lime-100 group-hover:text-lime-700 rounded-lg transition-all">
                                      Group Settings
                                    </span>
                                </Dropdown.Item>
                            </Navbar.Collapse><Dropdown.Divider/></>) : ""}
                            <Dropdown.Item className="py-0 px-1 group focus:bg-transparent hover:bg-transparent" onClick={() => changePassword()}>
                                <span
                                    className="inline-block text-left py-1.5 px-3 w-full text-sm cursor-pointer hover:bg-transparent group-hover:bg-lime-100 group-hover:text-lime-700 rounded-lg transition-all">
                                    Password Change
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Divider/>
                            <Dropdown.Item className="py-0 px-1 group focus:bg-transparent hover:bg-transparent" onClick={handleLogout}>
                                <span
                                    className="inline-block text-left py-1.5 px-3 w-full text-sm cursor-pointer hover:bg-transparent group-hover:bg-lime-100 group-hover:text-lime-700 rounded-lg transition-all">
                                    Logout
                                </span>
                            </Dropdown.Item>
                        </Dropdown>
                    ) : (
                        !isLoginRoute && login
                    )}

                    <Navbar.Toggle/>
                </div>
                {user?.isKitchen ? (
                    <Navbar.Collapse>
                        <Link
                            to="/kitchen/inventory"
                            className={location.pathname === "/kitchen/inventory" ? "active text-lime-600" : ""}>
                            Menu Availability
                        </Link>
                        <Link
                            to="kitchen/kitchenOrderLists"
                            className={location.pathname === "/kitchen/kitchenOrderLists" ? "active text-lime-600" : ""}>
                            Orders
                        </Link>
                        <Link
                            to="/kitchen/kitchenCsr"
                            className={location.pathname === "/kitchen/kitchenCsr" ? "active text-lime-600" : ""}>
                            Packaging
                        </Link>
                        <Link
                            to="/kitchen/delivery"
                            className={location.pathname === "/kitchen/delivery" ? "active text-lime-600" : ""}>
                            Delivery
                        </Link>
                        <Link
                            to="/kitchen/reporting"
                            className={location.pathname === "/kitchen/reporting" ? "active text-lime-600" : ""}>
                            Reporting
                        </Link>
                        {/* <Link
            to="/kitchen/deliveryBagList"
            className={
              location.pathname === "/orderStatus" ? "text-lime-600" : ""
            }>
            Order status
          </Link> */}
                    </Navbar.Collapse>
                ) : user ? (
                    <Navbar.Collapse>
                        <Link
                            to="/placeOrder?slot=breakfast"
                            className={location.search === "?slot=breakfast" ? "active text-lime-600" : ""}>
                            Breakfast
                        </Link>
                        <Link
                            to="/placeOrder?slot=lunch"
                            className={location.search === "?slot=lunch" ? "active text-lime-600" : ""}>
                            Lunch
                        </Link>
                        <Link
                            to="/placeOrder?slot=snacks"
                            className={location.search === "?slot=snacks" ? "active text-lime-600" : ""}>
                            Snacks
                        </Link>
                        <Link
                            to="/placeOrder?slot=dinner"
                            className={location.search === "?slot=dinner" ? "active text-lime-600" : ""}>
                            Dinner
                        </Link>
                        <Link
                            to="/orderStatus"
                            className={
                                location.pathname === "/orderStatus" ? "active text-lime-600" : ""
                            }>
                            Order status
                        </Link>
                        {user.id === user.selectedUserGroup.groupOwner.id ? (<Navbar.Collapse>
                            <Link
                                to="/reportPerGroup"
                                className={
                                    location.pathname === "/reportPerGroup" ? "active text-lime-600" : ""
                                }>
                                Reporting
                            </Link>
                        </Navbar.Collapse>) : ""}
                    </Navbar.Collapse>
                ) : ''}
            </Navbar>
        </>
    );
}
