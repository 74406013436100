import React, {useEffect, useState} from 'react';
import MenuItemService from './MenuItemService';
import MenuCategoryService from '../MenuCategory/MenuCategoryService';
import {useNavigate} from 'react-router-dom';
import {Table} from 'flowbite-react';
import Pagination from '../../../components/Pagination/Pagination';
import {toast} from 'react-toastify';
import Select from 'react-select';
import { FaArrowLeft } from "react-icons/fa6";

const token = localStorage.getItem("token");

const MenuItemList = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [menuCategories, setMenuCategories] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items per page
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCategory, setSearchCategory] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchMenuItems();
        fetchMenuCategories();
    }, [token]);

    const fetchMenuItems = () => {
        MenuItemService.getAll()
            .then(response => {
                setMenuItems(response.data.list);
                setTotalCount(response.data.count);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    };

    const fetchMenuCategories = () => {
        MenuCategoryService.getAll()
            .then(response => {
                setMenuCategories(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    };

    const handleEdit = (id) => {
        navigate(`/admin/menuItem/edit/${id}`);
    };

    const handleDependencies = (id) => {
        navigate(`/admin/menuItem/assignDependencies/${id}`);
    }

    const handleDelete = (id) => {
        const confirmed = window.confirm('Are you sure you want to delete this menu item?');
        if (confirmed) {
            MenuItemService.delete(id)
                .then(response => {
                    setMenuItems(menuItems.filter(menuItem => menuItem.id !== id));
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleCreate = () => {
        navigate(`/admin/menuItem/add`);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchCategoryChange = (selectedOption) => {
        setSearchCategory(selectedOption);
    };

    const filterMenuItems = () => {
        return menuItems.filter(item => {
            const matchesCategory = searchCategory ? item.selectedCategory.id === searchCategory.value : true;
            const matchesName = searchTerm ? item.name.toLowerCase().includes(searchTerm.toLowerCase()) : true;
            return matchesCategory && matchesName;
        }).sort((a, b) => a.name.localeCompare(b.name));
    };

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterMenuItems().slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
            <div className="flex items-center justify-between mb-4">
                <div className="nav-buttons">
                    <button onClick={() => navigate('/admin')}
                        className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                        <FaArrowLeft />
                        <span>Back</span>
                    </button>
                    <button onClick={handleCreate}
                            className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Create New Group
                    </button>
                </div>
                <div className="flex space-x-2">
                    <input
                        type="text"
                        placeholder="Search by item name"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        className="block md:min-w-80 rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    />
                    <Select
                        placeholder="Search by category"
                        value={searchCategory}
                        onChange={handleSearchCategoryChange}
                        options={menuCategories.map(category => ({value: category.id, label: category.name}))}
                        className="react-select-container w-60"
                        classNamePrefix="react-select"
                    />
                    {/* <select 
                        onChange={handleSearchCategoryChange}
                        className="mt-1 block w-full rounded-lg border-0 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all">
                        { menuCategories.map((category, index) => (
                            <option key={index} value={category.id}>{category.name}</option>
                        ))}
                            </select> */}
                </div>
            </div>

            <div className="overflow-x-auto">
                <Table>
                    <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Category</Table.HeadCell>
                        <Table.HeadCell>Price</Table.HeadCell>
                        <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {currentItems.map(menuItem => (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={menuItem.id}>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {menuItem.name}
                                </Table.Cell>
                                <Table.Cell>{menuItem.selectedCategory ? menuItem.selectedCategory.name : 'N/A'}</Table.Cell>
                                <Table.Cell>{menuItem.price}</Table.Cell>
                                <Table.Cell>
                                    <button
                                        onClick={() => handleEdit(menuItem.id)}
                                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDependencies(menuItem.id)}
                                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500"
                                    >
                                        Handle Dependencies
                                    </button>
                                    <button
                                        onClick={() => handleDelete(menuItem.id)}
                                        className="bg-red-700 rounded-lg px-4 py-2 text-white text-sm hover:bg-red-600 font-semibold transition-all focus:ring-2 focus:ring-red-600"
                                    >
                                        Delete
                                    </button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalCount}
                paginate={paginate}
                currentPage={currentPage}
            />
        </div>
    );
};

export default MenuItemList;