import React, {useEffect, useState} from 'react';
import UserGroupService from './UserGroupService';
import {useNavigate} from 'react-router-dom';
import {Table} from "flowbite-react";
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";
import Spinner from '../../../components/Spinner/Spinner';

const token = localStorage.getItem("token");

const UserGroupList = () => {
    const [userGroups, setUserGroups] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        UserGroupService.getAll()
            .then(response => {
                setUserGroups(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
            })
            .finally(() => {
                setLoading(false);
            });
    }, [token]);

    const handleEdit = (id) => {
        navigate(`/admin/userGroup/edit/${id}`);
    };

    const handleUserManagement = (id) => {
        navigate(`/admin/userGroup/addUser/${id}`);
    };

    const handleMenuManagement = (id) => {
        navigate(`/admin/userGroup/addMenu/${id}`);
    };

    const handleOwnerManagement = (id) => {
        navigate(`/admin/userGroup/addOwner/${id}`);
    }

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this user group?");
        if (confirmed) {
            UserGroupService.delete(id)
                .then(response => {
                    setUserGroups(userGroups.filter(userGroup => userGroup.id !== id));
                })
                .catch(e => {
                    toast.error("Error occurred");
                    console.log(e);
                });
        }
    };

    const handleCreate = () => {
        navigate(`/admin/userGroup/add`);
    };

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredUserGroups = userGroups
        .filter(userGroup => userGroup.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="flex items-center justify-between mb-4">
                <div className="flex space-x-2">
                    <button onClick={() => navigate('/admin')}
                            className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-200 rounded-lg">
                        <FaArrowLeft />
                        <span>Back</span>
                    </button>
                    <button onClick={handleCreate}
                            className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Create New Group
                    </button>
                </div>
                <div className="flex space-x-2">
                    <input
                        type="text"
                        placeholder="Search by group name"
                        value={searchTerm}
                        onChange={handleSearchTermChange}
                        className="block md:min-w-80 rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    />
                </div>
            </div>
            {loading ? <Spinner /> :
            <div className="overflow-x-auto">
                <Table>
                    <Table.Head>
                        <Table.HeadCell>I.D</Table.HeadCell>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Lunch Time Start</Table.HeadCell>
                        <Table.HeadCell>Snacks Time Start</Table.HeadCell>
                        <Table.HeadCell>Dinner Time Start</Table.HeadCell>
                        <Table.HeadCell>Subsidy</Table.HeadCell>
                        <Table.HeadCell>Parent</Table.HeadCell>
                        <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {filteredUserGroups.map(userGroup => (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={userGroup.id}>
                                <Table.Cell>{userGroup.id}</Table.Cell>
                                <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                    {userGroup.displayName}
                                    {userGroup.parentId && ` (Parent ID: ${userGroup.parentId})`}
                                </Table.Cell>
                                <Table.Cell>{userGroup.lunchTimeStart}</Table.Cell>
                                <Table.Cell>{userGroup.snacksTimeStart}</Table.Cell>
                                <Table.Cell>{userGroup.dinnerTimeStart}</Table.Cell>
                                <Table.Cell>{userGroup.subsidy}</Table.Cell>
                                <Table.Cell>{userGroup.parent ? userGroup.parent.id : '-'}</Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => handleEdit(userGroup.id)}
                                            className="mr-2 mb-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Edit
                                    </button>

                                    <button onClick={() => handleUserManagement(userGroup.id)}
                                            className="mr-2 mb-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Users
                                    </button>

                                    <button onClick={() => handleMenuManagement(userGroup.id)}
                                            className="mr-2 mb-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Menus
                                    </button>

                                    <button onClick={() => handleOwnerManagement(userGroup.id)}
                                            className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Owner
                                    </button>

                                    <button onClick={() => handleDelete(userGroup.id)}
                                            className="bg-red-700 rounded-lg px-4 py-2 text-white text-sm hover:bg-red-600 font-semibold transition-all focus:ring-2 focus:ring-red-600">Delete
                                    </button>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            }
        </div>
    );
};

export default UserGroupList;