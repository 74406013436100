import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import CustomerUserService from "../../../services/CustomerUserService";
import {useUser} from "../../../context/UserContext";
import {toast} from 'react-toastify';
import Spinner from '../../../components/Spinner/Spinner';

export default function ProfileSetting() {
    const {user,fetchUserDetails} = useUser();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone1: '',
        phone2: '',
        company: '',
        dateOfBirth: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        console.log('useEffect');
        if (user) {
            setFormData({
                name: user.firstName + ' ' + user.lastName || '',
                email: user.email || '',
                phone1: user.phone1 || '',
                phone2: user.phone2 || '',
                company: user.company || '',
                dateOfBirth: user.dateOfBirth || ''
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        // Clear errors for a particular field when user starts typing
        if (errors[name]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: null
            }));
        }
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        let newErrors = {};
        // Validate required fields
        if (!formData.name.trim()) {
            newErrors.name = 'Username is required';
        }else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
            newErrors.name = "Name can only contain alphabets and spaces";
        }
        if (!formData.phone1.trim()) {
            newErrors.phone1 = 'Phone is required';
        }else if (!/^\d{10}$/.test(formData.phone1)) {
            newErrors.phone1 = "Phone number must be 10 digits";
        }
        if (!formData.dateOfBirth.trim()) {
            newErrors.dateOfBirth = 'Date of Birth is required';
        }
        // Set errors state or submit form if no errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setLoading(false);
        } else {
            try {
                const {name, email, phone1, phone2, company, dateOfBirth} = formData;  // Destructure to get only name and password
                const payload = {name, email, phone1, phone2, company, dateOfBirth};
                CustomerUserService.update(user.id, payload).then(() => {
                    toast.success('Your profile has been updated now.')
                    fetchUserDetails()
                }).catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
                // navigate('/');
            } catch (err) {

            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            {loading ? <Spinner/> :
                <div className="container mx-auto flex set-h-minus-nav flex-1 flex-col justify-center px-10 py-12 lg:px-10 animate-fadeIn">
                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md bg-white shadow-lg px-12 py-8 rounded-md">
                        <h2 className="mb-5 text-xl font-bold leading-9 tracking-tight text-gray-900">
                            Update an account
                        </h2>
                        <form className="space-y-5" onSubmit={handleSubmit}>
                            <div>
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium text-gray-900">
                                    Name <span className="text-red-600">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter name"
                                        className="block mt-1 w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                        style={{border: errors.username ? '1px solid red' : ''}}
                                    />
                                    {errors.name && <p style={{color: 'red'}}>{errors.name}</p>}
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="phone"
                                    className="block text-sm font-medium text-gray-900">
                                    Phone 1 <span className="text-red-600">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone"
                                        name="phone1"
                                        type="text"
                                        autoComplete="phone1"
                                        value={formData.phone1}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter phone number"
                                        className="block mt-1 w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                        style={{border: errors.phone1 ? '1px solid red' : ''}}
                                    />
                                    {errors.phone1 && <p style={{color: 'red'}}>{errors.phone1}</p>}
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="phone2"
                                    className="block text-sm font-medium text-gray-900">
                                    Phone 2
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone2"
                                        name="phone2"
                                        type="text"
                                        autoComplete="phone2"
                                        value={formData.phone2}
                                        onChange={handleChange}
                                        placeholder="Enter phone number"
                                        className="block mt-1 w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                    />
                                </div>
                            </div>
                            {/* <div>
                <label
                    htmlFor="company"
                    className="block text-sm font-medium leading-6 text-gray-900">
                  Company
                </label>
                <div className="mt-2">
                  <input
                      id="company"
                      name="company"
                      type="text"
                      autoComplete="company"
                      value={formData.company}
                      onChange={handleChange}
                      required
                      placeholder="Enter company name"
                      className="block w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                      style={{ border: errors.company ? '1px solid red' : '' }}
                  />
                  {errors.company && <p style={{ color: 'red' }}>{errors.company}</p>}
                </div>
              </div>*/}
                            <div>
                                <label
                                    htmlFor="dateOfBirth"
                                    className="block text-sm font-medium text-gray-900">
                                    Date Of Birth <span className="text-red-600">*</span>
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="dateOfBirth"
                                        name="dateOfBirth"
                                        type="date"
                                        autoComplete="dateOfBirth"
                                        value={formData.dateOfBirth}
                                        onChange={handleChange}
                                        required
                                        placeholder="Enter date of birth"
                                        className="block mt-1 w-full rounded-lg border-0 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                        style={{border: errors.dateOfBirth ? '1px solid red' : ''}}
                                    />
                                    {errors.dateOfBirth && <p style={{color: 'red'}}>{errors.dateOfBirth}</p>}
                                </div>
                            </div>
                            <div className="pt-4">
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-lg bg-6 p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">
                                    Update account
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </>
    );
}
