import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import UserGroupService from "../../Admin/UserGroup/UserGroupService";
import { toast } from 'react-toastify';
import { Table } from "flowbite-react";
import {FaTrashCan, FaPenToSquare, FaArrowLeft} from "react-icons/fa6";

const OrganizationUserListForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [addedUsers, setAddedUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        UserGroupService.listUsers(Number(id))
            .then(response => {
                setAddedUsers(response.data.users);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage);
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const handleEdit = (userId) => {
        navigate(`/settings/editUser/${userId}?groupId=${id}`);
    };

    const handleRemove = (userId) => {
        const confirmed = window.confirm("Are you sure you want to delete?");
        if (confirmed) {
            UserGroupService.removeUsers(Number(id), { userId: userId })
                .then(response => {
                    setAddedUsers(response.data.users);
                    toast.success('User removed from group');
                })
                .catch(e => {
                    toast.error('Cannot remove user');
                    console.log(e);
                });
        }
    };

    const addUserHandler = () => {
        navigate(`/settings/addUser/${id}`);
    };

    const addUserByEmail = () => {
        navigate(`/settings/addUserByEmail/${id}`);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredUsers = addedUsers.filter(user => {
        const firstName = user.firstName ? user.firstName.toLowerCase() : '';
        const lastName = user.lastName ? user.lastName.toLowerCase() : '';
        const email = user.email ? user.email.toLowerCase() : '';
        const employeeId = user.employeeId ? user.employeeId.toString() : '';

        const query = searchQuery.toLowerCase();

        return (
            firstName.includes(query) ||
            lastName.includes(query) ||
            email.includes(query) ||
            employeeId.includes(query)
        );
    });

    const sortedUsers = [...filteredUsers].sort((a, b) => {
        if (a.firstName < b.firstName) return -1;
        if (a.firstName > b.firstName) return 1;
        return 0;
    });

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <button onClick={() => navigate('/settings')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                <FaArrowLeft/>
                <span>Back</span>
            </button>
            <div className='flex justify-between items-center flex-wrap gap-2'>
                <h2 className="text-2xl font-semibold mb-6">Manage Users</h2>
                <div className="mb-4">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search by name, email or employeeId"
                        className="block md:min-w-96 rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                    />
                </div>
            </div>

            <div className="overflow-x-auto">
                <Table className="drop-shadow-none border-collapse">
                    <Table.Head>
                        <Table.HeadCell>Employee ID</Table.HeadCell>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Email</Table.HeadCell>
                        <Table.HeadCell>Phone</Table.HeadCell>
                        <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {sortedUsers.map(user => (
                            <Table.Row key={user.id}>
                                <Table.Cell>{user.employeeId}</Table.Cell>
                                <Table.Cell>{user.firstName} {user.lastName}</Table.Cell>
                                <Table.Cell>{user.email}</Table.Cell>
                                <Table.Cell>{user.phone1}</Table.Cell>
                                <Table.Cell>
                                    <div className="flex flex-wrap items-center gap-3">
                                        <FaPenToSquare
                                            onClick={() => handleEdit(user.id)}
                                            className="text-base cursor-pointer text-gray-700 hover:text-lime-600 transition-colors"
                                        />
                                        <FaTrashCan
                                            className='text-md text-gray-800 hover:text-lime-700 cursor-pointer transition-colors'
                                            onClick={() => handleRemove(user.id)}
                                        />
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <div className="mt-4">
                <button
                    onClick={addUserHandler}
                    type="button"
                    className="mr-2 rounded-lg px-5 py-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 transition-colors"
                >
                    Add New User
                </button>
                <button
                    onClick={addUserByEmail}
                    type="button"
                    className="rounded-lg px-5 py-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-600 transition-colors"
                >
                    Add Existing User by Email
                </button>
            </div>
        </div>
    );
};

export default OrganizationUserListForm;