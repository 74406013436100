import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import UserGroupService from "./UserGroupService";
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";

const AddOwnerForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [userGroup, setUserGroup] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({
        userId: 0
    });

    useEffect(() => {
        UserGroupService.get(Number(id)).then(response => {
            if(response.data.groupOwner.id){
                setSelectedUser({userId: response.data.groupOwner.id})
            }
            setUserGroup(response.data);
        }).catch(error => {
            console.log(error)
            const errorMessage = error.response?.data?.message || "Error occurred.";
            toast.error(errorMessage)
        });
        UserGroupService.listUsers(Number(id))
            .then(response => {
                setUsers(response.data.users);
            })
            .catch(e => {
                console.log(e)
                const errorMessage = e.response?.data?.message || "Failed to load group.";
                toast.error(errorMessage)
            });
    }, [id]);

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedUser({...selectedUser, userId: selectedValue});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            UserGroupService.updateOwner(Number(id), selectedUser)
                .then(response => {
                    UserGroupService.listUsers(Number(id))
                        .then(response => {
                            toast.success('Successfully updated')
                            setUsers(response.data.users);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Error occurred.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons mb-2">
                <button onClick={() => navigate('/admin/userGroup')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-200 rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
            </div>
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-lg">
                <div className='bg-white shadow-lg px-12 py-12 rounded-md'>
                <h2 className="text-2xl font-semibold mb-6">{userGroup.name?.toUpperCase()}</h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block pb-2 text-sm font-medium text-gray-700">Change Owner:</label>
                        <select
                            name="userId"
                            value={selectedUser.userId}
                            onChange={handleChange}
                            className="block w-full rounded-lg border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                        >
                            <option value={0}>Select Owner</option>
                            {users.map(user => (
                                <option key={user.id} value={user.id}>
                                    {user.firstName} {user.lastName} ({user.email})
                                </option>
                            ))}
                        </select>
                    </div>
                    <button type="submit" 
                        className="flex w-full justify-center rounded-lg p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">Save</button>
                </form>
                </div>
            </div>
        </div>
    );
};

export default AddOwnerForm;
