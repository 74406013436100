"use client";
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import {Table, Modal} from "flowbite-react";
import { API_URL } from '../../../constants';
import { toast } from 'react-toastify';
import Spinner from '../../../components/Spinner/Spinner';
import {useUser} from "../../../context/UserContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Order() {
  const {user} = useUser();
  const hidePricing = user?.selectedUserGroup?.hidePricing;

  let query = useQuery();
  let slot = query.get("slot");
  const [menuItems, setMenuItems] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [loading, setLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchText, setSearchText] = useState('');
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [totalCost, setTotalCost] = useState(0);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [isRiceSelected, setIsRiceSelected] = useState(false);
  const [isAddRiceCheckboxChecked, setIsAddRiceCheckboxChecked] = useState(false);
  const [isAddRotiCheckboxChecked, setIsAddRotiCheckboxChecked] = useState(false);
  const [riceQuantity, setRiceQuantity] = useState(1);
  const [isRotiSelected, setIsRotiSelected] = useState(false);
  const [rotiQuantity, setRotiQuantity] = useState(1);

  useEffect(() => {
    const fetchData = async (data) => {
      setLoading(true);
      try {
        const response = await axios.post(`${API_URL}/api/inventory/list`, data, {
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': `${token}`
          }
        });
        const fetchedMenuItems = response.data;
        const initialQuantities = {};
        fetchedMenuItems.forEach(item => {
          initialQuantities[item.id] = '';
        });
        setMenuItems(fetchedMenuItems);
        setQuantities(initialQuantities);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData({ slot: slot ? slot : 'LUNCH' });
  }, [token, slot]);

  const handleQuantityChange = (id, maxQuantity, value) => {
    const newQuantities = { ...quantities, [id]: Math.min(value, maxQuantity) };
    setQuantities(newQuantities);
  };

  useEffect(() => {
    const hasQuantities = Object.values(quantities).some(qty => qty > 0);
    setIsButtonDisabled(!hasQuantities);
  }, [quantities]);

  const handlePlaceOrder = async () => {
    const orders = menuItems
        .filter(item => quantities[item.id] > 0)
        .map(item => ({
          date: new Date().toISOString(),
          id: item.id,
          item: item.item,
          quantity: quantities[item.id],
        }));
    if (isAddRiceCheckboxChecked && riceQuantity>0) {
      const addRice = getAddRiceItem();
        orders.push({
          date: new Date().toISOString(),
          id: addRice.id,
          item: addRice.item,
          quantity: riceQuantity,
        });
      }
      if(isAddRotiCheckboxChecked && rotiQuantity>0){
        const addRoti = getAddRotiItem();
        orders.push({
          date: new Date().toISOString(),
          id: addRoti.id,
          item: addRoti.item,
          quantity: rotiQuantity,
        });
      }

    const payload = { orders, slot: slot ? slot.toUpperCase() : "LUNCH" };

    try {
        await axios.post(`${API_URL}/api/customerOrder/placeOrder`, payload, {
        headers: {
          'X-Auth-Token': `${token}`
        }
      });
      navigate('/orderStatus');
      toast.success("Order placed successfully.");
    } catch (error) {
      const errorMessage = error.response?.data || "Failed to place order.";
      toast.error(errorMessage);
      console.log('Err', error);
    }
  };

  const futureOrderHandler = () => {
    slot = slot ? slot : 'LUNCH';
    navigate('/orderCalendar?slot=' + slot);
  };

  const groupByCategory = (items) => {
    const groupedItems = items.reduce((acc, item) => {
      const category = item.item.selectedCategory;
      const categoryId = category.id;
      if (!acc[categoryId]) {
        acc[categoryId] = { category, items: [] };
      }
      acc[categoryId].items.push(item);
      return acc;
    }, {});

    const sortedCategories = Object.values(groupedItems).sort((a, b) => {
      if (a.category.displayOrder === null || a.category.displayOrder === undefined) return 1;
      if (b.category.displayOrder === null || b.category.displayOrder === undefined) return -1;
      return a.category.displayOrder - b.category.displayOrder;
    });

    return sortedCategories;
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRiceQuantity(1);  // Reset rice quantity
    setRotiQuantity(1);
  };

  const getAddRiceItem = () => {
    return menuItems.find(item => item.item.name.toLowerCase() === "add rice");
  };

  const getAddRotiItem = () => {
    return menuItems.find(item => item.item.name.toLowerCase() === "add roti");
  };

  const calculateTotalCost = () => {
    let total = menuItems.reduce((total, item) => {
      const quantity = quantities[item.id] || 0;
      return total + (item.item.price * quantity);
    }, 0);
    if (isAddRiceCheckboxChecked && riceQuantity>0) {
      const addRice = getAddRiceItem();
      total += riceQuantity * addRice.item.price;
    }
    if (isAddRotiCheckboxChecked && rotiQuantity>0) {
      const addRoti = getAddRotiItem();
      total += rotiQuantity * addRoti.item.price;
    }
    return total;
  };

  const handleConfirmOrder = () => {
    handlePlaceOrder();
    handleClose();
  };

  const filteredMenuItems = menuItems.filter(item => {
    const matchesCategory = selectedCategory ? item.item.selectedCategory.id === parseInt(selectedCategory) : true;
    const matchesText = item.item.name.toLowerCase().includes(searchText.toLowerCase());
    const isNotExcluded = !["Add Rice", "Add Roti"].includes(item.item.name);
    return matchesCategory && matchesText && isNotExcluded;
  });

  const groupedMenuItems = groupByCategory(filteredMenuItems);

  const categories = Array.from(
      new Map(menuItems.map(item => [item.item.selectedCategory.id, item.item.selectedCategory])).values()
  ).sort((a, b) => {
    if (a.displayOrder === null || a.displayOrder === undefined) return 1;
    if (b.displayOrder === null || b.displayOrder === undefined) return -1;
    return a.displayOrder - b.displayOrder;
  });

  const selectedItems = menuItems
      .filter(item => quantities[item.id] > 0)
      .map(item => ({
        name: item.item.name,
        quantity: quantities[item.id],
        price: item.item.price
      }));

  const totalCost = calculateTotalCost();
  // const subsidy = user?.selectedUserGroup?.subsidy || 0;
  // const personalLiability = totalCost - subsidy;

  useEffect(() => {
    const isRiceCategorySelected = menuItems.some(item => {
      const category = item.item.selectedCategory.name.toLowerCase();
      return category === 'rice set' && quantities[item.id] > 0;
    });
    setIsRiceSelected(isRiceCategorySelected);
  }, [quantities, menuItems]);

  useEffect(() => {
    const isRotiCategorySelected = menuItems.some(item => {
      const category = item.item.selectedCategory.name.toLowerCase();
      return category === 'roti set' && quantities[item.id] > 0;
    });
    setIsRotiSelected(isRotiCategorySelected);
  }, [quantities, menuItems]);


  return (
      <div>
        {loading ? <Spinner /> :
            <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
              <div className="overflow-x-auto p-0.5">
                <div className="flex justify-between items-center mb-6">
                  <button onClick={futureOrderHandler}
                          className='bg-lime-600 rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'>
                    Place Future Order
                  </button>
                </div>
                <div className='flex justify-between gap-2'>
                  <div className="mb-4 max-w-screen-sm flex gap-4 flex-1">
                    <div className="flex-1">
                      <label className="block text-sm font-medium">Category</label>
                      <select
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          // className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-lime-600 focus:ring-lime-600 sm:text-sm transition-all"
                          className="mt-1 block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                      >
                        <option value="">All Categories</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm font-medium">Search</label>
                      <input
                          type="text"
                          placeholder="Search by item name"
                          value={searchText}
                          onChange={handleSearchTextChange}
                          className="mt-1 block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                      />
                    </div>
                  </div>
                  <div className='mt-6'>
                    <button onClick={handleClickOpen}
                            disabled={isButtonDisabled}
                            className='bg-lime-600 rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'>
                      Place Order
                    </button>
                  </div>
                </div>
                <div className="overflow-x-auto">
                <Table className='drop-shadow-none border-collapse table-fixed'>
                  <Table.Head className='font-bold'>
                    <Table.HeadCell className="bg-zinc-100" style={{ width: '45%' }}>Item</Table.HeadCell>
                    <Table.HeadCell className="bg-zinc-100" style={{ width: '12%' }}>Order Cutoff Time</Table.HeadCell>
                    <Table.HeadCell className="bg-zinc-100" style={{ width: '11%' }}>Total Qty</Table.HeadCell>
                    <Table.HeadCell className="bg-zinc-100" style={{ width: '11%' }}>Avail Qty</Table.HeadCell>
                    <Table.HeadCell className="bg-zinc-100" style={{ width: '11%' }}>Quantity</Table.HeadCell>
                    <Table.HeadCell className="bg-zinc-100" style={{ width: '10%' }}>Price</Table.HeadCell>
                  </Table.Head>
                {groupedMenuItems.map(group => (
                        <Table.Body className="divide-y">
                          <Table.Row className="bg-gray-50">
                            <Table.Cell colSpan={6} className="text-gray-950 py-3 text-sm uppercase font-extrabold">{group.category.name}</Table.Cell>
                          </Table.Row>
                          {group.items.map(item => (
                              <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={item.id}>
                                <Table.Cell
                                    className="font-medium text-gray-900 dark:text-white">
                                  {item.item.name}
                                  {item.item.description? (<div>
                                      <span style={{
                                        fontSize: '0.75rem',  // Smaller font size
                                        fontStyle: 'italic',  // Italic style
                                        color: '#6b7280',     // Light gray color
                                        fontWeight: '300',    // Light font weight
                                        lineHeight: '1.5'     // Smooth line height for better readability
                                      }}>
                                        ({item.item.description})
                                      </span>
                                  </div>) : ''}
                                </Table.Cell>
                                <Table.Cell>{item.orderTime}</Table.Cell>
                                <Table.Cell>{item.quantity}</Table.Cell>
                                <Table.Cell>{item.availableQuantity - (quantities[item.id] || 0)}</Table.Cell>
                                <Table.Cell>
                                  <input
                                      type="number"
                                      value={quantities[item.id]}
                                      onWheel={(e) => e.target.blur()}
                                      onChange={(e) => handleQuantityChange(item.id, item.availableQuantity, parseInt(e.target.value))}
                                      className="rounded-lg w-full border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                  />
                                </Table.Cell>
                                {!hidePricing ? <Table.Cell>Rs. {parseInt(item.item.price)}</Table.Cell> : <Table.Cell>-</Table.Cell>}
                              </Table.Row>
                          ))}
                        </Table.Body>
                ))}
                </Table>
                </div>
              </div>
            </div>
        }
        <Modal show={open} size="lg" onClose={handleClose} popup>
          <Modal.Header className='px-6 py-4 border'>
            <p className="text-xl font-bold text-gray-900">Order Confirmation</p>
          </Modal.Header>
          <Modal.Body className='px-6 py-5'>
            <div>
              <div className="mb-4">
                <ul className="flex flex-col gap-4">
                  {selectedItems.map((item, index) => (
                      <li key={index} className="list-none flex justify-between">
                        <span>
                          <span className='font-bold text-gray-900'>{item.name}</span>
                          <br/>
                          <span className='text-sm font-semibold text-zinc-500'>Qty: {item.quantity}</span>
                        </span>
                        {!hidePricing &&
                        <span className='font-bold text-gray-900'>
                            Rs. {item.quantity * item.price}
                        </span>
                        }
                      </li>
                  ))}
                </ul>
              </div>
              {isRiceSelected && (
                  <div className="flex items-center mt-4">
                    <label className="flex font-semibold items-center cursor-pointer">
                      <input
                          type="checkbox"
                          checked={isAddRiceCheckboxChecked}
                          onChange={() => setIsAddRiceCheckboxChecked(!isAddRiceCheckboxChecked)}
                          className="mr-2 w-4 h-4 text-lime-600 bg-gray-100 focus:ring-lime-600 focus:ring-1 border-gray-300 rounded"
                      />
                      Add Rice
                    </label>
                    {isAddRiceCheckboxChecked? (
                        <input
                            type="number"
                            min="1"
                            value={riceQuantity}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value.length > 0 && value[0] === '0') {
                                value = value.slice(1);
                                e.target.value = value.slice(1);
                              }
                              console.log('e.target.value', e.target.value);
                              setRiceQuantity(Number(value));
                            }}
                            className="ml-4 block w-16 rounded-lg border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                        />
                    ) : ''}
                  </div>
              )}
              {isRotiSelected && (
                  <div className="flex items-center mt-4">
                    <label className="flex font-semibold items-center cursor-pointer">
                      <input
                          type="checkbox"
                          checked={isAddRotiCheckboxChecked}
                          onChange={() => setIsAddRotiCheckboxChecked(!isAddRotiCheckboxChecked)}
                          className="mr-2 w-4 h-4 text-lime-600 bg-gray-100 focus:ring-lime-600 focus:ring-1 border-gray-300 rounded"
                      />
                    </label>
                    Add Roti -{!hidePricing ? <span className="ml-2 text-gray-900">Rs. {getAddRotiItem().item.price} per piece</span> : ''}
                    {isAddRotiCheckboxChecked ? (
                        <input
                            type="number"
                            min="1"
                            value={rotiQuantity}
                            onChange={(e) => {
                              let value = e.target.value;
                              if (value.length > 0 && value[0] === '0') {
                                value = value.slice(1);
                                e.target.value = value.slice(1);
                              }
                              console.log('e.target.value', e.target.value);
                              setRotiQuantity(Number(value));
                            }}
                            className="ml-4 block w-16 rounded-lg border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                        />
                    ) : ''}
                  </div>
              )}
              <hr className='mt-4 mb-4'/>
              <p className='text-lg font-bold flex justify-between'>
                {!hidePricing && <span>Total Cost:</span>}
                {!hidePricing &&<span>Rs. {totalCost}</span>}
              </p>
              {/*<p className='text-lg font-semibold'>Office Subsidy: Rs. {subsidy}</p>*/}
              {/*<p className='text-lg font-semibold'>Personal Liability: Rs. {personalLiability}</p>*/}
              <div className=" mt-5 flex justify-end text-right gap-4">
                      <button className='px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg'
                    onClick={handleClose}>
                  Cancel
                </button>
                      <button className='bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'
                    onClick={handleConfirmOrder}>
                  Confirm Order
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
  );
}
