"use client";
import moment from "moment-timezone";
import { parse, differenceInHours } from "date-fns";
//Handle Timezone
let serverOffset = 0;
const offset = (date) => {
    let localOffset = date.getTimezoneOffset();
    return serverOffset - localOffset;
};
const toLocalDate = (date) => {
    let m = moment(date);
    m.subtract(offset(date), "minutes");
    return m.toDate();
};
const fromLocalDate = (date) => {
    let m = moment(date);
    m.add(offset(date), "minutes");
    return m.toDate();
};
export const toLocalTime = (time) => {
    if (time instanceof Date) {
        return toLocalDate(time);
    } else {
        return toLocalDate(new Date(time));
    }
};
export const fromLocalTime = (date) => {
    var newDate = new Date(date).toLocaleDateString("en-US");
    var date2 = new Date(newDate).getTime();
    return fromLocalDate(new Date(date2));
};
export const formatDate = (date, format) => {
    return moment(date).format(format);
};
export const formatTime = (date, resetZone) => {
    var m = moment(date);
    if (resetZone) {
        m.utc();
    }
    return m.format("HH.mm");
};
export const toLocalDateOnly = (date) => {
    let m = moment(date);
    m.subtract(offset(date), "minutes");
    return m.format("HH.mm");
};
export const toLocalTimeOnly = (time) => {
    if (time instanceof Date) {
        return toLocalDateOnly(time);
    } else {
        return toLocalDateOnly(new Date(time));
    }
};
export const hmToSecondsOnly = (str) => {
    try {
        if (str.includes("am")) {
            str = str.replace("12", "0");
            str = str.replace("am", "");
        }
        if (str.includes("pm")) {
            str = str.replace("pm", "");
            var a = str.split(":");
            str = parseInt(a[0]) + 12 + ":" + a[1];
        }
        const [hours, minutes] = str.split(":");
        return +hours * 3600 + +minutes * 60;
    } catch (error) {
        return false;
    }
};
export const hmToMillisecondsOnly = (str) => {
    try {
        if (str.includes("am")) {
            str = str.replace("12", "0");
            str = str.replace("am", "");
        }
        if (str.includes("pm")) {
            str = str.replace("pm", "");
            var a = str.split(":");
            str = parseInt(a[0]) + 12 + ":" + a[1];
        }
        const [hours, minutes] = str.split(":");
        const totalSeconds = +hours * 3600 + +minutes * 60;
        return totalSeconds * 1000;
    } catch (error) {
        return false;
    }
};
export const getDefaultFieldValueForTimePicker = (
    tFrom,
    tTo
) => {
    const langSelected = localStorage.getItem("eL_language");
    let timeFrom = "";
    let timeTo = "";
    let timeFromMinutes = "00";
    let timeToMinutes = "00";
    if (tFrom.toString().length === 4) {
        timeFromMinutes = tFrom.toString().substring(2, 4);
        tFrom = parseInt(tFrom.toString().substring(0, 2));
    }
    if (tTo.toString().length === 4) {
        timeToMinutes = tTo.toString().substring(2, 4);
        tTo = parseInt(tTo.toString().substring(0, 2));
    }
    if (langSelected === "en") {
        timeFrom = `${
            tFrom > 12
                ? ((tFrom - 12).toString().length < 2 ? "0" : "") +
                (tFrom - 12) +
                ":" +
                timeFromMinutes +
                " pm"
                : (tFrom.toString().length < 2 ? "0" : "") +
                tFrom +
                ":" +
                timeFromMinutes +
                " am"
        }`;
        timeTo = `${
            tTo > 12
                ? ((tTo - 12).toString().length < 2 ? "0" : "") +
                (tTo - 12) +
                ":" +
                timeToMinutes +
                " pm"
                : (tTo.toString().length < 2 ? "0" : "") +
                tTo +
                ":" +
                timeToMinutes +
                " am"
        }`;
    } else {
        timeFrom = `${tFrom}:${timeFromMinutes}`;
        timeTo = `${tTo}:${timeToMinutes}`;
    }
    return {
        timeFrom: timeFrom,
        timeTo: timeTo,
    };
};
export const formatDateWithMonth = (date) => {
    let newDate = new Date(toLocalTime(date));
    const month = newDate.toLocaleString("default", { month: "short" });
    return `${newDate.getDate()} ${month}`;
};
export const convertTime = (time) => {
    const langSelected = localStorage.getItem("eL_language");
    if (langSelected === "en") {
        if (time.includes("am") || time.includes("pm")) {
            return time;
        }
    } else {
        if (!time.includes("am") && !time.includes("pm")) {
            return time;
        }
    }
    if (time.includes("am") || time.includes("pm")) {
        const [timePart, modifier] = time.split(" ");
        let [hours, minutes] = timePart.split(":");
        if (hours === "12") {
            hours = "00";
        }
        if (modifier === "pm") {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    } else {
        const [hours, minutes] = time.split(":");
        const hoursIn12 = (hours % 12 || 12).toString();
        const ampm = parseInt(hours, 10) >= 12 ? "pm" : "am";
        return `${hoursIn12.padStart(2, "0")}:${minutes} ${ampm}`;
    }
};
export const convertTimeTo24 = (time) => {
    let timeStr = time.toString();
    if (timeStr.includes("am") || timeStr.includes("pm")) {
        const [timePart, modifier] = timeStr.split(" ");
        let [hours, minutes] = timePart.split(":");
        if (hours === "12") {
            hours = "00";
        }
        if (modifier === "pm") {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    } else {
        return timeStr;
    }
};
export const convertToEpoch = (date, time) => {
    if (date && time !== 0) {
        const dateTimeString = `${date}T${time}:00.000Z`;
        const dateTime = new Date(dateTimeString);
        return Math.floor(dateTime.getTime());
    }
    return 0;
};
export const getTimeInMillisecond = (str) => {
    try {
        if (str.includes("am")) {
            str = str.replace("12", "0");
            str = str.replace("am", "");
        }
        if (str.includes("pm")) {
            str = str.replace("pm", "");
            var a = str.split(":");
            str = parseInt(a[0]) + 12 + ":" + a[1];
        }
        const [hours, minutes] = str.split(":");
        // Calculate milliseconds since the start of the day
        let millisecondsSinceStartOfDay =
            hours * 60 * 60 * 1000 + minutes * 60 * 1000;
        return millisecondsSinceStartOfDay;
    } catch (error) {
        return false;
    }
};
const parseDate = (dateString) => {
    let date;
    try {
        // Try parsing 24-hour format
        date = parse(dateString, "yyyy-MM-dd HH:mm", new Date());
    } catch (e) {
        try {
            // Try parsing 12-hour format
            date = parse(dateString, "yyyy-MM-dd hh:mm a", new Date());
        } catch (e) {
            throw new Error("Invalid date format");
        }
    }
    return date;
};
export const calculateTimeDifferenceInHours = (
    startDateStr,
    endDateStr
) => {
    const startDate = parseDate(startDateStr);
    const endDate = parseDate(endDateStr);
    const differenceInHrs = differenceInHours(endDate, startDate);
    return differenceInHrs;
};
export const processTimeForApp = ({
                                      startDate,
                                      endDate,
                                      startTime,
                                      endTime,
                                  }) => {
    const baseDate = fromLocalTime(startDate).getTime(); // 1653523200000  -  May 26, 2022
    const normalizeTime = (time) => {
        if (time?.includes("12:00 am")) return "00:00";
        if (time?.includes("12:00 pm")) return "12:00";
        return time;
    };
    const timeFromApi = normalizeTime(startTime);
    const timeToApi = normalizeTime(endTime);
    const fromDateTime = timeFromApi
        ? hmToSecondsOnly(timeFromApi)
        : 43200000;
    const toDateTime = timeToApi ? hmToSecondsOnly(timeToApi) : 3600000;
    const datePickerFromDate = fromLocalTime(
        new Date(startDate).toISOString()
    ).getTime();
    const datePickerToDate = fromLocalTime(
        new Date(endDate ? endDate : startDate).toISOString()
    ).getTime();
    const baseDateOffset = baseDate;
    return {
        fromDateTime: datePickerFromDate - baseDateOffset + fromDateTime,
        toDateTime: datePickerToDate - baseDateOffset + toDateTime,
        eventFromDate: fromLocalTime(startDate).getTime(),
    };
};