
"use client";

import { Footer } from "flowbite-react";
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from "react-icons/bs";
import './Footer.css';

export function FooterComponent() {
  return (
    <Footer container className="bg-transparent border shadow-none rounded-none" id="footer-section">
      <div className="w-full">
        <div className="max-w-screen-xl mx-auto text-center">
            {/* <div>
                <span className="whitespace-nowrap text-xl font-semibold dark:text-white">
                    <span className="text-lime-600">Cloud</span> Canteen
                </span> 
            </div> */}
          <Footer.Copyright by="Cloud Canteen. All rights reserved." year={new Date().getFullYear()} />
          {/* <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="#" icon={BsFacebook} />
            <Footer.Icon href="#" icon={BsInstagram} />
            <Footer.Icon href="#" icon={BsTwitter} />
          </div> */}
        </div>
      </div>
    </Footer>
  );
}
