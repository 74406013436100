import React, { useState, useEffect } from 'react';
import UserGroupService from '../../Admin/UserGroup/UserGroupService'; // Assuming you have a service file for API calls
import { useParams, useNavigate } from 'react-router-dom';
import {useUser} from "../../../context/UserContext";
import {FaArrowLeft} from "react-icons/fa6";

const OrganizationAddChildUserGroupForm = () => {
    const { id } = useParams(); // Get the 'id' parameter from the URL
    const navigate = useNavigate(); // Hook for navigation


    // State to hold form data and manage changes
    const [userGroup, setUserGroup] = useState({
        name: '',
        description: '',
        subsidy: ''
    });

    // Handle changes in form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserGroup({ ...userGroup, [name]: value }); // Update the corresponding state field
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        if (id) {
            UserGroupService.setUpChildGroup(id, userGroup)
                .then(response => {
                    navigate('/settings'); // Navigate to the user group list after successful creation
                })
                .catch(error => {
                    console.log('Error creating user group:', error);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <button onClick={() => navigate('/settings')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                <FaArrowLeft/>
                <span>Back</span>
            </button>
            <div className="mt-10 mb-10 sm:mx-auto sm:w-full sm:max-w-md bg-white shadow-lg px-12 py-8 rounded-md">
                <h2 className="text-2xl font-semibold mb-6">Add Child Group</h2>
                <form onSubmit={handleSubmit} className="space-y-5">
                    <div>
                        <label className="block text-sm font-medium text-gray-800">
                            Name <span className="text-red-600">*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            value={userGroup.name}
                            onChange={handleChange}
                            placeholder="Enter name"
                            required
                            className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-900">Description</label>
                        <input
                            type="text"
                            name="description"
                            value={userGroup.description}
                            placeholder="Enter description"
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-900">Subsidy</label>
                        <input
                            type="text"
                            name="subsidy"
                            value={userGroup.subsidy}
                            placeholder="Enter subsidy"
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-lg border-0 p-2 text-gray-900 bg-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    <div className='pt-4'>
                        <button type="submit"
                                className="flex w-full justify-center rounded-lg bg-6 p-2 text-sm font-semibold leading-6 bg-lime-600 text-white shadow-sm hover:bg-lime-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lime-500 transition-all">
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OrganizationAddChildUserGroupForm;