// DeliveryBagForm.jsx

import React, { useState, useEffect } from 'react';
import DeliveryBagService from '../../../services/DeliveryBagService'; // Adjust import as per your setup
import { useParams, useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
const token = localStorage.getItem("token");

const DeliveryBagForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deliveryBag, setDeliveryBag] = useState({
        name: '',
        bagNumber: '',
        capacity: '',
        deliveryBoyEmail: '',
        deliveredDate: false,
        deletedDate: null,
        dateCreated: null,
        lastUpdated: null
    });

    useEffect(() => {
        if (id) {
            DeliveryBagService.get(id)
                .then(response => {
                    setDeliveryBag(response.data);
                })
                .catch(error => {
                    toast.error("Error occurred")
                    console.error('Error fetching delivery bag:', error);
                });
        }
    }, [id, token]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDeliveryBag({ ...deliveryBag, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            DeliveryBagService.update(id, deliveryBag)
                .then(response => {
                    navigate('/admin/deliveryBag'); // Navigate to list page after update
                })
                .catch(error => {
                    toast.error("Error occurred")
                    console.error('Error updating delivery bag:', error);
                });
        } else {
            DeliveryBagService.create(deliveryBag)
                .then(response => {
                    navigate('/admin/deliveryBag'); // Navigate to list page after create
                })
                .catch(error => {
                    toast.error("Error occurred")
                    console.error('Error creating delivery bag:', error);
                });
        }
    };

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <h2 className="text-2xl font-semibold mb-6">{id ? 'Edit Delivery Bag' : 'Add Delivery Bag'}</h2>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/deliveryBag')
                }} className="nav-button">❮ Back </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={deliveryBag.name}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Bag Number:</label>
                    <input
                        type="text"
                        name="bagNumber"
                        value={deliveryBag.bagNumber}
                        onChange={handleChange}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Capacity:</label>
                    <input
                        type="number"
                        name="capacity"
                        value={deliveryBag.capacity}
                        onChange={handleChange}
                        onWheel={(e) => e.target.blur()}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>

               {/* <div>
                    <label className="block text-sm font-medium text-gray-700">Delivery boy:</label>
                    <input
                        type="email"
                        name="deliveryBoyEmail"
                        value={deliveryBag.deliveryBoyEmail}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>*/}

                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default DeliveryBagForm;
