import React, {useState, useEffect} from 'react';
import DeliveryBagService from '../../../services/DeliveryBagService'; // Adjust import as per your setup
import KitchenService from './KitchenService'; // Adjust import as per your setup
import {useParams, useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";

const token = localStorage.getItem("token");

const AssignDeliveryBagForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [deliveryBags, setDeliveryBags] = useState([]);
    const [assignedBags, setAssignedBags] = useState([]);
    const [selectedBag, setSelectedBag] = useState({
        bagId: 0
    });

    useEffect(() => {
        KitchenService.listDeliveryBags(Number(id))
            .then(response => {
                setAssignedBags(response.data);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });

        DeliveryBagService.getAll()
            .then(response => {
                setDeliveryBags(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [token]);

    const handleChange = (e) => {
        const selectedValue = e.target.value;
        setSelectedBag({...selectedBag, bagId: selectedValue});
    };

    const handleRemove = (bagId) => {
        const confirmed = window.confirm("Are you sure you want to remove this delivery bag?");
        if (confirmed) {
            KitchenService.removeDeliveryBag(Number(id), {bagId: bagId})
                .then(response => {
                    KitchenService.listDeliveryBags(Number(id))
                        .then(response => {
                            setAssignedBags(response.data);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Error occurred.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (id) {
            KitchenService.addDeliveryBag(Number(id), selectedBag)
                .then(response => {
                    KitchenService.listDeliveryBags(Number(id))
                        .then(response => {
                            setAssignedBags(response.data);
                        })
                        .catch(e => {
                            const errorMessage = e.response?.data?.message || "Error occurred.";
                            toast.error(errorMessage)
                            console.log(e);
                        });
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const availableBags = deliveryBags.filter(
        bag => !assignedBags.some(assignedBag => assignedBag.id === bag.id)
    );

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons">
                <button onClick={() => {
                    navigate('/admin/kitchen')
                }} className="nav-button">❮ Back
                </button>
            </div>
            <h2 className="text-2xl font-semibold mb-6">Manage Delivery Bag</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Assigned Delivery Bags:</label>
                    <ul className="list-disc list-inside mb-4">
                        {assignedBags.map(bag => (
                            <li key={bag.id} className="flex justify-between items-center">
                                {bag.name}
                                <button
                                    type="button"
                                    onClick={() => handleRemove(bag.id)}
                                    className="text-red-600 ml-2"
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Select Delivery Bags:</label>
                    <select
                        name="bagId"
                        value={selectedBag.bagId}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        <option>Select Bag</option>
                        {availableBags.map(bag => (
                            <option key={bag.id} value={bag.id}>
                                #{bag.number} - {bag.name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded-md">Save</button>
            </form>
        </div>
    );
};

export default AssignDeliveryBagForm;
