import React, { useEffect, useState } from "react";
import axios from "axios";
import {Table, Button, Modal} from "flowbite-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API_URL } from "../../../constants";
import UserGroupService from "../../Admin/UserGroup/UserGroupService";
import { format, subDays, startOfMonth } from "date-fns";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import { FaFilePdf } from 'react-icons/fa';
import html2canvas from "html2canvas";
import ProcessStatus from "../../../utils/enums";
import {HiOutlineExclamationCircle} from "react-icons/hi";

export default function ReportPerGroup() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [childList, setChildList] = useState([]);
  const [totals, setTotals] = useState({
    total: 0,
    subsidy: 0,
    personalLiability: 0,
  });

  const processStatuses = ProcessStatus.getAll();

  const [fromDate, setFromDate] = useState(subDays(new Date(), 30));
  const [toDate, setToDate] = useState(new Date());
  const [selectedChild, setSelectedChild] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(processStatuses[0]);
  const token = localStorage.getItem("token");

  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => {
    UserGroupService.listChildren()
        .then((response) => {
          setChildList(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
  }, []);


  const fetchData = async () => {
    try {
      const formattedFromDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
      const formattedToDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
      const response = await axios.post(
          `${API_URL}/api/customerOrderCart/listByGroup`,
          { fromDate: formattedFromDate, toDate: formattedToDate, groupId: selectedChild, status: selectedStatus },
          {
            headers: {
              "X-Auth-Token": `${token}`,
            },
          }
      );

      const sortedData = response.data.list.sort((a, b) =>
          a.user.name.localeCompare(b.user.name)
      );

      setData(sortedData);
      calculateTotals(sortedData);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Error fetching data.";
      toast.error(errorMessage);
      console.error("Error fetching data:", error);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const goToReportPerUser = (id) => {
    const formattedFromDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
    const formattedToDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
    navigate(`/reportPerUser/${id}?fromDate=${formattedFromDate}&toDate=${formattedToDate}&status=${selectedStatus}`);
  };

  const goToAdjustOrder = (id) => {
    const formattedToDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
    navigate(`/adjustOrder`);
  };

  const calculateTotals = (list) => {
    const totalAmounts = list.reduce((acc, item) => acc + item.total, 0);
    const totalSubsidy = list.reduce((acc, item) => acc + item.subsidy, 0);
    const totalLiability = list.reduce(
        (acc, item) => acc + (item.personalLiability || 0),
        0
    );

    setTotals({
      total: totalAmounts,
      subsidy: totalSubsidy,
      personalLiability: totalLiability,
    });
  };

  const printTableAsPDF = () => {
    const input = document.getElementById("tableToPrint");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("report-per-group.pdf");
    });
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
      <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
        <div className="flex space-x-4 mb-4">
          <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select From Date"
              className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
          />

          <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select To Date"
              className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
          />

          <select
              value={selectedChild}
              onChange={(e) => setSelectedChild(e.target.value)}
              className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
              placeholder="Select Child Group"
          >
            <option value="">All Groups</option>
            {childList.map((child) => (
                <option key={child.id} value={child.id}>
                  {child.name}
                </option>
            ))}
          </select>


          <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
              placeholder="Select Status"
          >
            {processStatuses.map((status) =>(
                <option key={status} value={status}>
                  {status}
                </option>
            ))}
          </select>

          <Button onClick={fetchData} className="bg-lime-600 hover:bg-lime-700 transition-all">
            Search
          </Button>

          <Button onClick={goToAdjustOrder} className="bg-lime-600 hover:bg-lime-700 transition-all">
            Manage Orders
          </Button>

          <Button
              onClick={printTableAsPDF}
              className="bg-blue-600 hover:bg-blue-700 transition-all flex items-center gap-2 relative group"
          >
            <FaFilePdf className="text-white"/>
            <span className="hidden group-hover:inline text-white">Print PDF</span>
          </Button>
        </div>

        <div className="overflow-x-auto p-0.5" id="tableToPrint">
          <Table className="drop-shadow-none">
            <Table.Head>
              <Table.HeadCell>Employee</Table.HeadCell>
              <Table.HeadCell>Group name</Table.HeadCell>
              <Table.HeadCell>Subsidy</Table.HeadCell>
              <Table.HeadCell>Personal Liability</Table.HeadCell>
              <Table.HeadCell>Total</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {data.map((item, index) => (
                  <Table.Row
                      className="bg-white dark:border-gray-700 dark:bg-gray-800"
                      key={index}
                  >
                    <Table.Cell>
                  <span
                      className="text-lime-600 cursor-pointer hover:text-lime-700 hover:underline transition-all"
                      onClick={() => {
                        goToReportPerUser(item.user.id);
                      }}
                  >
                    {item.user.name}
                  </span>
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      {item.userGroup.name}
                    </Table.Cell>

                    <Table.Cell>{item.subsidy}</Table.Cell>
                    <Table.Cell>{item.personalLiability || "-"}</Table.Cell>
                    <Table.Cell>{item.total}</Table.Cell>
                  </Table.Row>
              ))}
            </Table.Body>
            <Table.Body>
              <Table.Row className="bg-gray-100 font-semibold border-0 rounded-none">
                <Table.Cell>Total</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell>{totals.subsidy}</Table.Cell>
                <Table.Cell>{totals.personalLiability}</Table.Cell>
                <Table.Cell>{totals.total}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {/*<Modal show={modalIsOpen} size="lg" onClose={() => setModalIsOpen(false)} popup>*/}
        {/*  <Modal.Header/>*/}
        {/*  <Modal.Body>*/}
        {/*    <div className="text-center">*/}
        {/*      <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">*/}
        {/*        Are you sure you want to manage to subsidy?*/}
        {/*      </h3>*/}
        {/*      <div className="flex justify-center gap-4">*/}
        {/*        <Button className="bg-lime-600 hover:bg-lime-700" onClick={goToAdjustOrder}>*/}
        {/*          {"Yes, I'm sure"}*/}
        {/*        </Button>*/}
        {/*        <button*/}
        {/*            className="px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100 rounded-lg"*/}
        {/*            onClick={() => setModalIsOpen(false)}>*/}
        {/*          No, cancel*/}
        {/*        </button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Modal.Body>*/}
        {/*</Modal>*/}
      </div>

  );
}