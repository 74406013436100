// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cc-hero-section {
    height: calc(100vh - 154px);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 2s;
}
.cc-hero-section h1 {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.cc-hero-section .order-now-btn {
    background: linear-gradient(135deg, #a3e635, #65a30d);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    padding: 15px 30px;
    transition: all 0.2s ease-in-out;
}
.cc-hero-section .order-now-btn:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}`, "",{"version":3,"sources":["webpack://./src/components/HeroSection/Hero.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,2CAA2C;AAC/C;AACA;IACI,qDAAqD;IACrD,0CAA0C;IAC1C,mBAAmB;IACnB,kBAAkB;IAClB,gCAAgC;AACpC;AACA;IACI,sBAAsB;IACtB,4CAA4C;AAChD","sourcesContent":[".cc-hero-section {\n    height: calc(100vh - 154px);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    animation: fadeIn 2s;\n}\n.cc-hero-section h1 {\n    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);\n}\n.cc-hero-section .order-now-btn {\n    background: linear-gradient(135deg, #a3e635, #65a30d);\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    border-radius: 50px;\n    padding: 15px 30px;\n    transition: all 0.2s ease-in-out;\n}\n.cc-hero-section .order-now-btn:hover {\n    transform: scale(1.05);\n    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
