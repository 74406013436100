import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {
    addDays,
    eachDayOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    startOfMonth,
    startOfWeek,
    subDays,
    isSameMonth,
    isSameDay,
    isSaturday,
    isSunday,
    isToday,
    isFuture,
} from 'date-fns';
import './Calendar.css';
import { Table, Modal } from 'flowbite-react';
import InventoryService from '../../../services/InventoryService';
import axios from "axios";
import { API_URL } from "../../../constants";
import DateUtils from "../../../utils/DateUtils";
import { toast } from "react-toastify";
import Spinner from "../../../components/Spinner/Spinner";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Calendar = () => {
    let query = useQuery();
    let slot = query.get("slot");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [view, setView] = useState('weekly');
    const [menuItems, setMenuItems] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [loading, setLoading] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const [isRiceSelected, setIsRiceSelected] = useState(false);
    const [isAddRiceCheckboxChecked, setIsAddRiceCheckboxChecked] = useState(false);
    const [isAddRotiCheckboxChecked, setIsAddRotiCheckboxChecked] = useState(false);
    const [riceQuantity, setRiceQuantity] = useState(1);
    const [isRotiSelected, setIsRotiSelected] = useState(false);
    const [rotiQuantity, setRotiQuantity] = useState(1);

    const openModal = (date) => {
        setSelectedDate(date);
        setIsModalOpen(true);
        fetchInventory(date);
    };

    const handleQuantityChange = (id, maxQuantity, value) => {
        const newQuantities = { ...quantities, [id]: Math.min(value, maxQuantity) };
        setQuantities(newQuantities);
    };
    useEffect(() => {
        const hasQuantities = Object.values(quantities).some(qty => qty > 0);
        setIsButtonDisabled(!hasQuantities);
    }, [quantities]);

    const fetchInventory = (date) => {
        setLoading(true);
        InventoryService.listForCustomer({ date: DateUtils.formatDate(date), slot: slot })
            .then((response) => {
                setMenuItems(response.data);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                toast.error("Inventory list failed to load.");
            });
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedDate(null);
        setRiceQuantity(1);  // Reset rice quantity
        setRotiQuantity(1);
    };

    const renderDaysOfWeek = () => {
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return daysOfWeek.map((day) => (
            <div key={day} className="day-header">
                {day}
            </div>
        ));
    };

    const renderMonthlyView = () => {
        const start = startOfMonth(currentDate);
        const end = endOfMonth(currentDate);
        const startDate = startOfWeek(start);
        const endDate = endOfWeek(end);
        const days = eachDayOfInterval({ start: startDate, end: endDate });
        const today = new Date(); // Get today's date

        return days.map((day) => {
            const isPastDate = day < today.setHours(0, 0, 0, 0); // Compare dates

            return (
                <div
                    key={day}
                    className={`day ${isSameMonth(day, currentDate) ? '' : 'disabled'} 
                          ${isSameDay(day, selectedDate) ? 'selected' : ''}
                          ${isSaturday(day) || isSunday(day) ? 'holiday' : ''} 
                          ${isToday(day) ? 'today' : ''}`}
                    onClick={() => {
                        if (!isPastDate) {
                            openModal(day);
                        }
                    }}
                    style={{
                        cursor: isPastDate ? 'not-allowed' : 'pointer',
                        pointerEvents: isPastDate ? 'none' : 'auto',
                    }}
                >
                    {format(day, 'd')}
                </div>
            );
        });
    };

    const renderWeeklyView = () => {
        const start = startOfWeek(currentDate);
        const end = endOfWeek(currentDate);
        const days = eachDayOfInterval({ start, end });
        const today = new Date(); // Get today's date

        return days.map((day) => {
            const isPastDate = day < today.setHours(0, 0, 0, 0); // Compare dates

            return (
                <div
                    key={day}
                    className={`day ${isSameDay(day, selectedDate) ? 'selected' : ''} 
                          ${isSaturday(day) || isSunday(day) ? 'holiday' : ''} 
                          ${isToday(day) ? 'today' : ''}`}
                    onClick={() => {
                        if (!isPastDate) {
                            openModal(day);
                        }
                    }}
                    style={{
                        cursor: isPastDate ? 'not-allowed' : 'pointer',
                        pointerEvents: isPastDate ? 'none' : 'auto',
                    }}
                >
                    {format(day, 'd')}
                </div>
            );
        });
    };

    const changeView = (newView) => {
        setView(newView);
    };

    const goToPrevious = () => {
        if (view === 'monthly') {
            setCurrentDate(subDays(currentDate, 30));
        } else {
            setCurrentDate(subDays(currentDate, 7));
        }
    };

    const goToNext = () => {
        if (view === 'monthly') {
            setCurrentDate(addDays(currentDate, 30));
        } else {
            setCurrentDate(addDays(currentDate, 7));
        }
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleSearchTextChange = (e) => {
        setSearchText(e.target.value);
    };

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getAddRiceItem = () => {
        return menuItems.find(item => item.item.name.toLowerCase() === "add rice");
    };

    const getAddRotiItem = () => {
        return menuItems.find(item => item.item.name.toLowerCase() === "add roti");
    };

    const calculateTotalCost = () => {
        let total = menuItems.reduce((total, item) => {
            const quantity = quantities[item.id] || 0;
            return total + (item.item.price * quantity);
        }, 0);
        if (isAddRiceCheckboxChecked && riceQuantity>0) {
            const addRice = getAddRiceItem();
            total += riceQuantity * addRice.item.price;
        }
        if (isAddRotiCheckboxChecked && rotiQuantity>0) {
            const addRoti = getAddRotiItem();
            total += rotiQuantity * addRoti.item.price;
        }
        return total;
    };

    const handleConfirmOrder = () => {
        handlePlaceOrder();
    };

    const handlePlaceOrder = async () => {
        const orders = menuItems
            .filter(item => quantities[item.id] > 0)
            .map(item => ({
                date: DateUtils.formatDate(new Date()),
                id: item.id,
                item: item.item,
                quantity: quantities[item.id],
                slot: "LUNCH" // Assuming slot is "LUNCH", change as needed
            }));
        if (isAddRiceCheckboxChecked && riceQuantity>0) {
            const addRice = getAddRiceItem();
            orders.push({
                date: DateUtils.formatDate(new Date()),
                id: addRice.id,
                item: addRice.item,
                quantity: riceQuantity,
                slot: "LUNCH"
            });
        }
        if (isAddRotiCheckboxChecked && rotiQuantity>0) {
            const addRoti = getAddRotiItem();
            orders.push({
                date: DateUtils.formatDate(new Date()),
                id: addRoti.id,
                item: addRoti.item,
                quantity: rotiQuantity,
                slot: "LUNCH"
            });
        }

        const payload = { orders, slot: slot.toUpperCase(), date: DateUtils.formatDate(selectedDate) };

        try {
            const token = localStorage.getItem('token'); // Get token from local storage
            await axios.post(`${API_URL}/api/customerOrder/placeOrder`, payload, {
                headers: {
                    'X-Auth-Token': `${token}`
                }
            });
            toast.success(`Order placed successfully for ${DateUtils.formatDate(selectedDate)}`);
            handleClose();
        } catch (error) {
            const errorMessage = error.response?.data?.text || "Failed to place order.";
            toast.error(errorMessage);
            console.log('Err', error);
        }
    };

    const filteredMenuItems = menuItems.filter(item => {
        const matchesCategory = selectedCategory ? item.item.selectedCategory.id === parseInt(selectedCategory) : true;
        const matchesText = item.item.name.toLowerCase().includes(searchText.toLowerCase());
        const isNotExcluded = !["Add Rice", "Add Roti"].includes(item.item.name);
        return matchesCategory && matchesText && isNotExcluded;
    });

    const categories = Array.from(
        new Map(menuItems.map(item => [item.item.selectedCategory.id, item.item.selectedCategory])).values()
    ).sort((a, b) => {
        if (a.displayOrder === null || a.displayOrder === undefined) return 1;
        if (b.displayOrder === null || b.displayOrder === undefined) return -1;
        return a.displayOrder - b.displayOrder;
    });

    const dailyOrderHandler = () => {
        slot = slot ? slot : 'LUNCH';
        navigate('/placeOrder?slot=' + slot);
    };
    const selectedItems = menuItems
        .filter(item => quantities[item.id] > 0)
        .map(item => ({
            name: item.item.name,
            quantity: quantities[item.id],
            price: item.item.price
        }));

    useEffect(() => {
        const isRiceCategorySelected = menuItems.some(item => {
            const category = item.item.selectedCategory.name.toLowerCase();
            return category === 'rice set' && quantities[item.id] > 0;
        });
        setIsRiceSelected(isRiceCategorySelected);
    }, [quantities, menuItems]);

    useEffect(() => {
        const isRotiCategorySelected = menuItems.some(item => {
            const category = item.item.selectedCategory.name.toLowerCase();
            return category === 'roti set' && quantities[item.id] > 0;
        });
        setIsRotiSelected(isRotiCategorySelected);
    }, [quantities, menuItems]);

    return (
        <>
            <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
                <div className="nav-buttons">
                    <button onClick={dailyOrderHandler}
                            className='bg-lime-600 rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'>
                        Place daily order
                    </button>
                </div>
                <div className='max-w-screen-lg mx-auto mt-10 mb-10'>
                    <div className='calendar-header mb-6'>
                        <div className='nav-buttons items-center'>
                            <button onClick={goToPrevious} className="bg-gray-100 font-semibold rounded-full w-10 h-10 flex justify-center text-gray-900 text-sm hover:bg-lime-600 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">❮</button>
                            <h2 className="current-date">{format(currentDate, 'MMMM yyyy')}</h2>
                            <button onClick={goToNext} className="bg-gray-100 font-semibold rounded-full w-10 h-10 flex justify-center text-gray-900 text-sm hover:bg-lime-600 hover:text-white focus:ring-2 focus:ring-lime-500 transition-all">❯</button>
                        </div>
                        <div className="view-buttons">
                            <button onClick={() => changeView('monthly')}
                                    className={`rounded-lg px-6 py-3 text-sm hover:bg-lime-600 hover:text-white font-semibold transition-all focus:ring-2 focus:ring-lime-500 ${view === 'monthly' ? 'bg-lime-600 text-white' : 'bg-gray-100 text-gray-900'}`}>Monthly
                            </button>
                            <button onClick={() => changeView('weekly')}
                                    className={`rounded-lg px-6 py-3 text-sm hover:bg-lime-600 hover:text-white font-semibold transition-all focus:ring-2 focus:ring-lime-500 ${view === 'weekly' ? 'bg-lime-600 text-white' : 'bg-gray-100 text-gray-900'}`}>Weekly
                            </button>
                        </div>
                    </div>
                    <div className="days-grid">
                        {renderDaysOfWeek()}
                        {view === 'monthly' ? renderMonthlyView() : renderWeeklyView()}
                    </div>
                </div>

                <Modal show={isModalOpen} size="5xl" onClose={() => closeModal()} popup>
                    <div className="modal-content">
                        <Modal.Header className='px-8 py-6 border modal-header'>
                            <h2>{slot?.toUpperCase()} {selectedDate ? format(selectedDate, 'PPP') : ''}</h2>
                        </Modal.Header>
                        <Modal.Body className='px-8 py-6 modal-body'>
                            {loading ? <Spinner /> :
                                <div>
                                    <div className='flex justify-between gap-2'>
                                        <div className="mb-4 max-w-screen-sm flex gap-4 flex-1">
                                            <div className="flex-1">
                                                <label className="block text-sm font-medium text-gray-700">Category</label>
                                                <select
                                                    value={selectedCategory}
                                                    onChange={handleCategoryChange}
                                                    className="mt-1 block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                                                >
                                                    <option value="">All Categories</option>
                                                    {categories.map(category => (
                                                        <option key={category.id} value={category.id}>
                                                            {category.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="flex-1">
                                                <label className="block text-sm font-medium text-gray-700">Search</label>
                                                <input
                                                    type="text"
                                                    placeholder="Search by item name"
                                                    value={searchText}
                                                    onChange={handleSearchTextChange}
                                                    className="mt-1 block w-full rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                                                />
                                            </div>
                                        </div>
                                        <div className='mt-6'>
                                            <button onClick={handleClickOpen} className='bg-lime-600 rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'
                                                    disabled={isButtonDisabled}>
                                                Place Order</button>
                                        </div>
                                    </div>
                                    <Table className='drop-shadow-none border-collapse mt-4 table-fixed'>
                                        <Table.Head>
                                            <Table.HeadCell style={{ width: '50%' }}>Item</Table.HeadCell>
                                            <Table.HeadCell style={{ width: '15%' }}>Availability</Table.HeadCell>
                                            <Table.HeadCell style={{ width: '20%' }}>Quantity</Table.HeadCell>
                                            <Table.HeadCell style={{ width: '15%' }}>Price</Table.HeadCell>
                                        </Table.Head>
                                        <Table.Body className="divide-y">
                                            {filteredMenuItems.map((item) => (
                                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={item.id}>
                                                    <Table.Cell className="font-medium text-gray-900 dark:text-white">
                                                        {item.item.name}
                                                        {item.item.description?(<div>
                                                          <span style={{
                                                              fontSize: '0.75rem',  // Smaller font size
                                                              fontStyle: 'italic',  // Italic style
                                                              color: '#6b7280',     // Light gray color
                                                              fontWeight: '300',    // Light font weight
                                                              lineHeight: '1.5'     // Smooth line height for better readability
                                                          }}>
                                                            ({item.item.description})
                                                          </span>
                                                        </div>):''}
                                                    </Table.Cell>
                                                    <Table.Cell>{item.availableQuantity - (quantities[item.id] || 0)}</Table.Cell>
                                                    <Table.Cell>
                                                        <input
                                                            type="number"
                                                            value={quantities[item.id]}
                                                            onWheel={(e) => e.target.blur()}
                                                            onChange={(e) => handleQuantityChange(item.id, item.availableQuantity, parseInt(e.target.value))}
                                                            className="rounded-lg w-full border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                                            disabled={!isFuture(selectedDate) && !isToday(selectedDate)}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>Rs. {parseInt(item.item.price)}</Table.Cell>
                                                </Table.Row>
                                            ))}
                                        </Table.Body>
                                    </Table>
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer className='modal-footer justify-end border px-6 py-4'>
                            <button
                                className='px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100 rounded-lg'
                                onClick={closeModal}>
                                Close
                            </button>
                            <button className='bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'
                                    onClick={handleClickOpen}
                                    disabled={isButtonDisabled}>
                                Confirm Order
                            </button>
                        </Modal.Footer>
                    </div>
                </Modal>

                <Modal show={open} size="lg" onClose={handleClose} popup>
                    <Modal.Header className='px-6 py-4 border'>
                        <p className="text-xl font-bold text-gray-900">Order Confirmation</p>
                    </Modal.Header>
                    <Modal.Body className='px-6 py-5'>
                        <div>
                            <div className="mb-4">
                                <ul className="flex flex-col gap-4">
                                    {selectedItems.map((item, index) => (
                                        <li key={index} className="list-none flex justify-between">
                                            <span>
                                                <span className='font-bold text-gray-900'>{item.name}</span>
                                                <br/>
                                                <span className='text-sm text-gray-700'>Qty: {item.quantity}</span>
                                            </span>
                                            <span className='font-bold text-gray-900'>
                                                Rs. {item.quantity * item.price}
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {isRiceSelected && (
                                <div className="flex items-center mt-4">
                                    <label className="flex font-semibold text-sm items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={isAddRiceCheckboxChecked}
                                            onChange={() => setIsAddRiceCheckboxChecked(!isAddRiceCheckboxChecked)}
                                            className="mr-2 w-4 h-4 text-lime-600 bg-gray-100 focus:ring-lime-600 focus:ring-1 border-gray-300 rounded"
                                        />
                                        Add Rice
                                    </label>
                                    {isAddRiceCheckboxChecked? (
                                        <input
                                            type="number"
                                            min="1"
                                            value={riceQuantity}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value.length > 0 && value[0] === '0') {
                                                    value = value.slice(1);
                                                    e.target.value = value.slice(1);
                                                }
                                                console.log('e.target.value', e.target.value);
                                                setRiceQuantity(Number(value));
                                            }}
                                            className="ml-4 block w-16 rounded-lg border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                        />
                                    ) : ''}
                                </div>
                            )}
                            {isRotiSelected && (
                                <div className="flex items-center mt-4">
                                    <label className="flex font-semibold items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={isAddRotiCheckboxChecked}
                                            onChange={() => setIsAddRotiCheckboxChecked(!isAddRotiCheckboxChecked)}
                                            className="mr-2 w-4 h-4 text-lime-600 bg-gray-100 focus:ring-lime-600 focus:ring-1 border-gray-300 rounded"
                                        />
                                        Add Roti - <span className="ml-2 text-gray-900">Rs. {getAddRotiItem().item.price}/piece </span>
                                    </label>
                                    {isAddRotiCheckboxChecked ? (
                                        <input
                                            type="number"
                                            min="1"
                                            value={rotiQuantity}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value.length > 0 && value[0] === '0') {
                                                    value = value.slice(1);
                                                    e.target.value = value.slice(1);
                                                }
                                                console.log('e.target.value', e.target.value);
                                                setRotiQuantity(Number(value));
                                            }}
                                            className="ml-4 block w-16 rounded-lg border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
                                        />
                                    ):''}
                                </div>
                            )}
                            <hr className='mt-4 mb-4' />
                            <p className='text-lg font-bold flex justify-between'>
                                <span>Total Cost:</span>
                                <span>Rs. {calculateTotalCost()}</span>
                            </p>
                            <div className="mt-5 flex justify-end text-right gap-4">
                                <button
                                    className='px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-white text-gray-900 focus:ring-4 enabled:hover:bg-gray-100 rounded-lg'
                                    onClick={handleClose}>
                                    Cancel
                                </button>
                                <button className='bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500'
                                        onClick={() => { handleConfirmOrder() }}>
                                    Confirm Order
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default Calendar;