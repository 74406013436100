import axios from 'axios';
import { API_URL } from '../constants';

const CUSTOMER_USER_API_URL = `${API_URL}/api/customerUser`;

const token = localStorage.getItem('token'); // Adjust this to how you store/retrieve your token
console.log(token);
const headers = {
    'Content-Type': 'application/json',
    'X-Auth-Token': `${token}` // Set your authorization token here
};

class CustomerUserService {

    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }

    getAll() {
        return axios.get(CUSTOMER_USER_API_URL, { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${CUSTOMER_USER_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${CUSTOMER_USER_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${CUSTOMER_USER_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${CUSTOMER_USER_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }
}

export default new CustomerUserService();
