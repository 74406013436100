import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import DeliveryBagService from '../../../services/DeliveryBagService';
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";


const token = localStorage.getItem("token");

const DeliveryBagList = () => {
    const [deliveryBags, setDeliveryBags] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [token]);

    const fetchData = () => {
        DeliveryBagService.getAll()
            .then(response => {
                let bags = response.data.list;
                bags = sortBags(bags, sortOrder);
                setDeliveryBags(bags);
            })
            .catch(error => {
                toast.error("Error occurred")
                console.error('Error fetching delivery bags:', error);
            });
    };

    const sortBags = (bags, order) => {
        return bags.sort((a, b) => {
            if (a.name < b.name) return order === 'asc' ? -1 : 1;
            if (a.name > b.name) return order === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const handleSort = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        const sortedBags = sortBags([...deliveryBags], newOrder);
        setDeliveryBags(sortedBags);
    };

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this kitchen?");
        if (confirmed) {
            DeliveryBagService.delete(id)
                .then(response => {
                    console.log('Delivery bag deleted:', id);
                    fetchData(); // Refresh list after deletion
                })
                .catch(error => {
                    toast.error("Error occurred")
                    console.error('Error deleting delivery bag:', error);
                });
        }
    };

    const handleCreate = () => {
        navigate(`/admin/deliveryBag/add`);
    }

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons mb-2">
                <button onClick={() => navigate('/admin')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
                <button onClick={handleCreate}
                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Create New Group
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                    <tr>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                            onClick={handleSort}>
                            Name {sortOrder === 'asc' ? '▲' : '▼'}
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Bag Number
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Capacity
                        </th>
                        <th scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                    {deliveryBags.map(bag => (
                        <tr key={bag.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{bag.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{bag.bagNumber}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{bag.capacity}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                <Link to={`/admin/deliveryBag/edit/${bag.id}`}
                                      className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Edit</Link>
                                <button onClick={() => handleDelete(bag.id)} className="bg-red-700 rounded-lg px-4 py-2 text-white text-sm hover:bg-red-600 font-semibold transition-all focus:ring-2 focus:ring-red-600">Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DeliveryBagList;