import React, { useState, useEffect } from 'react';
import DeliveryBagService from '../../../services/DeliveryBagService';
import { toast } from "react-toastify";
import DateUtils from "../../../utils/DateUtils";
import Spinner from '../../../components/Spinner/Spinner';
import './DeliveryBagList.css'; // Import your custom CSS
import {toLocalTime} from "../../../services/TimezoneService";
const token = localStorage.getItem("token");

const DeliveringBagList = () => {
    const [deliveringBags, setDeliveringBags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');

    useEffect(() => {
        fetchData();
    }, [token]);

    const fetchData = () => {
        DeliveryBagService.kitchenCurrentDeliveryBags({})
            .then(response => {
                setDeliveringBags(response.data);
                const groupMap = new Map();
                response.data.forEach(bag => {
                    if (!groupMap.has(bag.userGroupId)) {
                        groupMap.set(bag.userGroupId, { id: bag.userGroupId, name: bag.userGroupName });
                    }
                });
                const uniqueGroups = Array.from(groupMap.values());
                setGroups(uniqueGroups);
            })
            .catch(error => {
                console.error('Error fetching delivery bags:', error);
                const errorMessage = error.response?.data?.message || "Error fetching delivery bags:";
                toast.error(errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleMarkAsDelivered = (id) => {
        const confirmed = window.confirm("Are you sure?");
        if (confirmed) {
            DeliveryBagService.markAsDelivered(id)
                .then(response => {
                    console.log('Delivery bag delivered:', id);
                    fetchData(); // Refresh list after deletion
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || "Error marking delivery bag delivered:";
                    toast.error(errorMessage);
                    console.error('Error marking delivery bag delivered:', error);
                });
        }
    };

    const handleMarkAsDelivering = (id) => {
        const confirmed = window.confirm("Are you sure?");
        if (confirmed) {
            DeliveryBagService.markAsDelivering(id)
                .then(response => {
                    console.log('Delivery bag delivering:', id);
                    fetchData(); // Refresh list after deletion
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message || "Error marking delivery bag delivering:";
                    toast.error(errorMessage);
                    console.error('Error marking delivery bag delivering:', error);
                });
        }
    };

    const handleGroupChange = (e) => {
        setSelectedGroup(e.target.value);
    };

    const filteredBags = selectedGroup ? deliveringBags.filter(bag => bag.userGroupId === parseInt(selectedGroup)) : deliveringBags;

    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="mb-4">
                <label htmlFor="groupFilter" className="block text-sm font-medium text-gray-700">
                    Select Group:
                </label>
                <select
                    id="groupFilter"
                    value={selectedGroup}
                    onChange={handleGroupChange}
                    className="mt-1 block rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                    <option value="">All Groups</option>
                    {groups.map((group, index) => (
                        <option key={index} value={group.id}>{group.name}</option>
                    ))}
                </select>
            </div>
            {loading ? <Spinner /> :
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Group Name
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Slot
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Status
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Delivery Time
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Delivered Time
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {filteredBags.map((bag, index) => (
                            <tr key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {bag.name}
                                    <div className="relative inline-block ml-2">
                                        <span className="notification-circle">{bag.carts.length}</span>
                                        <div className="notification-list">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>STATUS</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {bag.carts.map((cart, cartIndex) => (
                                                    <tr key={cartIndex}>
                                                        <td>{cart.userId ? cart.userId : cart.userEmail}</td>
                                                        <td>{cart.status.name}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{bag.userGroupName}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{bag.slot}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{bag.status}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{DateUtils.formatDateTime(toLocalTime(bag.date))}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{bag.deliveredDate ? DateUtils.formatDateTime(bag.deliveredDate) : '-'}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                    {bag.status === 'DELIVERING' ? (
                                        <button onClick={() => handleMarkAsDelivered(bag.id)}
                                                className="bg-amber-500 font-semibold rounded-lg px-6 py-3 text-white text-sm hover:bg-amber-400 focus:ring-2 focus:ring-amber-400 transition-all">
                                            Mark as delivered
                                        </button>
                                    ) : ''}
                                    {bag.status !== 'DELIVERING' ? (
                                        <button onClick={() => handleMarkAsDelivering(bag.id)}
                                                className="bg-lime-600 font-semibold rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 focus:ring-2 focus:ring-lime-500 transition-all">Mark
                                            as delivering
                                        </button>
                                    ) : ""}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default DeliveringBagList;