// src/components/KitchenList.js

import React, {useEffect, useState} from 'react';
import KitchenService from './KitchenService';
import {useNavigate} from 'react-router-dom';
import {Table} from "flowbite-react";
import {toast} from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";

const token = localStorage.getItem("token");


const KitchenList = () => {
    const [kitchens, setKitchens] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        KitchenService.getAll()
            .then(response => {
                setKitchens(response.data.list);
            })
            .catch(e => {
                const errorMessage = e.response?.data?.message || "Error occurred.";
                toast.error(errorMessage)
                console.log(e);
            });
    }, [token]);

    const handleEdit = (id) => {
        navigate(`/admin/kitchen/edit/${id}`);
    };

    const handleCreate = (id) => {
        navigate(`/admin/kitchen/add`);
    };

    const handleDelete = (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this kitchen?");
        if (confirmed) {
            KitchenService.delete(id)
                .then(response => {
                    setKitchens(kitchens.filter(kitchen => kitchen.id !== id));
                })
                .catch(e => {
                    const errorMessage = e.response?.data?.message || "Error occurred.";
                    toast.error(errorMessage)
                    console.log(e);
                });
        }
    };

    const handleAssignContractedGroup = (id) => {
        navigate(`/admin/kitchen/assignContractedGroup/${id}`);
    };

    const handleAssignMenuCategory = (id) => {
        navigate(`/admin/kitchen/assignMenuCategory/${id}`);
    };

    const handleAssignMenuItem = (id) => {
        navigate(`/admin/kitchen/assignMenuItem/${id}`);
    };

    const handleAssignDeliveryBag = (id) => {
        navigate(`/admin/kitchen/assignDeliveryBag/${id}`);
    }


    return (
        <div className='max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn'>
            <div className="nav-buttons mb-2">
                <button onClick={() => navigate('/admin')}
                    className="flex items-center gap-1 px-4 py-2 text-sm text-center font-medium transition-all focus:z-10 focus:outline-none border border-gray-200 bg-gray-100 text-gray-900 focus:ring-4 enabled:hover:bg-gray-100  rounded-lg">
                    <FaArrowLeft />
                    <span>Back</span>
                </button>
                <button onClick={handleCreate}
                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">Create New Group
                </button>
            </div>
            <div className="overflow-x-auto">
                <Table>
                    <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                        <Table.HeadCell>Phone</Table.HeadCell>
                        <Table.HeadCell>Email</Table.HeadCell>
                        <Table.HeadCell>Score</Table.HeadCell>
                        <Table.HeadCell>Serving Count</Table.HeadCell>
                        <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                        {kitchens.map(kitchen => (
                            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={kitchen.id}>
                                <Table.Cell
                                    className="whitespace-nowrap font-medium text-gray-900 dark:text-white">{kitchen.name}</Table.Cell>
                                <Table.Cell>{kitchen.phone}</Table.Cell>
                                <Table.Cell>{kitchen.email}</Table.Cell>
                                <Table.Cell>{kitchen.score}</Table.Cell>
                                <Table.Cell>{kitchen.servingCount}</Table.Cell>
                                <Table.Cell>
                                    <button onClick={() => handleEdit(kitchen.id)}
                                            className="mr-2 mb-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500">
                                        Edit
                                    </button>
                                    <button
                                        className="mr-2 mb-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500"
                                        onClick={() => handleAssignContractedGroup(kitchen.id)}> Contracted Group
                                    </button>
                                    <button
                                        className="mr-2 mb-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500"
                                        onClick={() => handleAssignMenuCategory(kitchen.id)}> Menu Category
                                    </button>

                                    <button
                                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500"
                                        onClick={() => handleAssignMenuItem(kitchen.id)}> Menu Item
                                    </button>

                                    <button
                                        className="mr-2 bg-lime-600 rounded-lg px-4 py-2 text-white text-sm hover:bg-lime-500 font-semibold transition-all focus:ring-2 focus:ring-lime-500"
                                        onClick={() => handleAssignDeliveryBag(kitchen.id)}> Delivery Bags
                                    </button>

                                    <button onClick={() => handleDelete(kitchen.id)}
                                            className="bg-red-700 rounded-lg px-4 py-2 text-white text-sm hover:bg-red-600 font-semibold transition-all focus:ring-2 focus:ring-red-600">Delete
                                    </button>

                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </div>
    );
};

export default KitchenList;