import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // You can use any icon library

const PasswordInput = ({id, name, password, setPassword, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative mt-2">
        <input
            id={id}
            name={name}
            type={showPassword ? 'text' : 'password'}
            value={password}
            placeholder={placeholder}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="block w-full rounded-lg border-0 pr-10 text-gray-900 shadow-sm ring-1 ring-inset bg-gray-50 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:text-sm sm:leading-6 transition-all"
        />
        <span
            type="button"
            onClick={togglePasswordVisibility}
            style={{
            position: 'absolute',
            right: '10px',
            top: '50%',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            padding: '0'
            }}
        >
            {showPassword ? <FaEyeSlash className='text-gray-400'/> : <FaEye className='text-gray-400'/>}
        </span>
    </div>
  );
};

export default PasswordInput;
