import axios from 'axios';

import { API_URL } from '../../../constants';
const KITCHEN_API_URL = `${API_URL}/api/userGroup`;

class UserGroupService {

    getHeaders() {
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        return {
            'Content-Type': 'application/json',
            'X-Auth-Token': token // Set your authorization token here
        };
    }

    getAll() {
        return axios.get(`${KITCHEN_API_URL}` , { headers: this.getHeaders() });
    }

    get(id) {
        return axios.get(`${KITCHEN_API_URL}/show/${id}`, { headers: this.getHeaders() });
    }

    create(data) {
        return axios.post(`${KITCHEN_API_URL}/save`, data, { headers: this.getHeaders() });
    }

    update(id, data) {
        return axios.put(`${KITCHEN_API_URL}/update/${id}`, data, { headers: this.getHeaders() });
    }

    updateByOwner(id, data) {
        return axios.put(`${KITCHEN_API_URL}/updateByGroupOwner/${id}`, data, { headers: this.getHeaders() });
    }

    delete(id) {
        return axios.delete(`${KITCHEN_API_URL}/delete/${id}`, { headers: this.getHeaders() });
    }

    listUsers(id) {
        return axios.get(`${KITCHEN_API_URL}/listUsers/${id}`, { headers: this.getHeaders() });
    }

    removeUsers(id, data) {
        return axios.put(`${KITCHEN_API_URL}/removeUser/${id}`, data, { headers: this.getHeaders() });
    }

    addUsers(id, data) {
        return axios.put(`${KITCHEN_API_URL}/addUser/${id}`, data, { headers: this.getHeaders() });
    }

    listMenus(id) {
        return axios.get(`${KITCHEN_API_URL}/listMenus/${id}`, { headers: this.getHeaders() });
    }

    addMenus(id, data) {
        return axios.put(`${KITCHEN_API_URL}/addMenu/${id}`, data, { headers: this.getHeaders() });
    }

    removeMenu(id, data) {
        return axios.put(`${KITCHEN_API_URL}/removeMenu/${id}`, data, { headers: this.getHeaders() });
    }

    assignOwner(id, data) {
        return axios.put(`${KITCHEN_API_URL}/assignOwner/${id}`, data, { headers: this.getHeaders() });
    }

    addNewUser(id, data) {
        return axios.put(`${KITCHEN_API_URL}/addNewUser/${id}`, data, { headers: this.getHeaders() });
    }

    updateOwner(id, data) {
        return axios.put(`${KITCHEN_API_URL}/updateOwner/${id}`, data, { headers: this.getHeaders() });
    }

    listChildren() {
        return axios.get(`${KITCHEN_API_URL}/listChildren`, { headers: this.getHeaders() });
    }

    addChildren(id, data) {
        return axios.put(`${KITCHEN_API_URL}/addChild/${id}`, data, { headers: this.getHeaders() });
    }

    removeChildren(id, data) {
        return axios.put(`${KITCHEN_API_URL}/removeChild/${id}`, data, { headers: this.getHeaders() });
    }

    setUpChildGroup(id, data) {
        return axios.post(`${KITCHEN_API_URL}/setUpChildGroup/${id}`, data, { headers: this.getHeaders() });
    }

    listChildrenUsers(id) {
        return axios.get(`${KITCHEN_API_URL}/listChildrenUsers/${id}`, { headers: this.getHeaders() });
    }

    addUserByEmail(id, data) {
        return axios.put(`${KITCHEN_API_URL}/addUserByEmail/${id}`, data, { headers: this.getHeaders() });
    }

}

export default new UserGroupService();
