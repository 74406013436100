import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import {useLocation, useNavigate} from 'react-router-dom';
import {API_URL} from "../constants";
import { toast } from 'react-toastify';

const UserContext = createContext({ user: null, setUser: () => {},  fetchUserDetails: () => {} });

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUserDetails = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.get(`${API_URL}/api/customerUser/getLoggedUser`, {
          headers: {
            'X-Auth-Token': `${token}`
          }
        });
        setUser(response.data);
        if (location.pathname === '/') {
          response.data.isKitchen ? navigate('/kitchen/kitchenOrderLists') : navigate('/placeOrder');
        }
      } catch (error) {
        localStorage.removeItem('token');
        if (error.response && error.response.status === 401) {
          toast.error("Session expired. Please log in again.");
          navigate('/auth/login');
        } else if (!navigator.onLine) {
          toast.error("No internet connection. Please check your network settings.");
        } else {
          navigate('/error');
        }
      }
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, fetchUserDetails }}>
      {children}
    </UserContext.Provider>
  );
}
