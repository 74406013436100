import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom'
import { useUser } from '../../context/UserContext';

export function HeroSection() {
    const {user} = useUser();
    return (
        <>
        <section className="bg-transparent cc-hero-section">
            <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                <div className="mr-auto place-self-center lg:col-span-7">
                    <h1 className="max-w-2xl mb-4 text-4xl font-semibold tracking-tight text-lime-600 leading-none md:text-5xl xl:text-6xl cc-hero-h1">Good Food & Good Moment</h1>
                    <p className="max-w-2xl mb-6 font-normal text-gray-900 lg:mb-8 md:text-lg lg:text-xl">With One Solution for Your Office Lunch Experience</p>
                    <Link to={user ? '/placeOrder?slot=breakfast': '/auth/login'} className="order-now-btn inline-flex items-center justify-center px-8 py-3 text-base font-medium text-center bg-lime-600 text-white rounded-full focus:ring-2 focus:ring-lime-700 cursor-pointer">
                        Order Now
                    </Link>
                </div>
                <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                    <img src="./banner-food.png" alt="mockup" />
                </div>                
            </div>
        </section>
        </>
    )
}