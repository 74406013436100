import React, { useEffect, useState, useCallback } from "react";
import { Table } from "flowbite-react";
import axios from 'axios';
import { API_URL } from "../../constants";
import { toast } from 'react-toastify';
import Spinner from "../../components/Spinner/Spinner";
import DateUtils from "../../utils/DateUtils";
import KitchenService from "../Admin/Kitchen/KitchenService";
import "react-datepicker/dist/react-datepicker.css";

const TimeExceededOrders = () => {
    const token = localStorage.getItem('token');
    const [orderedItems, setOrderedItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedSlot, setSelectedSlot] = useState("LUNCH");
    const [userGroups, setUserGroups] = useState([]);
    const [selectedUserGroup, setSelectedUserGroup] = useState({
        id: 0,
    });
    const [status, setStatus] = useState("TIME_EXCEEDED");


    const fetchData = useCallback(async () => {
        setLoading(true);
        const payload = { date: DateUtils.formatDate(currentDate), slot: selectedSlot, userGroupId: selectedUserGroup.id, status: status };
        try {
            const response = await axios.post(`${API_URL}/api/customerOrderCart/orderListByCart`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': `${token}`
                }
            });
            setOrderedItems(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [currentDate, selectedSlot, token, selectedUserGroup, status]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        KitchenService.listMyGroups()
            .then((response) => {
                setUserGroups(response.data);
            })
            .catch((error) => {
                toast.error("Error fetching my groups");
                console.error("Error fetching my groups:", error);
            });
    }, [token])

    const handleStatusChange = async (cartIds, status) => {
        const payload = { cartIds: cartIds };
        const URL = status === 'PREPARING' ? 'api/customerOrderCart/markAsPreparing' : 'api/customerOrderCart/markAsReady';
        try {
            await axios.put(`${API_URL}/${URL}`, payload, {
                headers: {
                    'X-Auth-Token': `${token}`
                }
            });
            toast.success(`Order status changed to ${status}`);
            fetchData();
        } catch (error) {
            toast.error(`Error changing status ${status}`);
        }
    };

    const acceptOrder = async (cartId, status) => {
        const payload = { cartId: cartId };
        try {
            await axios.put(`${API_URL}/api/customerOrderCart/markAsNew`, payload, {
                headers: {
                    'X-Auth-Token': `${token}`
                }
            });
            toast.success(`Order status changed to ${status}`);
            await fetchData();

        } catch (error) {
            toast.error(`Error changing status ${status}`);
        }
    };

    const handleSlotDropdownChange = (e) => {
        setSelectedSlot(e.target.value);
    };


    const handleGroupDropdownChange = async (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setSelectedUserGroup({ id: selectedValue });
    };

    const renderTableBody = () => {
            return (
                <React.Fragment>
                    {orderedItems.map((item) => (
                        <React.Fragment key={item.id}>
                            {item.orders.map((order) => (
                                <Table.Row key={order.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>{order.item}</Table.Cell>
                                    <Table.Cell>{order.quantity}</Table.Cell>
                                    <Table.Cell>
                        <span
                            className={`${
                                item.status.name === 'TIME_EXCEEDED'
                                    ? 'text-red-800'
                                    : 'text-blue-800'
                            }`}
                        >
                            {item.status.name}
                        </span>
                                    </Table.Cell>
                                    <Table.Cell>{item.expectedDeliveryTime}</Table.Cell>
                                    <Table.Cell>
                                        {item.status.name === "TIME_EXCEEDED" && (
                                            <button
                                                className="bg-lime-600 font-semibold rounded-lg px-6 py-3 text-white text-sm hover:bg-lime-500 focus:ring-2 focus:ring-lime-500 transition-all"
                                                onClick={() => acceptOrder(item.id, "NEW")}
                                            >
                                                Accept
                                            </button>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </React.Fragment>
                    ))}
                </React.Fragment>

            );

    };

    return (
        <div className="max-w-screen-xl px-4 py-8 mx-auto animate-fadeIn">
            <div className="calendar-header mb-6">
                <div className="nav-buttons items-center">
                    <h2 className="current-date">{DateUtils.formatDate(currentDate)}</h2>
                </div>
                <div className="flex space-x-2">
                    <div>
                        <label
                            htmlFor="select-group"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Select Group
                        </label>
                        <div className="mt-2">
                            <select
                                id="select-group"
                                value={selectedUserGroup.id}
                                onChange={handleGroupDropdownChange}
                                className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                <option value="">All groups</option>
                                {userGroups.map((userGroup) => (
                                    <option key={userGroup.id} value={userGroup.id}>
                                        {userGroup.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="select-slot" className="block text-sm font-medium leading-6 text-gray-900">
                            Select Slot
                        </label>
                        <div className="mt-2">
                            <select
                                id="select-slot"
                                value={selectedSlot}
                                onChange={handleSlotDropdownChange}
                                className="block rounded-lg border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lime-600 sm:max-w-xs sm:text-sm sm:leading-6 transition-all"
                            >
                                <option value="LUNCH">LUNCH</option>
                                <option value="BREAKFAST">BREAKFAST</option>
                                <option value="SNACKS">SNACKS</option>
                                <option value="DINNER">DINNER</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            {loading ? <Spinner/> : (
                <div className="overflow-x-auto px-0.5">
                    <Table className="drop-shadow-none">
                        <Table.Head>
                            <Table.HeadCell>Item</Table.HeadCell>
                            <Table.HeadCell>Quantity</Table.HeadCell>
                            <Table.HeadCell>Status</Table.HeadCell>
                            <Table.HeadCell>Expected Delivery Time</Table.HeadCell>
                            <Table.HeadCell>Action</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {renderTableBody()}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </div>
    );
};

export default TimeExceededOrders;