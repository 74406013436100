// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    position: relative;
}

.notification-list {
    display: none;
    position: absolute;
    top: -76px;
    left: 19px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    z-index: 20;
    width: 200px;
}

.notification-item {
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.notification-item:last-child {
    border-bottom: none;
}

.relative:hover .notification-list {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/Kitchen/Delivery/DeliveryBagList.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,uBAAuB;IACvB,sBAAsB;IACtB,sCAAsC;IACtC,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".notification-circle {\n    display: inline-block;\n    width: 20px;\n    height: 20px;\n    background-color: red;\n    color: white;\n    border-radius: 50%;\n    text-align: center;\n    line-height: 20px;\n    font-size: 12px;\n    position: relative;\n}\n\n.notification-list {\n    display: none;\n    position: absolute;\n    top: -76px;\n    left: 19px;\n    background-color: white;\n    border: 1px solid #ccc;\n    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);\n    z-index: 20;\n    width: 200px;\n}\n\n.notification-item {\n    padding: 10px;\n    border-bottom: 1px solid #eee;\n}\n\n.notification-item:last-child {\n    border-bottom: none;\n}\n\n.relative:hover .notification-list {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
