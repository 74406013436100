// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  /* table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  } */
  
  button {
    padding: 10px 20px;
    background-color: green;
    color: white;
    border: none;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/OrderHistory/OrderHistory.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;EACrB;;EAEA;;;;;;;;;;;;;KAaG;;EAEH;IACE,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB","sourcesContent":[".filter-container {\n    display: flex;\n    gap: 10px;\n    margin-bottom: 20px;\n  }\n  \n  /* table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    border: 1px solid #ccc;\n    padding: 10px;\n    text-align: left;\n  }\n  \n  th {\n    background-color: #f4f4f4;\n  } */\n  \n  button {\n    padding: 10px 20px;\n    background-color: green;\n    color: white;\n    border: none;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
