// src/components/AdminIndex.js

import React from 'react';
import { Link } from 'react-router-dom';

const AdminIndex = () => {
    return (
        <div className="max-w-screen-xl px-4 py-12 mx-auto animate-fadeIn">
            <h2 className="text-2xl font-semibold mb-6">Admin Dashboard</h2>
            <div className="flex gap-4 font-semibold">
                <Link
                    to="/admin/userGroup"
                    className="basis-1/5 h-32 flex justify-center items-center px-4 py-2 bg-lime-50 text-gray-900 rounded-xl shadow-md hover:border-2 hover:border-lime-500 transition-colors"
                >
                    Manage UserGroup
                </Link>
                <Link
                    to="/admin/kitchen"
                    className="basis-1/5 h-32 flex justify-center items-center px-4 py-2 bg-lime-50 text-gray-900 rounded-xl shadow-md hover:border-2 hover:border-lime-500 transition-colors"
                >
                    Manage Kitchens
                </Link>
                <Link
                    to="/admin/menuItem"
                    className="basis-1/5 h-32 flex justify-center items-center px-4 py-2 bg-lime-50 text-gray-900 rounded-xl shadow-md hover:border-2 hover:border-lime-500 transition-colors"
                >
                    Manage Menu Items
                </Link>
                <Link
                    to="/admin/menuCategory"
                    className="basis-1/5 h-32 flex justify-center items-center px-4 py-2 bg-lime-50 text-gray-900 rounded-xl shadow-md hover:border-2 hover:border-lime-500 transition-colors"
                >
                    Manage Menu Categories
                </Link>

                <Link
                    to="/admin/deliveryBag"
                    className="basis-1/5 h-32 flex justify-center items-center px-4 py-2 bg-lime-50 text-gray-900 rounded-xl shadow-md hover:border-2 hover:border-lime-500 transition-colors"
                >
                    Manage Delivery Bags
                </Link>
            </div>
        </div>
    );
};

export default AdminIndex;