import React from 'react'
import './Header.css'
import { HeroSection } from '../HeroSection/Hero'

export default function Header() {
  return (
    <>
        <HeroSection/>
    </>
  )
}
