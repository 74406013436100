import React from 'react';
import { ClipLoader } from 'react-spinners';

const Spinner = () => {
    return (
        <div className="spinner">
            <ClipLoader size={50} color={"#65a30d"} loading={true} aria-label="Loading Spinner"
                data-testid="loader" />
        </div>
    );
};

export default Spinner;
